import styled from 'styled-components/macro'

export const Title = styled.p`
  border-radius: 0 0 10px 10px;
  width: 220px;
  height: 100px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  color: #707070;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 5px;
  transition: all ease 200ms;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 980px) {
    font-size: 0.8rem;
  }
`

export const LastPosts = styled.p`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.primary};
`

export const GridPosts = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 220px);
  grid-gap: 30px;
`

export const ContentPosts = styled.div<any>`
  display: flex;
  align-items: flex-end;
  min-height: 300px;
  border-radius: 10px;
  box-shadow: 1px 1px 5px #ddd;
  background: ${({ imageUrl }) => `url(${imageUrl})center center no-repeat`};
  background-size: cover;
  word-break: break-word;
`
