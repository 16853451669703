import axios from 'axios'
import { Teacher } from '../domain/entities/Teacher'
import { Student } from '../domain/entities/Student'

type LimeResponse = {
  url: string
  token: string
  questionarioLimesurvey: true
  tipoUsuario: number
}

export const limeSurvey = async (user: Student & Teacher): Promise<LimeResponse | null> => {
  const typeuser = user?.matricula ? 1 : 2
  const userId = user?.matricula ? user.matricula : user.office

  try {
    const { data } = await axios.get(`${process.env.REACT_APP_LOGIN_API}/user/limesurvey/${userId}/${typeuser}`)
    return data as LimeResponse
  } catch (error) {
    return null
  }
}
