import styled from 'styled-components/macro'

type PageButtonProp = {
  active: boolean;
};

export const PaginationButton = styled.span<PageButtonProp>`
  display: inline-grid;
  place-content: center;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  margin: 0 3px;
  padding: 2px;
  min-width: 25px;
  cursor: pointer;
  background-color: ${({ theme, active }) => (active ? theme.primary : 'none')};
  font-family: 'BwMitga', -apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”,
    “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif;
  color: ${({ active }) => (active ? '#fff' : '#707070')};
  font-weight: bold;
`

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
`
