import React, { useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Box from '@mui/material/Box'
import Banner from '../../components/Media/Banner'
import Footer from '../../components/layout/Footer/Footer'
import Header from '../../components/layout/Header/Header'
import { Container } from '../../components/layout/Grid/Grid'
import themeState from '../../store/themeAtom'
import { useRecoilState } from 'recoil'
import InputText from '../../components/forms/InputText/InputText'
import Button from '../../components/forms/Button'
import UserState from '../../store/UserAtom'
import { FormRadioEnem, GradeInput, GradeLabel, InputGroup, PageWrapper, SectionTitle } from './style'
import { Select, MenuItem } from '@mui/material'
import api from '../../services/api'
import {
  ResponseDatasEnem,
  ResponseMotivosAusencia,
  ResponseCaracteristicasInstituicao,
  ResponseListaUf,
  ResponseFormularioEnem,
} from '../../domain/entities/EnemForm'
import { TabEnemForm } from './style'
import { handleChangeNota, validarEnvioDeNotas, validarEnvioParticipacao } from '../../helpers/enemFormHelper'

const getTurno = (userTurno: string) => {
  switch (userTurno) {
    case '1':
      return 'Matutino'
    case '2':
      return 'Vespertino'
    case '3':
      return 'Noturno'
    case '4':
      return 'Integral'
    case '5':
      return 'Intermediário'
    case '6':
      return 'Não Informado'
    default:
      return 'Não Informado'
  }
}

const EnemForm: React.FC<any> = ({ history }) => {
  const [user] = useRecoilState(UserState)
  const [theme, setTheme] = useRecoilState(themeState)
  const [error, setError] = useState<any>({})
  const [activeTab, setActiveTab] = useState<number>(0)
  const [updateData, setUpdateData] = useState<boolean>(false)

  const [participacaoPrimeiraFase, setParticipacaoPrimeiraFase] = useState<boolean>(false)
  const [participacaoSegundaFase, setParticipacaoSegundaFase] = useState<boolean>(false)
  const [inserirNotas, setInserirNotas] = useState<boolean>(false)
  const [formularioSisu, setFormularioSisu] = useState<boolean>(false)
  const [datasFormularioEnem, setDatasFormularioEnem] = useState<ResponseDatasEnem>({} as ResponseDatasEnem)
  const [motivosAusenciaDia1, setMotivosAusenciaDia1] = useState<ResponseMotivosAusencia>([])
  const [motivosAusenciaDia2, setMotivosAusenciaDia2] = useState<ResponseMotivosAusencia>([])
  const [caracteristicasInstituicao, setCaracteristicasInstituicao] = useState<ResponseCaracteristicasInstituicao>([])
  const [listagemUf, setListaUf] = useState<ResponseListaUf>([])
  const currentDate = new Date()
  const currentYear = currentDate.getMonth() >= 9 ? currentDate.getFullYear() : currentDate.getFullYear() - 1

  const [defaultInputValue, setDefaultInputValue] = useState<number>(0)

  const [respondeuAprovacao, setRespondeuAprovacao] = useState<boolean>(false)
  const [notasEnviadas, setNotasEnviadas] = useState<boolean>(false)
  const [formularioEnem, setFormularioEnem] = useState<ResponseFormularioEnem>({} as ResponseFormularioEnem)
  const [defaultFormularioEnem, setDefaultFormularioEnem] = useState<ResponseFormularioEnem>(
    {} as ResponseFormularioEnem,
  )

  useEffect(() => {
    ;(async () => {
      if (user?.matricula != null)
        try {
          const data = await api.get(
            `${process.env.REACT_APP_LOGIN_API}/enem/FomarularioEnem/${user?.matricula}/${currentYear}`,
          )
          if (!data.data) {
            setFormularioEnem((prevState) => ({
              ...prevState,
              qtdEnviosParticipacao: 0,
              qtdEnviosNotas: 0,
              qtdEnviosSituacao: 0,
            }))
            return
          }
          setFormularioEnem(data.data)
          setDefaultFormularioEnem(data.data)
          setNotasEnviadas(
            data.data.notaRedacao &&
              data.data.notaChst &&
              data.data.notaCnst &&
              data.data.notaLcst &&
              data.data.notaMst,
          )
          setRespondeuAprovacao(data.data.situacaoEnem != null && data.data.situacaoEnem != undefined)
        } catch (e) {
          window.alert('Ocorreu um problema')
          console.log(e)
        }
    })()
  }, [activeTab, updateData])

  useEffect(() => {
    ;(async () => {
      try {
        const data = await api.get(`${process.env.REACT_APP_LOGIN_API}/enem/DatasFormulario/${currentYear}`)
        if (!data.data) return
        setDatasFormularioEnem(data.data)
        return setDateVariables()
      } catch (e) {
        window.alert('Ocorreu um problema')
        console.log(e)
      }
    })()
  }, [activeTab])

  useEffect(() => {
    ;(async () => {
      try {
        const data = await api.get(`${process.env.REACT_APP_LOGIN_API}/enem/MotivosAusencia`)
        if (!data.data) return
        const motivosDia1: ResponseMotivosAusencia = []
        const motivosDia2: ResponseMotivosAusencia = []

        data.data.forEach((element: any) => {
          if (element.dia1 == 1) {
            motivosDia1.push(element)
          }
          if (element.dia2 == 1) {
            motivosDia2.push(element)
          }
        })
        setMotivosAusenciaDia1(motivosDia1)
        setMotivosAusenciaDia2(motivosDia2)
      } catch (e) {
        window.alert('Ocorreu um problema')
        console.log(e)
      }
    })()
  }, [activeTab == 1])

  useEffect(() => {
    ;(async () => {
      try {
        const data = await api.get(`${process.env.REACT_APP_LOGIN_API}/enem/CaracteristicasInstituicao`)
        if (!data.data) return
        const caracteristicasInstituicao: ResponseCaracteristicasInstituicao = []

        data.data.forEach((element: any) => {
          caracteristicasInstituicao.push(element)
        })
        setCaracteristicasInstituicao(caracteristicasInstituicao)
      } catch (e) {
        window.alert('Ocorreu um problema')
        console.log(e)
      }
    })()
  }, [activeTab == 3])

  useEffect(() => {
    ;(async () => {
      try {
        const data = await api.get(`${process.env.REACT_APP_LOGIN_API}/enem/ListaUf`)
        if (!data.data) return
        const listaUf: ResponseListaUf = []

        data.data.forEach((element: any) => {
          listaUf.push(element)
        })
        setListaUf(listaUf)
      } catch (e) {
        window.alert('Ocorreu um problema')
        console.log(e)
      }
    })()
  }, [activeTab == 3])

  useEffect(() => {
    if (user?.nivelEnsino !== 'Ensino Médio') history.push('/')
    if (theme !== 'home_enem') setTheme('home_enem')
  }, [user, setTheme, history])

  async function createSendObject() {
    let data = {}
    try {
      if (activeTab == 1) {
        data = {
          Matricula: user?.matricula,
          AnoLetivo: currentYear,
          PrimeiraFase: formularioEnem.primeiraFase,
          SegundaFase: formularioEnem.segundaFase,
          CodigoMotivoPrimeiraFase: formularioEnem.codigoMotivoPrimeiraFase,
          CodigoMotivoSegundaFase: formularioEnem.codigoMotivoSegundaFase,
          QtdEnviosParticipacao:
            participacaoSegundaFase &&
            ((formularioEnem?.qtdEnviosParticipacao < 2 &&
              defaultFormularioEnem?.primeiraFase != formularioEnem?.primeiraFase) ||
              defaultFormularioEnem?.segundaFase != formularioEnem?.segundaFase)
              ? formularioEnem.qtdEnviosParticipacao + 1
              : formularioEnem.qtdEnviosParticipacao,
        }
      } else if (activeTab == 2) {
        data = {
          Matricula: user?.matricula,
          AnoLetivo: currentYear,
          NotaRedacao: formularioEnem.notaRedacao,
          NotaChst: formularioEnem.notaChst,
          NotaCnst: formularioEnem.notaCnst,
          NotaLcst: formularioEnem.notaLcst,
          NotaMst: formularioEnem.notaMst,
          QtdEnviosNotas:
            formularioEnem?.qtdEnviosParticipacao == 0 ||
            defaultFormularioEnem.notaRedacao != formularioEnem.notaRedacao ||
            defaultFormularioEnem.notaChst != formularioEnem.notaChst ||
            defaultFormularioEnem.notaCnst != formularioEnem.notaCnst ||
            defaultFormularioEnem.notaLcst != formularioEnem.notaLcst ||
            defaultFormularioEnem.notaMst != formularioEnem.notaMst
              ? formularioEnem.qtdEnviosNotas + 1
              : formularioEnem.qtdEnviosNotas,
        }
      } else if (activeTab == 3) {
        data = {
          Matricula: user?.matricula,
          AnoLetivo: currentYear,
          SituacaoEnem: formularioEnem.situacaoEnem == true ? 1 : 0,
          NomeInstituicaoEnsinoSup: formularioEnem.nomeInstituicaoEnsinoSup,
          CaracteristicaInstituicaoEnsinoSup: formularioEnem.caracteristicaInstituicaoEnsinoSup,
          UfInstituicaoEnsinoSup: formularioEnem.ufInstituicaoEnsinoSup,
          NomeCursoEnsinoSup: formularioEnem.nomeCursoEnsinoSup,
          QtdEnviosSituacao:
            formularioEnem?.qtdEnviosParticipacao == 0 ||
            defaultFormularioEnem.situacaoEnem != formularioEnem.situacaoEnem ||
            defaultFormularioEnem.nomeInstituicaoEnsinoSup != formularioEnem.nomeInstituicaoEnsinoSup ||
            defaultFormularioEnem.caracteristicaInstituicaoEnsinoSup !=
              formularioEnem.caracteristicaInstituicaoEnsinoSup ||
            defaultFormularioEnem.ufInstituicaoEnsinoSup != formularioEnem.ufInstituicaoEnsinoSup ||
            defaultFormularioEnem.nomeCursoEnsinoSup != formularioEnem.nomeCursoEnsinoSup
              ? formularioEnem.qtdEnviosSituacao + 1
              : formularioEnem.qtdEnviosSituacao,
        }
      }
      const res = await api.post(
        `${process.env.REACT_APP_LOGIN_API}/enem/EnviarFormularioEnem/?etapa=${activeTab}`,
        data,
      )
      if ((res as any).status == 200) {
        setUpdateData(!updateData)
      }
      alert('Enviado com sucesso')
    } catch (error) {
      alert('Erro ao enviar. Tente novamente mais tarde.')
    }
  }

  function setDateVariables() {
    if (
      new Date(datasFormularioEnem.dataInicioParticipacaoPrimeiraFase) <= currentDate &&
      currentDate <= new Date(datasFormularioEnem.dataFimParticipacao)
    ) {
      setParticipacaoPrimeiraFase(true)
    }
    if (
      new Date(datasFormularioEnem.dataInicioParticipacaoSegundaFase) <= currentDate &&
      currentDate <= new Date(datasFormularioEnem.dataFimParticipacao)
    ) {
      setParticipacaoSegundaFase(true)
    }
    if (
      new Date(datasFormularioEnem.dataInicioNotas) <= currentDate &&
      currentDate <= new Date(datasFormularioEnem.dataFimNotas)
    ) {
      setInserirNotas(true)
    }
    if (
      new Date(datasFormularioEnem.dataInicioSisu) <= currentDate &&
      currentDate <= new Date(datasFormularioEnem.dataFimSisu)
    ) {
      setFormularioSisu(true)
    }
  }

  return (
    <>
      <Header />
      <Banner />
      <Container>
        <PageWrapper>
          <Tabs
            key="tabPai"
            value={activeTab}
            onChange={(_, newValue: number) => setActiveTab(newValue)}
            sx={{ justifyContent: 'left', alignItems: 'left', width: '100%' }}
            variant="scrollable"
            scrollButtons={false}
          >
            <TabEnemForm label="Dados da Escola" key="dadosEscola" bgColor="gray" />
            <TabEnemForm
              label="Participação no Enem"
              key="participacaoEnem"
              bgColor="orange"
              sx={{
                display: !(currentDate >= new Date(datasFormularioEnem?.dataInicioParticipacaoPrimeiraFase))
                  ? 'none'
                  : 'flex',
              }}
            />
            <TabEnemForm
              label="Notas do Enem"
              key="notasEnem"
              bgColor="green"
              sx={{
                display:
                  currentDate >= new Date(datasFormularioEnem?.dataInicioNotas) &&
                  defaultFormularioEnem?.primeiraFase &&
                  defaultFormularioEnem?.segundaFase
                    ? 'block'
                    : 'none',
              }}
            />
            <TabEnemForm
              label="Situação Aluno"
              key="situacaoAluno"
              bgColor="blue"
              sx={{
                display:
                  currentDate >= new Date(datasFormularioEnem?.dataInicioSisu) &&
                  formularioEnem?.primeiraFase &&
                  formularioEnem?.segundaFase &&
                  notasEnviadas
                    ? 'block'
                    : 'none',
              }}
            />
          </Tabs>
          <Box role="tabpanel" hidden={activeTab !== 0}>
            <SectionTitle>Dados da Escola</SectionTitle>
            <InputGroup gridTemplateColumn="1fr 145px">
              <InputText name="nome_aluno" label="Nome do Aluno" value={user?.nome} disabled />
              <InputText name="matricula" label="Matrícula" value={user?.matricula} disabled />
            </InputGroup>
            <InputText name="nome_escola" label="Nome da Escola" value={user?.escola} disabled />

            <InputGroup gridTemplateColumn="5fr 2fr 2fr 145px">
              <InputText name="composicao" label="Composição" disabled value={user?.composicao} />
              <InputText name="serie" label="Série" disabled value={user?.serie} />
              <InputText name="turma" label="Turma" disabled value={user?.turma} />
              <InputText name="turno" label="Turno" disabled value={getTurno(user?.turno)} />
            </InputGroup>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                disabled={Object.values(error).includes(true)}
                onClick={() => setActiveTab(activeTab + 1)}
                bgcolor="#084C94"
                label="Enviar"
                style={{
                  width: 200,
                  height: 60,
                  marginTop: 35,
                  marginBottom: 50,
                }}
              />
            </Box>
          </Box>
          <Box role="tabpanel" hidden={activeTab !== 1}>
            <SectionTitle>Participação no Enem</SectionTitle>
            <FormRadioEnem>
              {currentDate >= new Date(datasFormularioEnem?.dataInicioParticipacaoPrimeiraFase) && (
                <Box>
                  <h4>Participou do Enem no 1º dia?</h4>
                  <div className="radio">
                    <div className="radio-item">
                      <input
                        type="radio"
                        key="sim1"
                        value={defaultInputValue}
                        checked={formularioEnem.primeiraFase}
                        onChange={() => {
                          setFormularioEnem((prevState) => ({
                            ...prevState,
                            primeiraFase: true,
                            codigoMotivoPrimeiraFase: 0,
                          }))
                        }}
                        name="primeiraFase"
                        disabled={currentDate > new Date(datasFormularioEnem?.dataFimParticipacao)}
                      ></input>
                      <label
                        htmlFor="sim1"
                        onClick={() => {
                          if (currentDate <= new Date(datasFormularioEnem?.dataFimParticipacao)) {
                            setFormularioEnem((prevState) => ({
                              ...prevState,
                              primeiraFase: true,
                              codigoMotivoPrimeiraFase: 0,
                            }))
                          }
                        }}
                      >
                        Sim
                      </label>
                    </div>
                    <input
                      type="radio"
                      key="nao1"
                      name="primeiraFase"
                      value={defaultInputValue}
                      checked={!formularioEnem.primeiraFase}
                      onChange={() =>
                        setFormularioEnem((prevState) => ({
                          ...prevState,
                          primeiraFase: false,
                        }))
                      }
                      disabled={currentDate > new Date(datasFormularioEnem?.dataFimParticipacao)}
                    ></input>
                    <label
                      htmlFor="nao1"
                      onClick={() => {
                        if (currentDate <= new Date(datasFormularioEnem?.dataFimParticipacao)) {
                          setFormularioEnem((prevState) => ({
                            ...prevState,
                            primeiraFase: false,
                          }))
                        }
                      }}
                    >
                      Não
                    </label>
                  </div>

                  <Box hidden={formularioEnem?.primeiraFase}>
                    <h3>Motivo</h3>
                    <Select
                      key="selectMotivo1"
                      className="select"
                      disabled={
                        currentDate > new Date(datasFormularioEnem?.dataFimParticipacao) || formularioEnem?.primeiraFase
                      }
                      labelId="select-label"
                      value={
                        formularioEnem?.codigoMotivoPrimeiraFase != null ? formularioEnem?.codigoMotivoPrimeiraFase : 0
                      }
                      onChange={(e: any) => {
                        setFormularioEnem((prevState) => ({
                          ...prevState,
                          primeiraFase: false,
                          codigoMotivoPrimeiraFase: e.target.value,
                        }))
                      }}
                      sx={{ maxWidth: '100%', minWidth: '100%' }}
                    >
                      <MenuItem key="defaultMotivo1" value={defaultInputValue} disabled defaultChecked>
                        Selecione...
                      </MenuItem>
                      {motivosAusenciaDia1.map((e: any, index: number) => (
                        <MenuItem key={e.descricao + '1'} value={e.codigo}>
                          {e.descricao}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
              )}
              {currentDate >= new Date(datasFormularioEnem?.dataInicioParticipacaoSegundaFase) && (
                <Box>
                  <h4>Participou do Enem no 2º dia?</h4>
                  <div
                    className="radio"
                    key="dia2"
                    style={{
                      justifyContent: 'left',
                      marginBottom: '8px',
                    }}
                  >
                    <input
                      type="radio"
                      key="sim2"
                      value={defaultInputValue}
                      checked={formularioEnem?.segundaFase}
                      name="segundaFase"
                      onChange={() => {
                        setFormularioEnem((prevState) => ({
                          ...prevState,
                          segundaFase: true,
                        }))
                      }}
                      disabled={!participacaoSegundaFase}
                    ></input>
                    <label
                      htmlFor="sim2"
                      onClick={() => {
                        if (currentDate <= new Date(datasFormularioEnem?.dataFimParticipacao)) {
                          setFormularioEnem((prevState) => ({
                            ...prevState,
                            segundaFase: true,
                          }))
                        }
                      }}
                    >
                      Sim
                    </label>
                    <input
                      type="radio"
                      key="nao2"
                      name="segundaFase"
                      value={defaultInputValue}
                      checked={!formularioEnem?.segundaFase}
                      onChange={() =>
                        setFormularioEnem((prevState) => ({
                          ...prevState,
                          segundaFase: false,
                        }))
                      }
                      disabled={!participacaoPrimeiraFase}
                    ></input>
                    <label
                      htmlFor="nao2"
                      onClick={() => {
                        if (currentDate <= new Date(datasFormularioEnem?.dataFimParticipacao)) {
                          setFormularioEnem((prevState) => ({
                            ...prevState,
                            segundaFase: false,
                          }))
                        }
                      }}
                    >
                      Não
                    </label>
                  </div>
                  <Box hidden={formularioEnem?.segundaFase}>
                    <h3>Motivo</h3>
                    <Select
                      key="selectMotivo2"
                      className="select"
                      labelId="select-label"
                      value={
                        formularioEnem?.codigoMotivoSegundaFase != null ? formularioEnem?.codigoMotivoSegundaFase : 0
                      }
                      onChange={(e: any) => {
                        setDefaultInputValue(e.target.value)
                        setFormularioEnem((prevState) => ({
                          ...prevState,
                          segundaFase: false,
                          codigoMotivoSegundaFase: e.target.value,
                        }))
                      }}
                      disabled={
                        currentDate > new Date(datasFormularioEnem?.dataFimParticipacao) || formularioEnem?.segundaFase
                      }
                      sx={{ maxWidth: '100%', minWidth: '100%' }}
                    >
                      <MenuItem key="defaultMotivo2" value={0} disabled defaultChecked>
                        Selecione...
                      </MenuItem>
                      {motivosAusenciaDia2.map((e: any, index: number) => (
                        <MenuItem key={e.descricao + '2'} value={e.codigo}>
                          {e.descricao}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
              )}
              {participacaoPrimeiraFase &&
                participacaoSegundaFase &&
                new Date(datasFormularioEnem.dataFimParticipacao) >= currentDate && (
                  <Box sx={{ marginTop: '30px', textAlign: 'right', color: '#084c94;' }}>
                    (Quantidade restante de alterações permitidas: {2 - formularioEnem.qtdEnviosParticipacao})
                  </Box>
                )}
            </FormRadioEnem>
            <Box sx={{ justifyContent: 'space-around', display: 'flex', maxWidth: '100%' }}>
              <Box>
                <Button
                  disabled={Object.values(error).includes(true)}
                  onClick={() => setActiveTab(activeTab - 1)}
                  bgcolor="#084C94"
                  label="Voltar"
                  style={{
                    width: 200,
                    height: 60,
                    marginTop: 35,
                    marginBottom: 50,
                  }}
                />
              </Box>
              <Box>
                <Button
                  disabled={
                    !validarEnvioParticipacao(formularioEnem, participacaoPrimeiraFase, participacaoSegundaFase) ||
                    (!participacaoPrimeiraFase && !participacaoSegundaFase)
                  }
                  label="Enviar"
                  onClick={async () => {
                    await createSendObject()
                    if (inserirNotas && formularioEnem.primeiraFase && formularioEnem.segundaFase) {
                      setActiveTab(activeTab + 1)
                    }
                  }}
                  bgcolor="#084C94"
                  style={{
                    width: 200,
                    height: 60,
                    marginTop: 35,
                    marginBottom: 50,
                  }}
                />
              </Box>
            </Box>
          </Box>
          {formularioEnem.primeiraFase &&
            formularioEnem.segundaFase &&
            currentDate >= new Date(datasFormularioEnem.dataInicioNotas) && (
              <Box role="tabpanel" hidden={activeTab !== 2}>
                <SectionTitle>Notas do Enem</SectionTitle>
                <GradeLabel>
                  <span>Redação</span>
                  <GradeInput
                    disabled={!inserirNotas}
                    key="notaRedacao"
                    onError={setError}
                    name="redacao"
                    error={error['redacao']}
                    placeholder="0"
                    inputProps={{ maxLength: 4 }}
                    type="string"
                    value={formularioEnem?.notaRedacao != null ? formularioEnem.notaRedacao : ''}
                    errorMessage="Insira uma nota válida"
                    onChange={(e: any) => {
                      e.target.value !== '' && e.target.value.replace(/\D/g, '')
                        ? handleChangeNota(e.target.value, 'notaRedacao') &&
                          setFormularioEnem((prevState) => ({
                            ...prevState,
                            notaRedacao: handleChangeNota(e.target.value.replace(/\D/g, '').slice(0, 4), 'notaRedacao'),
                          }))
                        : setFormularioEnem((prevState) => ({
                            ...prevState,
                            notaRedacao: '',
                          }))
                    }}
                  />
                </GradeLabel>
                <GradeLabel>
                  <span>Ciências Humanas e suas Tecnologias</span>
                  <GradeInput
                    disabled={!inserirNotas}
                    onError={setError}
                    name="cienciasHumanasSuasTecnologias"
                    error={error['cienciasHumanasSuasTecnologias']}
                    placeholder="0,0"
                    value={formularioEnem.notaChst != null ? formularioEnem.notaChst : ''}
                    errorMessage="Insira uma nota válida"
                    onChange={(e: any) => {
                      e.target.value !== '' && e.target.value.replace(/\D/g, '')
                        ? handleChangeNota(e.target.value, e.target.key) &&
                          setFormularioEnem((prevState) => ({
                            ...prevState,
                            notaChst: handleChangeNota(e.target.value, e.target.key),
                          }))
                        : setFormularioEnem((prevState) => ({
                            ...prevState,
                            notaChst: '',
                          }))
                    }}
                  />
                </GradeLabel>
                <GradeLabel>
                  <span>Ciências da Natureza e suas Tecnologias</span>
                  <GradeInput
                    disabled={!inserirNotas}
                    onError={setError}
                    placeholder="0,0"
                    name="cienciasNaturezaSuasTecnologias"
                    value={formularioEnem.notaCnst != null ? formularioEnem.notaCnst : ''}
                    error={error['cienciasNaturezaSuasTecnologias']}
                    errorMessage="Insira uma nota válida"
                    onChange={(e: any) => {
                      e.target.value !== '' && e.target.value.replace(/\D/g, '')
                        ? handleChangeNota(e.target.value, e.target.key) &&
                          setFormularioEnem((prevState) => ({
                            ...prevState,
                            notaCnst: handleChangeNota(e.target.value, e.target.key),
                          }))
                        : setFormularioEnem((prevState) => ({
                            ...prevState,
                            notaCnst: '',
                          }))
                    }}
                  />
                </GradeLabel>
                <GradeLabel>
                  <span>Linguagens, Códigos e suas Tecnologias</span>
                  <GradeInput
                    disabled={!inserirNotas}
                    onError={setError}
                    placeholder="0,0"
                    name="linguagemCodigoSuasTecnologias"
                    value={formularioEnem.notaLcst != null ? formularioEnem.notaLcst : ''}
                    error={error['linguagemCodigoSuasTecnologias']}
                    errorMessage="Insira uma nota válida"
                    onChange={(e: any) => {
                      e.target.value !== '' && e.target.value.replace(/\D/g, '')
                        ? handleChangeNota(e.target.value, e.target.key) &&
                          setFormularioEnem((prevState) => ({
                            ...prevState,
                            notaLcst: handleChangeNota(e.target.value, e.target.key),
                          }))
                        : setFormularioEnem((prevState) => ({
                            ...prevState,
                            notaLcst: '',
                          }))
                    }}
                  />
                </GradeLabel>
                <GradeLabel>
                  <span>Matemática e suas Tecnologias</span>
                  <GradeInput
                    disabled={!inserirNotas}
                    placeholder="0,0"
                    type="string"
                    name="matematicaSuasTecnologias"
                    value={formularioEnem.notaMst != null ? formularioEnem.notaMst : ''}
                    error={error['matematicaSuasTecnologias']}
                    errorMessage="Insira uma nota válida"
                    onChange={(e: any) => {
                      e.target.value !== '' && e.target.value.replace(/\D/g, '')
                        ? handleChangeNota(e.target.value, e.target.key) &&
                          setFormularioEnem((prevState) => ({
                            ...prevState,
                            notaMst: handleChangeNota(e.target.value, e.target.key),
                          }))
                        : setFormularioEnem((prevState) => ({
                            ...prevState,
                            notaMst: '',
                          }))
                    }}
                  />
                </GradeLabel>
                {inserirNotas && new Date(datasFormularioEnem.dataFimNotas) >= currentDate && (
                  <Box sx={{ marginTop: '30px', textAlign: 'right', color: '#084c94;' }}>
                    (Quantidade restante de alterações permitidas: {2 - formularioEnem.qtdEnviosNotas})
                  </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Box>
                    <Button
                      disabled={Object.values(error).includes(true)}
                      onClick={() => setActiveTab(activeTab - 1)}
                      bgcolor="#084C94"
                      label="Voltar"
                      style={{
                        width: 200,
                        height: 60,
                        marginTop: 35,
                        marginBottom: 50,
                      }}
                    />
                  </Box>
                  <Box>
                    <Button
                      disabled={
                        !validarEnvioDeNotas(formularioEnem) ||
                        !inserirNotas ||
                        formularioEnem.qtdEnviosNotas >= 2 ||
                        !inserirNotas
                      }
                      onClick={async () => {
                        await createSendObject(), formularioSisu && setActiveTab(activeTab + 1)
                      }}
                      bgcolor="#084C94"
                      label="Enviar"
                      style={{
                        width: 200,
                        height: 60,
                        marginTop: 35,
                        marginBottom: 50,
                        justifyContent: 'end',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          {formularioEnem.primeiraFase &&
            formularioEnem.segundaFase &&
            currentDate >= new Date(datasFormularioEnem?.dataInicioSisu) && (
              <Box role="tabpanel" hidden={activeTab !== 3}>
                <SectionTitle>Situação Aluno</SectionTitle>
                <FormRadioEnem>
                  <h4>O aluno foi aprovado para ingressar em alguma instituição de Ensino Superior?</h4>
                  <Box className="radio">
                    <div className="radio-item">
                      <input
                        disabled={!formularioSisu || defaultFormularioEnem.qtdEnviosSituacao >= 2}
                        key="simAprov"
                        type="radio"
                        name="simAprov"
                        value={'simAprov'}
                        onClick={() => {
                          setFormularioEnem((prevState) => ({
                            ...prevState,
                            situacaoEnem: true,
                          }))
                        }}
                        onChange={() => {
                          setRespondeuAprovacao(true)
                        }}
                        checked={formularioEnem.situacaoEnem && respondeuAprovacao}
                      ></input>
                      <label
                        htmlFor="simAprov"
                        onClick={() => {
                          if (formularioSisu && defaultFormularioEnem.qtdEnviosSituacao < 2) {
                            setFormularioEnem((prevState) => ({
                              ...prevState,
                              situacaoEnem: true,
                            })),
                              setRespondeuAprovacao(true)
                          }
                        }}
                      >
                        Sim
                      </label>
                    </div>
                    <input
                      disabled={!formularioSisu || defaultFormularioEnem.qtdEnviosSituacao >= 2}
                      type="radio"
                      value="naoAprov"
                      key="naoAprov"
                      name="naoAprov"
                      onClick={() => {
                        setFormularioEnem((prevState) => ({
                          ...prevState,
                          situacaoEnem: false,
                          nomeInstituicaoEnsinoSup: '',
                          caracteristicaInstituicaoEnsinoSup: 0,
                          nomeCursoEnsinoSup: '',
                          ufInstituicaoEnsinoSup: 0,
                        }))
                      }}
                      onChange={() => {
                        setRespondeuAprovacao(true)
                      }}
                      checked={!formularioEnem?.situacaoEnem && respondeuAprovacao}
                    ></input>
                    <label
                      htmlFor="naoAprov"
                      onClick={() => {
                        if (formularioSisu && defaultFormularioEnem.qtdEnviosSituacao < 2) {
                          setFormularioEnem((prevState) => ({
                            ...prevState,
                            situacaoEnem: false,
                          })),
                            setRespondeuAprovacao(true)
                        }
                      }}
                    >
                      Não
                    </label>
                  </Box>
                </FormRadioEnem>
                {formularioEnem.situacaoEnem && (
                  <>
                    <FormRadioEnem>
                      <Box>
                        <h4>Nome da Instituição</h4>
                        <InputText
                          disabled={!formularioSisu || defaultFormularioEnem.qtdEnviosSituacao >= 2}
                          name="nome_instituicao"
                          value={
                            formularioEnem.nomeInstituicaoEnsinoSup != null
                              ? formularioEnem.nomeInstituicaoEnsinoSup
                              : ''
                          }
                          onChange={(e: any) =>
                            setFormularioEnem((prevState) => ({
                              ...prevState,
                              nomeInstituicaoEnsinoSup: e.target.value,
                            }))
                          }
                        />
                        <h4>Característica da Instituição</h4>
                        <Select
                          sx={{ maxWidth: '100%', minWidth: '100%' }}
                          disabled={!formularioSisu || defaultFormularioEnem.qtdEnviosSituacao >= 2}
                          key="selectCaracteristica"
                          className="select"
                          labelId="select-label"
                          value={
                            formularioEnem.caracteristicaInstituicaoEnsinoSup != null
                              ? formularioEnem.caracteristicaInstituicaoEnsinoSup
                              : 0
                          }
                          onChange={(e: any) =>
                            setFormularioEnem((prevState) => ({
                              ...prevState,
                              caracteristicaInstituicaoEnsinoSup: e.target.value,
                            }))
                          }
                        >
                          <MenuItem key="defaultCaracteristica" value={0} disabled defaultChecked>
                            Selecione...
                          </MenuItem>
                          {caracteristicasInstituicao.map((e: any, index: number) => (
                            <MenuItem key={e.descricao + 'caracteristica'} value={e.codigo}>
                              {e.descricao}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box>
                        <h4>UF da Instituição</h4>
                        <Select
                          disabled={!formularioSisu || defaultFormularioEnem.qtdEnviosSituacao >= 2}
                          key="selectUf"
                          className="select"
                          labelId="select-label"
                          value={
                            formularioEnem.ufInstituicaoEnsinoSup != null ? formularioEnem.ufInstituicaoEnsinoSup : 0
                          }
                          onChange={(e: any) =>
                            setFormularioEnem((prevState) => ({
                              ...prevState,
                              ufInstituicaoEnsinoSup: e.target.value,
                            }))
                          }
                          sx={{ maxWidth: '100%', minWidth: '100%' }}
                        >
                          <MenuItem key="defaultUf" value={0} disabled defaultChecked>
                            Selecione...
                          </MenuItem>
                          {listagemUf.map((e: any, index: number) => (
                            <MenuItem key={e.descricao + 'uf'} value={e.codigo}>
                              {e.descricao + ' - ' + e.sigla}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <h4>Nome do Curso</h4>
                      <InputText
                        disabled={!formularioSisu || defaultFormularioEnem.qtdEnviosSituacao >= 2}
                        name="nomeCurso"
                        key="nomeCurso"
                        value={formularioEnem.nomeCursoEnsinoSup != null ? formularioEnem.nomeCursoEnsinoSup : ''}
                        onChange={(e: any) =>
                          setFormularioEnem((prevState) => ({
                            ...prevState,
                            nomeCursoEnsinoSup: e.target.value,
                          }))
                        }
                      />
                    </FormRadioEnem>
                  </>
                )}
                {formularioSisu && new Date(datasFormularioEnem.dataFimSisu) >= currentDate && (
                  <Box sx={{ marginTop: '30px', textAlign: 'right', color: '#084c94;' }}>
                    (Quantidade restante de alterações permitidas: {2 - formularioEnem.qtdEnviosSituacao})
                  </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Box>
                    <Button
                      disabled={Object.values(error).includes(true)}
                      onClick={() => setActiveTab(activeTab - 1)}
                      bgcolor="#084C94"
                      label="Voltar"
                      style={{
                        width: 200,
                        height: 60,
                        marginTop: 35,
                        marginBottom: 50,
                        justifyContent: 'end',
                      }}
                    />
                  </Box>
                  <Box>
                    <Button
                      disabled={
                        !formularioSisu ||
                        !respondeuAprovacao ||
                        (formularioEnem.situacaoEnem &&
                          (formularioEnem.nomeInstituicaoEnsinoSup == '' ||
                            !formularioEnem.nomeInstituicaoEnsinoSup ||
                            formularioEnem.caracteristicaInstituicaoEnsinoSup == 0 ||
                            !formularioEnem.caracteristicaInstituicaoEnsinoSup ||
                            formularioEnem.nomeCursoEnsinoSup == '' ||
                            !formularioEnem.nomeCursoEnsinoSup ||
                            formularioEnem.ufInstituicaoEnsinoSup == 0 ||
                            !formularioEnem.ufInstituicaoEnsinoSup)) ||
                        defaultFormularioEnem.qtdEnviosSituacao >= 2
                      }
                      onClick={() => {
                        createSendObject()
                      }}
                      bgcolor="#084C94"
                      label="Enviar"
                      style={{
                        width: 200,
                        height: 60,
                        marginTop: 35,
                        marginBottom: 50,
                        justifyContent: 'end',
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          {/* ... your loading indicator, error messages, and button ... */}
        </PageWrapper>
      </Container>
      <Footer />
    </>
  )
}

export default EnemForm
