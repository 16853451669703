import styled from 'styled-components/macro'

type ContainerProps = {
  mt?: number;
  mb?: number;
  pt?: number;
  pb?: number;
};

export const Container = styled.div<ContainerProps>`
  margin: auto;
  padding: 0 1rem;
  margin-top: ${({ mt }) => mt || 0}px;
  margin-bottom: ${({ mb }) => mb || 0}px;
  padding-top: ${({ pt }) => pt || 0}px;
  padding-bottom: ${({ pb }) => pb || 0}px;
  min-height: 70px;
  width: 100%;
  max-width: 1366px;
  position: relative;
`

type RowProps = {
  justify?: string;
  align?: string;
  mt?: number;
  mb?: number;
};
export const Row = styled.div<RowProps>`
  display: flex;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'flex-start'};
  margin-top: ${({ mt }) => mt || 0}px;
  margin-bottom: ${({ mb }) => mb || 0}px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Col = styled.div`
  flex: 1;
`
