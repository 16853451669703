import styled from 'styled-components/macro'

export const Banner = styled.div`
  width: 100%;
  height: 230px;
  background-color: lightgray;
  background: url(${({ theme }) => theme.banner}) center center;
  background-size: cover;
  margin-bottom: 70px;

  @media (max-width: 980px) {
    height: 130px;
    margin-bottom: 30px;
  }
`

export default Banner
