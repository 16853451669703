function EdFisica () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
      <rect
        x="668.69"
        y="79.46"
        width="150.33"
        height="513.37"
        rx="39.37"
        transform="translate(1507.53 47.86) rotate(135)"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="15"
      />
      <rect
        x="794.31"
        y="0.94"
        width="150.33"
        height="419.17"
        rx="35.58"
        transform="translate(1633.15 -255.43) rotate(135)"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="15"
      />
      <rect
        x="260.98"
        y="487.17"
        width="150.33"
        height="513.37"
        rx="39.37"
        transform="translate(-427.53 455.56) rotate(-45)"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="15"
      />
      <rect
        x="135.36"
        y="659.89"
        width="150.33"
        height="419.17"
        rx="35.58"
        transform="translate(-553.15 403.53) rotate(-45)"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="15"
      />
      <path
        d="M109.58,913.8h0a39.29,39.29,0,0,0,0,55.56L124,983.74a39.28,39.28,0,0,0,55.55,0h0Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="15"
      />
      <rect
        x="363.71"
        y="498.82"
        width="362.67"
        height="98.91"
        transform="translate(-228.05 545.99) rotate(-45)"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="14.61"
      />
      <path
        d="M919.84,108.31l69.94,69.94h0a44,44,0,0,0,0-62.27l-7.68-7.67a44,44,0,0,0-62.26,0Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="15"
      />
    </svg>
  )
}

export default EdFisica
