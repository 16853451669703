function ApoioAluno () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
      <path
        d="M1011.73,1055.22H68.27a48.94,48.94,0,0,1-48.93-48.94V73.72A48.94,48.94,0,0,1,68.27,24.78H242.9a49,49,0,0,1,42.13,24l50.23,85a48.94,48.94,0,0,0,42.13,24H937a123.71,123.71,0,0,1,123.7,123.71v724.73A48.94,48.94,0,0,1,1011.73,1055.22Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="20.36"
      />
      <line
        x1="19.34"
        y1="396.26"
        x2="1060.66"
        y2="396.26"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="20.36"
      />
    </svg>
  )
}

export default ApoioAluno
