function Ciencias () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
      <ellipse
        cx="540"
        cy="540"
        rx="514.06"
        ry="202.32"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="16.87"
      />
      <ellipse
        cx="540"
        cy="540"
        rx="514.06"
        ry="202.32"
        transform="translate(-197.65 737.65) rotate(-60)"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="16.87"
      />
      <ellipse
        cx="540"
        cy="540"
        rx="202.32"
        ry="514.06"
        transform="translate(-197.65 342.35) rotate(-30)"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="16.87"
      />
      <circle cx="540" cy="540" r="87.8" fill="none" stroke="#1a1a1a" strokeMiterlimit="10" strokeWidth="16.87" />
    </svg>
  )
}

export default Ciencias
