import styled from 'styled-components/macro'
import Lottie from 'lottie-react'
import floral from '../../assets/lotties/floral-loading-animation.json'

const AnimationWrapper = styled.div`
  max-width: 180px;
  max-height: 180px;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  margin: auto;
`

const LoadingText = styled.p`
  text-align: center;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.primary};
  position: relative;
  top: -45px;
`

const ContentLoading = () => {
  return (
    <AnimationWrapper>
      <Lottie animationData={floral} style={{ maxWidth: '100%' }} />
      <LoadingText>Carregando conteúdo</LoadingText>
    </AnimationWrapper>
  )
}

export default ContentLoading
