import React, { useEffect, useState } from 'react'
import Banner from '../../components/Media/Banner'
import ContentLoading from '../../components/feedback/LoadingSpinner'
import Footer from '../../components/layout/Footer/Footer'
import { Container } from '../../components/layout/Grid/Grid'
import Header from '../../components/layout/Header/Header'
import Title from '../../components/Typography/Title'
import api from '../../services/api'
import themes from '../../assets/styles/themes'
import { PageContent, PageWrapper } from './style'

const Single: React.FC<any> = ({ match }) => {
  const [page, setPage] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    async function getPageContent() {
      try {
        const { slug } = match.params
        const content = await api.get(`/pages?slug=${slug}&_fields=title,content`)
        setPage(content.data[0])
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    getPageContent()
  }, [match.params])

  return (
    <>
      <Header />
      <Banner />
      <Container>
        <PageWrapper>
          <Title uppercase={false} textcolor={themes.home.primary}>
            {page?.title?.rendered}
          </Title>
          {loading ? <ContentLoading /> : <PageContent dangerouslySetInnerHTML={{ __html: page?.content?.rendered }} />}
        </PageWrapper>
      </Container>
      <Footer />
    </>
  )
}

export default Single
