function Historia () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
      <path
        d="M395.08,877.39h556.3a37,37,0,0,1,37,36.45c.68,50.06-9.26,134.59-83.32,140.31-105.66,8.16-635.55,0-635.55,0s93,5.65,88.59-138.55A37,37,0,0,1,395.08,877.39Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="17.29"
      />
      <path
        d="M269.51,1054.15s-83.13.39-83.13-96.73V731.54a30.07,30.07,0,0,1,8.1-20.54l35.09-37.57a30.09,30.09,0,0,0-3.89-44.59l-27.31-20.56a30.12,30.12,0,0,1-12-24V164.67"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="17.29"
      />
      <path
        d="M863.5,877.39V730.28a26.86,26.86,0,0,0-7.25-18.36l-38.38-41.1A26.89,26.89,0,0,1,821.35,631l31.43-23.67a26.88,26.88,0,0,0,10.72-21.48V112.9"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="17.29"
      />
      <path
        d="M874.64,110.62,176,166.24a32.58,32.58,0,0,1-35.13-31l-2.08-45.56a32.58,32.58,0,0,1,31.06-34L869.57,23.71a32.58,32.58,0,0,1,34,31.05l1,21.9A32.58,32.58,0,0,1,874.64,110.62Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="17.29"
      />
      <line
        x1="128.74"
        y1="112.9"
        x2="80.44"
        y2="117.53"
        fill="none"
        stroke="#1a1a1a"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="30"
      />
      <line
        x1="963.01"
        y1="56.99"
        x2="914.71"
        y2="61.63"
        fill="none"
        stroke="#1a1a1a"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="30"
      />
      <line
        x1="313.02"
        y1="246.51"
        x2="745.01"
        y2="246.51"
        fill="none"
        stroke="#1a1a1a"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="17.29"
      />
      <line
        x1="313.02"
        y1="372.37"
        x2="745.01"
        y2="372.37"
        fill="none"
        stroke="#1a1a1a"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="17.29"
      />
      <line
        x1="313.02"
        y1="498.24"
        x2="745.01"
        y2="498.24"
        fill="none"
        stroke="#1a1a1a"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="17.29"
      />
      <line
        x1="313.02"
        y1="624.11"
        x2="745.01"
        y2="624.11"
        fill="none"
        stroke="#1a1a1a"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="17.29"
      />
      <line
        x1="313.02"
        y1="749.97"
        x2="745.01"
        y2="749.97"
        fill="none"
        stroke="#1a1a1a"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="17.29"
      />
    </svg>
  )
}

export default Historia
