import React, { useEffect, useState } from 'react'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { htmlDecode } from '../../../helpers'
import api from '../../../services/api'
import PostsState from '../../../store/PostsAtom'
import SubjectAtom from '../../../store/SubjectsAtom'
import ContentLoading from '../../feedback/LoadingSpinner'
import Pagination from '../../DataDisplay/Pagination/Pagination'
import bgCover from '../../../assets/images/bg-cover.jpg'
import UserState from '../../../store/UserAtom'
import { ContentPosts, GridPosts, LastPosts, Title } from './style'

type Post = {
  id: number
  title: { rendered: string }
  slug: string
  content: string
  image_url: any
}

type RouteParams = {
  comp: string
  serie: string
  turma: string
}

const TitleList: React.FC = () => {
  const [subject] = useRecoilState(SubjectAtom)
  const [loading, setLoading] = useState(true)
  const { comp, serie } = useParams<RouteParams>()
  const [posts, setPosts] = useRecoilState(PostsState)
  const [activePage, setActivePage] = useState(1)
  const { url } = useRouteMatch()
  const user = useRecoilValue(UserState)

  useEffect(() => {
    async function getPosts(subjectid: number) {
      !loading && setLoading(true)

      let url = `${comp}?_fields=id,slug,title,content,componente,cod_componente,codigo_atividade,image_url`
      serie && (url += `&cat_serie_${comp}=${serie}`)
      subject && (url += `&cat_assuntos_${comp}=${subject}`)
      activePage && (url += `&page=${activePage}`)

      try {
        const posts = await api.get(url)
        const totalPages = parseInt(posts.headers['x-wp-totalpages'])
        setPosts({
          data: posts.data,
          totalPages,
        })
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    getPosts(subject)
  }, [subject, activePage])

  // Oculta os conteudos com "professor" no título para os alunos
  const listPosts = posts.data
    .filter(function (item: Post) {
      const title = '' + item.title.rendered
      const splitTitle = title.split(' ')

      return (user?.tipoUsuario !== 2 && splitTitle.indexOf("professor")  === -1) && (user?.tipoUsuario !== 2 && splitTitle.indexOf("Professor")  === -1) || user?.tipoUsuario === 2;
    })
    .map((post: Post, i) => (
      <ContentPosts key={post.id} imageUrl={post.image_url || bgCover}>
        <Link key={post.id} to={url + post.slug}>
          <Title>{htmlDecode(post.title.rendered)}</Title>
        </Link>
      </ContentPosts>
    ))

  return (
    <>
      <div style={{ marginBottom: 30 }}>
        {loading ? (
          <ContentLoading />
        ) : (
          <>
            {!subject && <LastPosts>Últimas Postagens:</LastPosts>}
            <GridPosts>{listPosts}</GridPosts>
          </>
        )}
      </div>
      <Pagination activePage={activePage} totalPages={posts.totalPages} setPage={setActivePage} />
    </>
  )
}

export default TitleList
