import styled from 'styled-components/macro'

export const ModalOverlay = styled.div<any>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: ${({ show, removeModal }) => (show && !removeModal ? 'grid' : 'none')};
  place-items: center;
`

export const ModalCard = styled.div`
  position: relative;
  max-width: 80%;
  background: #fff;
  border-radius: 3px;
  box-shadow: var(--shadow);
  padding: 10px;
  color: #084c94;
  margin: 10px;

  h3 {
    margin-bottom: 20px;
  }

  & > div {
    margin-top: 25px;
    display: flex;
  }

  img {
    max-width: 100%;
  }

  span {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`
