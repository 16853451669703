import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const MenuButton = styled.div`
  position: fixed;
  right: 10px;
  display: none;
  cursor: pointer;

  @media (max-width: 770px) {
    top: 29px;
  }

  @media (max-width: 980px) {
    display: grid;
  }

  div {
    width: 22px;
    height: 3px;
    background-color: ${({ theme }) => theme.primary};
    margin: 2px 0;
  }
`

export const MenuContainer = styled.div<{ open: boolean }>`
  position: fixed;
  height: 100%;
  width: 280px;
  padding: 15px;
  left: ${({ open }) => (open ? 0 : '-280')}px;
  background: whitesmoke;
  z-index: 9999;
  transition: 300ms left ease;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: ${({ theme }) => theme.primary};
`

export const UserData = styled.div`
  text-align: center;
  p {
    font-size: 0.8rem;
    margin-bottom: 5px;
    text-align: center;
    text-transform: capitalize;
  }
`

export const Divisor = styled.hr`
  color: #fff;
  opacity: 0.3;
  margin: 15px 0;
`

export const SideMenuNav = styled.div`
  align-self: flex-start;
`

export const SideMenuItem = styled(Link)`
  color: inherit;
  margin-bottom: 20px;
  &:active {
    color: inherit;
  }

  span {
    margin-left: 5px;
  }
`

export const LogoutButton = styled.button`
  background: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: ${({ theme }) => theme.primary};
`
