import styled from 'styled-components'
import InputText from '../../components/forms/InputText/InputText'
import Tab from '@mui/material/Tab'

type TabEnemFormProps = {
  bgColor?: string
}

export const PageWrapper = styled.div`
  min-height: 300px;
  max-width: 600px;
  margin: auto;

  span {
    color: ${({ theme }) => theme.primary};
    font-family: Raleway, sans-serif;
  }
`

export const SectionTitle = styled.h3`
  width: 100%;
  padding: 10px 5px;
  border-bottom: 2px solid;
  border-color: ${({ theme }) => theme.primary};
  font-size: 24px;
  font-weight: 300;
  color: ${({ theme }) => theme.primary};
  margin-bottom: 30px;
`

export const InputGroup = styled.div<any>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumn }) => gridTemplateColumn};
  grid-gap: 15px;
`

export const GradeLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  span {
    flex: 1;
    font-size: 22px;
    color: ${({ theme }) => theme.primary};
    font-family: Raleway, sans-serif;
  }
`

export const TabEnemForm = styled(Tab)<TabEnemFormProps>`
  && {
    background-color: ${({ bgColor }) => bgColor};
    text-transform: none;
    color: white;
    font-family: Segoe UI-Regular, Helvetica;
    border-radius: 20px 20px 2px 2px;
  }

  &.MuiTab-root.Mui-selected {
    color: white;
    height: 50px;
  }
`

export const GradeInput = styled(InputText)`
  background: #efefef;
  border: none;
  border-bottom: 1px solid #707070;
  width: 120px;
  height: 45px;
  font-family: Raleway, sans-serif;
  padding: 5px;
  font-size: 22px;
  transition: 500ms all ease-in;
  outline: none;
  text-align: center;
  color: ${({ theme }) => theme.primary};
`
export const FormRadioEnem = styled.form`
  margin: 10px;
  margin-left: 0;
  color: #084c94;
  font-weight: normal;
  font-family: 'Calibri', sans-serif;

  h4 {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .radio {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin: 8px;

    input {
      margin-right: 5px;
      margin-left: 20px;
    }
  }

  .select {
    background: #efefef;
    border: 0;
    outline: 0;
    border-width: 0;
  }
`
