import React, { useState } from 'react'
import Header from '../../components/layout/Header/Header'
import Footer from '../../components/layout/Footer/Footer'
import InputText from '../../components/forms/InputText/InputText'
import { Container } from '../../components/layout/Grid/Grid'
import Title from '../../components/Typography/Title'
import Button from '../../components/forms/Button'
import api from '../../services/api'
import { Link } from 'react-router-dom'
import SendEmailLoading from '../../components/feedback/SendEmailLoading'
import TextArea from '../../components/forms/TextArea/TextArea'
import { ButtonWrapper, FormWrapper, SuccessMessage } from './style'

const Contact: React.FC<any> = () => {
  const [formData, setFormData] = useState({})
  const [inputErrors, setInputErrors] = useState<any>({})
  const [sendStatus, setSendStatus] = useState<boolean | string>(false)

  async function sendEmail() {
    setSendStatus(true)
    try {
      await api.post(`${process.env.REACT_APP_HOST}/wp-json/contact/send`, formData)
      setSendStatus('success')
    } catch (error: any) {
      setSendStatus(false)
    }
  }

  function handleSendStatus() {
    switch (sendStatus) {
      case false:
        return <Button disabled={Object.values(inputErrors).includes(true)} label="Enviar" onClick={sendEmail} />
      case true:
        return <SendEmailLoading />
      case 'success':
        return (
          <SuccessMessage>
            Mensagem enviada com sucesso! <Link to="/home">Voltar</Link>
          </SuccessMessage>
        )
    }
  }

  return (
    <>
      <Header />
      <Container pt={70} pb={15}>
        <Title>Contato</Title>
        <FormWrapper>
          <InputText
            name="contact_name"
            getValue={setFormData}
            placeholder="Nome"
            validation="required"
            onError={setInputErrors}
            errorMessage="Informe a senha"
          />
          <InputText
            getValue={setFormData}
            name="contact_email"
            placeholder="Email"
            validation="email"
            onError={setInputErrors}
            errorMessage="Informe a senha"
          />
          <TextArea
            rows="3"
            getValue={setFormData}
            name="contact_message"
            placeholder="Mensagem"
            validation="required"
            onError={setInputErrors}
            errorMessage="Informe a senha"
          />
          <ButtonWrapper>{handleSendStatus()}</ButtonWrapper>
        </FormWrapper>
      </Container>
      <Footer />
    </>
  )
}

export default Contact
