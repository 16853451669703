import styled from 'styled-components/macro'

export const FooterContainer = styled.footer`
  min-height: 60px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.primary};
`

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const TextFooter = styled.p`
  color: #fff;
  line-height: 28px;
  font-size: 14px;
  max-width: 33.333%;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 15px;
  }
`

export const TagText = styled.p`
  font-size: 18px;
  align-self: flex-end;
  color: #fff;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`

export const BottomFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 15px;
  background-color: ${({ theme }) => theme.secondary};
`
