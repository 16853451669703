import Lottie from 'lottie-react'
import smallLoading from '../../../assets/lotties/small-loading.json'
import { useEffect, useState } from 'react'
import { LoadingWrapper } from './style'

type Props = {
  loading: string | boolean;
  onAnimationFinish?: Function;
};

const LoadIndicator = ({
  loading = false,
  onAnimationFinish = () => { },
}: Props) => {
  const [segments, setSegments] = useState<number[]>([0, 60])

  useEffect(() => {
    if (loading === 'done') setSegments([60, 100])
  }, [loading])

  return (
    <LoadingWrapper>
      <Lottie
        animationData={smallLoading}
        initialSegment={[segments[0], segments[1]]}
        style={{ maxWidth: 70 }}
        loop={loading !== 'done'}
      />
    </LoadingWrapper>
  )
}

export default LoadIndicator
