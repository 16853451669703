import jwtDecode from 'jwt-decode'
import { UserRole } from '../domain/enuns/UserRole'
import { Student } from '../domain/entities/Student'
import { Teacher } from '../domain/entities/Teacher'
import api from './api'
import { setLocalStorageItem } from '../helpers'

export async function handleLogin(loginData: any, token: string = ''): Promise<Student | Teacher> {
  try {
    let url
    let authToken
    let credentials

    if (token) {
      const user = jwtDecode(token) as Record<any, any>
      const userType = user['role']
      authToken = token
      credentials = { token: user['userToken'] }

      url =
        userType === UserRole.teacher
          ? `${process.env.REACT_APP_LOGIN_API}/user/GetServidorByToken`
          : `${process.env.REACT_APP_LOGIN_API}/user/GetAlunoByToken`
    } else {
      const authData = await api.post(`${process.env.REACT_APP_LOGIN_API}/account/login`, loginData)
      const user = jwtDecode(authData.data.access_token) as Record<any, any>
      authToken = authData.data.access_token
      credentials = { user: loginData.username, password: loginData.password }

      url =
        user['role'] === UserRole.teacher
          ? `${process.env.REACT_APP_LOGIN_API}/user/GetServidor`
          : `${process.env.REACT_APP_LOGIN_API}/user/GetAluno`
    }

    const userData = await api.post(url, credentials, {
      headers: { Authorization: `Bearer ${authToken}` },
    })

    const storageData = { ...userData.data, authToken }
    setLocalStorageItem('netescola', storageData)

    return userData.data
  } catch (error) {
    throw error
  }
}
