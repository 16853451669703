import Content from '../../components/DataDisplay/Content/Content'
import themeState from '../../store/themeAtom'
import TitleList from '../../components/DataDisplay/TitleList/TitleList'
import Title from '../../components/Typography/Title'
import { useSetRecoilState } from 'recoil'
import { Switch, Route } from 'react-router-dom'
import { Container } from '../../components/layout/Grid/Grid'
import SideMenu from '../../components/layout/SideMenu/SideMenu'
import Banner from '../../components/Media/Banner'
import Subtitle from '../../components/Typography/Subtitle'
import Header from '../../components/layout/Header/Header'
import Footer from '../../components/layout/Footer/Footer'
import { useEffect, useState } from 'react'
import { getQueryParams } from '../../helpers'
import SubjectAtom from '../../store/SubjectsAtom'
import { ContentWrapper, FlexContainer, MainContent } from './style'

function ContentList({ match, location }: any) {
  const {
    path,
    params: { comp },
  } = match
  const setTheme = useSetRecoilState(themeState)
  const setSubject = useSetRecoilState(SubjectAtom)
  const [open, setOpen] = useState(true)
  const { title, turma } = getQueryParams(location.search)
  const [pageInfo] = useState({ title, turma })

  useEffect(() => {
    setTheme(comp)
    return () => {
      setSubject(0)
    }
  }, [])

  return (
    <MainContent>
      <Header />
      <Banner />
      <Title>{pageInfo?.title}</Title>
      <Subtitle>{pageInfo?.turma}</Subtitle>
      <Container mt={58}>
        <FlexContainer>
          <SideMenu handlerMenu={setOpen} page={pageInfo} />
          <ContentWrapper open={open}>
            <Switch>
              <Route exact path={path}>
                <TitleList />
              </Route>
              <Route path={`${path}/:slug`}>
                <Content />
              </Route>
            </Switch>
          </ContentWrapper>
        </FlexContainer>
      </Container>
      <Footer />
    </MainContent>
  )
}

export default ContentList
