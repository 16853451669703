import styled from 'styled-components/macro'

type SubtitleProps = {
  uppercase?: boolean;
};

export const Subtitle = styled.p<SubtitleProps>`
  color: ${({ theme }) => theme.primary};
  text-align: center;
  font-family: "BwMitga", -apple-system, “Segoe UI”, “Roboto”, sans-serif;
  margin-top: 2rem;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-top: 1rem;
  }
`

export default Subtitle
