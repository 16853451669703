import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { getQueryParams, setLocalStorageItem } from '../../helpers'
import UserState from '../../store/UserAtom'
import Lottie from 'lottie-react'
import floral from '../../assets/lotties/floral-loading-animation.json'
import IllustrationSVG from '../../components/Media/IllustrationSVG'
import InputText from '../../components/forms/InputText/InputText'
import { handleLogin } from '../../services/loginService'
import ga from 'react-ga'
import {
  ContentWrapper,
  Description,
  ErrorMessage,
  FirstLogin,
  FormLabel,
  InputsWrap,
  LoadingWrapper,
  LoginBox,
  LoginButton,
  LoginContainer,
  Logo,
  LogoSeduc,
} from './style'

const LoginPage = ({ location, history }: any) => {
  const [loginData, setLoginData] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [inputErrors, setInputErrors] = useState<any>({})
  const [loginError, setLoginError] = useState<boolean>(false)
  const { token } = getQueryParams(location?.search)
  const setUser = useSetRecoilState(UserState)

  useLayoutEffect(() => {
    if (token) login(token)
  }, [token])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ga.pageview('/login')
    }
  }, [])

  const login = async (token: string = '') => {
    setLoginError(false)

    if (Object.values(inputErrors).includes(true)) return
    if (!loginData.username && !token) return

    try {
      setLoading(true)
      const userData = await handleLogin(loginData, token)
      setUser(userData)
      setLoading(false)
      history.push('/')
    } catch (error) {
      setLoading(false)
      setLoginError(true)
    }
  }

  function handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      login()
    }
  }

  return (
    <LoginContainer>
      <ContentWrapper>
        <LoginBox>
          <Logo>NetEscola</Logo>
          <Description>
            Olá,
            <br />
            Seja bem-vindo à plataforma de conteúdos didáticos da rede pública estadual de ensino! Aqui você encontra
            videoaulas, materiais, atividades e listas de exercícios produzidas pelos professores da Secretaria de
            Estado de Educação. Agora ficou muito mais fácil aprender pela Internet! Vamos juntos?
          </Description>
          <FormLabel>{!token ? 'Acessar' : 'Aguarde'}</FormLabel>
          <p style={{ marginTop: '20px', color: '#888' }}>O sistema só permite acesso a usuários da rede estadual.</p>
          <InputsWrap>
            <>
              {!token && (
                <>
                  {/* <ToggleSwitch handleChange={setIsTeacher} /> */}
                  <form style={{ width: '100%' }}>
                    <InputText
                      name="username"
                      placeholder="Usuário"
                      getValue={setLoginData}
                      errorMessage="Insira um usuário válido"
                      validation="required"
                      onError={setInputErrors}
                    />
                    <InputText
                      name="password"
                      placeholder="Senha"
                      getValue={setLoginData}
                      errorMessage="Informe a senha"
                      type="password"
                      validation="required"
                      onError={setInputErrors}
                      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(e)}
                    />
                  </form>
                </>
              )}
              {loginError && <ErrorMessage>Ops, há um problema com seu usuário ou senha</ErrorMessage>}
            </>
            {loading ? (
              <LoadingWrapper>
                <Lottie animationData={floral} style={{ width: 100 }} />
              </LoadingWrapper>
            ) : (
              <>
                <LoginButton onClick={() => login()}>Logar</LoginButton>
                <FirstLogin target="_blanck" href={process.env.REACT_APP_FIRST_LOGIN_API}>
                  Não tenho senha / Esqueci a senha
                </FirstLogin>
              </>
            )}
          </InputsWrap>
        </LoginBox>
        <IllustrationSVG />
      </ContentWrapper>
      <LogoSeduc src={'https://repositorioweb.educacao.go.gov.br/logos/logo-seduc.png'} alt="logo" />
    </LoginContainer>
  )
}

export default LoginPage
