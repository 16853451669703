import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import api from '../../../services/api'
import SideMenuItem from '../../navigation/SideMenuItem'
import { useRecoilState, useRecoilValue } from 'recoil'
import SubjectAtom from '../../../store/SubjectsAtom'
import { BiMenu } from 'react-icons/bi'
import UserState from '../../../store/UserAtom'
import { getQueryParams } from '../../../helpers'

type RouteParams = {
  comp: string
  serie: string
  turma: string
}

type SideMenuProps = {
  handlerMenu: React.Dispatch<React.SetStateAction<boolean>>
  page: any
}

const SideMenu = ({ handlerMenu, page }: SideMenuProps) => {
  const history = useHistory()
  const location = useLocation()
  const { comp, serie } = useParams<RouteParams>()
  const [subjects, setSubjects] = useState([])
  const [activeSubject, setActiveSubject] = useRecoilState(SubjectAtom)
  const user = useRecoilValue(UserState)
  const { turma } = getQueryParams(location.search)

  useEffect(() => {
    async function getSubjects() {
      try {
        let subjects
        const resp = await api.get(`/cat_assuntos_${comp}?_fields=name,id&orderby=slug&order=asc&per_page=50`)

        subjects = resp.data

        // Oculta os conteúdos para Aluno
        if (user?.tipoUsuario !== 2) {
          subjects = subjects.filter((item: any) => item.name !== 'Conversando com o professor')

          const menuFiltered = subjects.filter((item: any) => item.name !== 'Material de apoio para o professor')
          setSubjects(menuFiltered)
          return
        }

        if (comp === 'ensino_medio' && turma === '3ª Série') {
          const menuFiltered = subjects.filter((item: any) => {
            switch (item.name) {
              case 'PROJETO DE VIDA':
              case 'ARTE':
              case 'SOCIOLOGIA':
                return false
              default:
                return true
            }
          })
          setSubjects(menuFiltered)
          return
        }

        if (comp === 'ensino_medio' && turma !== '3ª Série') {
          const menuFiltered = subjects.filter((item: any) => item.name !== 'ROTEIRO DE ESTUDO')
          setSubjects(menuFiltered)
          return
        }

        if (comp === 'goias_tec' && turma === '1ª Série') {
          const menuFiltered = subjects.filter((item: any) => item.name !== 'Língua Espanhola')
          setSubjects(menuFiltered)
          return
        }

        if (comp === 'goias_tec' && turma === '2ª Série') {
          const menuFiltered = subjects.filter((item: any) => item.name !== 'Arte')
          setSubjects(menuFiltered)
          return
        }

        setSubjects(resp.data)
      } catch (error) {
        console.log(error)
      }
    }
    getSubjects()
  }, [comp, user])

  async function changeSubject(id: number, e: any) {
    e.preventDefault()
    await setActiveSubject(id)
    handlerMenu(true)
    history.push(`/conteudos/${comp}/${serie}/?title=${page?.title}&turma=${page?.turma}`)
  }

  return (
    <SideMenuWrapper>
      <SideMenuButton onClick={() => handlerMenu((state) => !state)}>
        <BiMenu />
      </SideMenuButton>

      {subjects.map((item: any) => (
        <SideMenuItem
          key={item.id}
          label={item.name}
          onClick={(e: any) => changeSubject(item.id, e)}
          active={activeSubject === item.id}
        />
      ))}
    </SideMenuWrapper>
  )
}

const SideMenuWrapper = styled.nav`
  border-right: 1px solid #e2e2e2;
  max-width: 300px;
  min-width: 300px;
  max-height: 80vh;
  overflow-y: auto;
  transition: all 350ms ease-in-out;
  z-index: 999;

  @media (max-width: 768px) {
    height: 100vh;
    background: #fff;
    padding: 15px 5px;
    outline-width: 300px;
    margin-left: -15px;
  }
`

const SideMenuButton = styled.button`
  display: none;
  text-align: center;
  cursor: pointer;
  width: 39px;
  height: 35px;
  position: relative;
  bottom: 15px;
  background: ${({ theme }) => theme.primary};
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 3px #ddd;
  color: #fff;
  font-size: 1.8rem;

  :hover {
    opacity: 0.9;
  }

  @media (max-width: 980px) {
    display: grid;
    place-items: center;
  }
`

export default SideMenu
