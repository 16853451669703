import { useState } from 'react'
import { useRecoilState } from 'recoil'
import UserState from '../../../store/UserAtom'
import Button from '../../forms/Button'
import { setLocalStorageItem } from '../../../helpers'
import LoadingIndicator from '../../feedback/LoadingIndicator/LoadingIndicator'
import { FormRadio, ModalCard, ModalOverlay } from './style'

type ModalProps = {
  show: boolean
}

const ModalBolsaEstudo = ({ show }: ModalProps) => {
  const [user, setUser] = useRecoilState(UserState)
  const [loading, setLoading] = useState<boolean | string>(false)
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  const informData = async (data: number | null) => {
    setLoading(true)
    try {
      //   await api.post(`${process.env.REACT_APP_LOGIN_API}/user/SalvarNotasAlunoEnem`, {
      //     matricula: user.matricula,
      //     ano: new Date().getFullYear(),
      //     segundaFase: data,
      //   });

      setLocalStorageItem('netescola_interactedWithModalAlelo', true)
      setLocalStorageItem('netescola', { ...user, cartao: data })
      setUser((user: any) => ({ ...user, cartao: data }))
      setLoading('done')

      setRemoveModal(true)
      document.location.reload()
    } catch (e) {
      window.alert('Ocorreu um problema ao salvar')
      setRemoveModal(true)
    }
  }

  return (
    <ModalOverlay show={show} removeModal={removeModal}>
      <ModalCard>
        <h3>Parabéns, {user?.matricula && user?.nome.substring(0, user.nome.indexOf(' ')).toUpperCase()}</h3>
        <p>
          Você atingiu os critérios para o recebimento da Bolsa Estudo. Informamos que o valor já está disponível para
          uso no cartão.{' '}
        </p>
        <p>
          Mantenha a sua frequência e uma boa média escolar e garanta o repasse do benefício nos próximos meses. Se
          possível, deixe a sua opinião sobre o Bolsa Estudo aqui.
        </p>

        <FormRadio>
          <h4>1 – Você está satisfeito(a) com o seu cartão Bolsa Estudo?</h4>
          <div className="radio">
            <div className="radio-item">
              <input type="radio" id="sim" name="satisfacao"></input>
              <label htmlFor="">Sim</label>
            </div>
            <input type="radio" id="nao" name="satisfacao"></input>
            <label htmlFor="">Não</label>
            <input type="radio" id="nao-recebi" name="satisfacao"></input>
            <label htmlFor="">Não recebi</label>
          </div>

          <h4>2 – Em que área o cartão te ajuda?</h4>
          <div className="radio">
            <input type="radio" id="alimentacao" name="area"></input>
            <label htmlFor="">Alimentação</label>
            <input type="radio" id="livros" name="area"></input>
            <label htmlFor="">Livros</label>
            <input type="radio" id="roupas" name="area"></input>
            <label htmlFor="">Roupas</label>
            <input type="radio" id="lazer" name="area"></input>
            <label htmlFor="">Lazer</label>
          </div>

          <h4>3 - Você já teve algum problema ao realizar compras com o cartão?</h4>
          <div className="radio">
            <input type="radio" id="sim" name="problema"></input>
            <label htmlFor="">Sim</label>
            <input type="radio" id="nao" name="problema"></input>
            <label htmlFor="">Não</label>
            <input type="radio" id="nao-recebi" name="problema"></input>
            <label>Não recebi</label>
          </div>
        </FormRadio>

        <div>
          {!loading ? (
            <>
              <Button
                label="Enviar"
                bgcolor="#0D5E16"
                onClick={() => informData(1)}
                style={{ marginRight: 150, marginLeft: 150 }}
              />
            </>
          ) : (
            <LoadingIndicator loading={loading} />
          )}
        </div>
      </ModalCard>
    </ModalOverlay>
  )
}

export default ModalBolsaEstudo
