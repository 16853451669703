import { ResponseFormularioEnem } from '../domain/entities/EnemForm'
import { ResponseDatasEnem } from '../domain/entities/EnemForm'

export function validarEnvioDeNotas(formularioEnem: ResponseFormularioEnem) {
  if (
    isNaN(parseInt(formularioEnem.notaRedacao)) ||
    isNaN(parseInt(formularioEnem.notaChst)) ||
    isNaN(parseInt(formularioEnem.notaCnst)) ||
    isNaN(parseInt(formularioEnem.notaLcst)) ||
    isNaN(parseInt(formularioEnem.notaMst))
  ) {
    return false
  }

  if (
    parseInt(formularioEnem.notaRedacao) < 100 ||
    parseInt(formularioEnem.notaRedacao) > 1000 ||
    parseInt(formularioEnem.notaChst) < 100 ||
    parseInt(formularioEnem.notaChst) > 1000 ||
    parseInt(formularioEnem.notaCnst) < 100 ||
    parseInt(formularioEnem.notaCnst) > 1000 ||
    parseInt(formularioEnem.notaLcst) < 100 ||
    parseInt(formularioEnem.notaLcst) > 1000 ||
    parseInt(formularioEnem.notaMst) < 100 ||
    parseInt(formularioEnem.notaMst) > 1000
  ) {
    return false
  }

  if (
    formularioEnem.notaRedacao == null ||
    formularioEnem.notaChst == null ||
    formularioEnem.notaCnst == null ||
    formularioEnem.notaLcst == null ||
    formularioEnem.notaMst == null
  ) {
    return false
  }
  return true
}

export function validarEnvioParticipacao(
  formularioEnem: ResponseFormularioEnem,
  primeiraFaseAtiva: Boolean,
  segundaFaseAtiva: Boolean,
) {
  if (formularioEnem.qtdEnviosParticipacao >= 2) {
    return false
  } else if (
    primeiraFaseAtiva &&
    !formularioEnem.primeiraFase &&
    (formularioEnem.codigoMotivoPrimeiraFase == 0 || formularioEnem.codigoMotivoPrimeiraFase == null)
  ) {
    return false
  } else if (
    segundaFaseAtiva &&
    !formularioEnem.segundaFase &&
    (formularioEnem.codigoMotivoSegundaFase == 0 || formularioEnem.codigoMotivoSegundaFase == null)
  ) {
    return false
  }
  if (primeiraFaseAtiva && formularioEnem.primeiraFase == null) {
    return false
  }
  if (segundaFaseAtiva && formularioEnem.segundaFase == null) {
    return false
  }
  return true
}

export function handleChangeNota(event: string, idCampo: string) {
  var sanitizedValue = event
    .replace(/^0+(?=\d)/, '') // Remove leading zeros
    .replace(/\./g, ',') // Replace dots with commas
    .replace(/[^\d,]/g, '') // Remove non-numeric and non-comma characters
    .replace(/,(?=.*\,)/g, '') // Remove extra commas
    .slice(0, 5)
  console.log(idCampo)
  var notaMaximaPermitida = 999.9

  if (idCampo == 'notaRedacao') {
    notaMaximaPermitida = 1000
  }

  // Check if the value is within the desired range (0 to 999.9)
  if (sanitizedValue === '' || (parseFloat(sanitizedValue) >= 0 && parseFloat(sanitizedValue) <= notaMaximaPermitida)) {
    return sanitizedValue
  }
  return ''
}
