import styled from 'styled-components/macro'

export const SignOutButton = styled.span`
cursor: pointer;
`

export const UserInfoWrapp = styled.div`
padding: 5px;
display: flex;
flex-direction: column;
justify-content: space-around;
max-width: 300px;

@media (max-width: 980px) {
  display: none;
}
`

export const InfoText = styled.p`
text-transform: capitalize;
font-size: 0.8rem;
color: ${({ theme }) => theme.primary};

span {
  margin-left: 3px;
}
`
