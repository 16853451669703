import { createGlobalStyle } from 'styled-components'
import bwmitgaeot from '../fonts/BwMitga-Regular.otf'
import bwmitgaeotBold from '../fonts/BwMitga-Bold.otf'
import bwmitgaeotBlack from '../fonts/BwMitga-Black.otf'

export default createGlobalStyle`
  @font-face {
    font-family: 'BwMitga';
    src: local('BwMitga-Regular'), url(${bwmitgaeot});
    font-weight: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'BwMitga';
    src: local('BwMitga-Bold'), url(${bwmitgaeotBold});
    font-weight: bold;
    font-display: swap;
  }

  @font-face {
    font-family: 'BwMitga';
    src: local('BwMitga-Black'), url(${bwmitgaeotBlack});
    font-weight: 900;
    font-display: swap;
  }

  html, body {
    height: 100%;
    min-height:100%;
  }

  #root {
    height: 100%;
  }

  * {
    margin: 0;
    padding:0;
    box-sizing:border-box;
    font-family: 'BwMitga', -apple-system, BlinkMacSystemFont, “Segoe UI”,
    “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”,
    “Helvetica Neue”, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

    /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  a {
    text-decoration: none;
  }

  table, tr, td, th {
    border: 1px solid #303030;
    border-collapse: collapse;
  }

  .wp-block-image figcaption {
    font-size: 0.8rem;
  }
  
  @keyframes floating {
    0% { transform: translate(0); }
    25% { transform: translate(5px, -5px); }
    50% { transform: translate(-10px, -10px); }
    75% {transform: translate(12px, -12px); }
    100% {transform: translate(10, 10); }
  }

  #lapis-azul {
    animation: floating 13s ease;
    animation-iteration-count: infinite;
  }
  #lapis {
    animation: floating 13s ease 1s infinite;
  }
  #regua {
    animation: floating 13s ease 2s infinite;
  }
  #livro-grande {
    animation: floating 13s ease 3s infinite;
  }
  #livro-pequeno {
    animation: floating 13s ease 4s infinite;
  }
  #transferidor {
    animation: floating 13s ease 5s infinite;
  }
  #globo {
    animation: floating 13s ease 6s infinite;
  }

  .aligncenter {text-align: center}
  .alignleft {text-align: left}


  @keyframes fadein {
    from {opacity: 0;}
    to {opacity: 1;}
  }
`
