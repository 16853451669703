function ApoioProf () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
      <path
        d="M193,143.29H885.5a28.38,28.38,0,0,1,28.38,28.38v22.45"
        fill="none"
        stroke="#1a1a1a"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="17.18"
      />
      <path
        d="M864.76,90.42V64.07A39.36,39.36,0,0,0,825.4,24.72H203.64c-60.13,0-108.88,56.39-108.88,126V879A176.24,176.24,0,0,0,271,1055.28H931.17a54.42,54.42,0,0,0,54.42-54.42V327.66a57.9,57.9,0,0,0-57.9-57.9H753.78a5.36,5.36,0,0,0-5.37,5.36V577a5.36,5.36,0,0,1-9.32,3.62L706.3,544.88a5.36,5.36,0,0,0-8.32.51L669.2,585.67a5.36,5.36,0,0,1-9.73-3.12V275.12a5.36,5.36,0,0,0-5.37-5.36H197.7c-114.83,0-102.94-119.09-102.94-119.09"
        fill="none"
        stroke="#1a1a1a"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="17.18"
      />
    </svg>
  )
}

export default ApoioProf
