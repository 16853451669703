function Artes () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
      <path
        d="M317.77,326C579,514.53,818,738.5,1038,992.81c28.54,29.79-11.57,75-43.73,49.32C711.53,849.75,461,637.51,250.53,400.54Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="15.99"
      />
      <path
        d="M299.44,302.57S273.23,160.38,162.64,159.4C70.09,158.58,54.15,63,51.42,31.88a1.79,1.79,0,0,0-3.5-.36C26.26,83.73-8.89,286.13,227,383.84Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="15.99"
      />
    </svg>
  )
}

export default Artes
