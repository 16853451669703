import styled from 'styled-components/macro'

type SectionTitleProps = {
  textcolor?: string;
  uppercase?: boolean;
  size?: number;
};

const Title = styled.h2<SectionTitleProps>`
  text-align: center;
  font-size: ${({ size }) => (size || '58')}px;
  font-family: "BwMitga", -apple-system, “Segoe UI”, “Roboto”, “Helvetica Neue”,
    sans-serif;
  font-weight: 900;
  color: ${({ color, theme }) => color || theme.primary};
  margin: auto;

  @media (max-width: 986px) {
    font-size: 2rem;
    max-width: 700px;
  }
`

export default Title
