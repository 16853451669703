import styled from 'styled-components/macro'

type CardProps = {
  bgcolor?: string;
};

export const CardWrapper = styled.div<CardProps>`
display: flex;
flex-direction: column;
align-items: center;
min-width: 300px;
max-width: 340px;
box-shadow: 1px 1px 6px #ddd;

`

export const Illustration = styled.img`
max-width: 100%;
position: relative;
`

export const CardContent = styled.div<CardProps>`
width: 100%;
padding: 1rem;
color: ${({ bgcolor }) => bgcolor};

a {
  display: inline-block;
  color: ${({ bgcolor }) => bgcolor};
  width: 50%;
  margin-bottom: 0.6rem;
  padding: 1px;

  &:hover {
    text-decoration: underline;
  }
}
`

export const CardTitle = styled.h3`
width: 100%;
font-size: 1rem;
border-bottom: 1px solid #fff;
margin-bottom: 7px;
`
