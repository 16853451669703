import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

type LinkButtonProps = {
  bgcolor: string
  href?: string
  to?: string
  text?: string
  textcolor?: string
  target?: string
}

function LinkButton({ bgcolor, href, to, text, textcolor }: LinkButtonProps) {
  if (to) {
    return (
      <ButtonLink to={to} textcolor={textcolor} bgcolor={bgcolor} className="linkButton">
        {text}
      </ButtonLink>
    )
  }

  return (
    <ButtonAnchor target="_blank" href={href} textcolor={textcolor} bgcolor={bgcolor} className="linkButton">
      {text}
    </ButtonAnchor>
  )
}

const ButtonLink = styled(Link)<LinkButtonProps>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
  min-height: 45px;
  cursor: pointer;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ textcolor }) => textcolor};
  transition: all 150ms;
  box-shadow: 0 1px 5px -1px #737373;

  &:hover {
    opacity: 1;
    box-shadow: unset;
  }

  @media (max-width: 768px) {
    margin: 0 0 10px 0;
  }
`

const ButtonAnchor = styled.a<LinkButtonProps>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
  min-height: 45px;
  cursor: pointer;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ textcolor }) => textcolor};
  transition: all 200ms;
  box-shadow: 0 1px 5px -1px #737373;

  &:hover {
    opacity: 1;
    box-shadow: unset;
  }

  @media (max-width: 768px) {
    margin: 0 0 10px 0;
  }
`

export default LinkButton
