import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const MainHeader = styled.header`
  width: 100%;
  box-shadow: 0 3px 5px #ddd;
  min-height: 110px;
  background-color: rgba(255, 255, 255, 0.9);
  overflow: hidden;

  a {
    display: flex;
    text-decoration: none;
  }
`

export const BrandWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: none;
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 0 15px;
    span {
      display: inline;
    }
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  margin: auto;
  padding: 0 15px;
  min-height: 70px;
  align-items: stretch;
  max-width: 1366px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 -15px;
  }
`

export const Wrapper = styled.div`
  flex: 1;
  border-width: 0 1px;
  border-color: #eee;
  border-style: solid;

  @media (max-width: 980px) {
    border-width: 1px 0 0 0;
  }
`

export const LogoNetEscola = styled.h1`
  align-self: center;
  font-family: "BwMitga", -apple-system, BlinkMacSystemFont, “Segoe UI”,
    “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”,
    “Helvetica Neue”, sans-serif;
  font-weight: 900;
  font-size: 2.5rem;
  color: ${({ theme }) => theme.primary};
  margin-right: 10px;
  letter-spacing: 1px;

  @media (max-width: 768px) {
    flex: 1;
    text-align: center;
    margin: 15px 0;
  }
`

export const BottomMenu = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding: 10px;
`

export const MenuItem = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.primary};
  margin: 0 25px;
  font-weight: 500;

  &:hover {
    color: green;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin: 0;
  }

  span {
    margin-right: 10px;
  }
`
