import { useEffect, useState } from 'react'
import Banner from '../../components/Media/Banner'
import Icons from '../../components/Media/Icons'
import Title from '../../components/Typography/Title'
import Subtitle from '../../components/Typography/Subtitle'
import { getQueryParams, htmlDecode } from '../../helpers'
import themes from '../../assets/styles/themes'
import { BsArrowReturnRight } from 'react-icons/bs'
import Header from '../../components/layout/Header/Header'
import {
  ButtonReadMore,
  CategoriesInfo,
  Category,
  ContentWrapper,
  Excerpt,
  ItemTitle,
  SearchItem,
  SearchResultsContainer,
  SubjectName,
  SubjectWrapper,
} from './style'
import { FooterContainer, FooterWrapper, TextFooter } from '../../components/layout/Footer/style'
import { Container } from '../../components/layout/Grid/Grid'

const Search = ({ location, history }: any) => {
  const { searchResults } = location?.state || {}
  const [results, setResults] = useState([])

  useEffect(() => {
    if (searchResults) setResults(searchResults)
  }, [searchResults])

  const { s } = getQueryParams(location.search)

  return (
    <>
      <Header />
      <Banner />
      <Title textcolor={themes.home.primary}>Resultados da Busca</Title>
      <Subtitle>{`Resultados para: "${htmlDecode(s)}"`}</Subtitle>
      <SearchResultsContainer>
        {results.map((result: any) => {
          const serieId = result[`cat_serie_${result.type}`][0] || null
          const { id, title, type, excerpt, serie, slug, componente, etapa } = result
          return (
            <SearchItem key={id}>
              <SubjectWrapper>
                <Icons name={componente} size={80} color="#303030" weight={15} />
                <SubjectName>{componente}</SubjectName>
              </SubjectWrapper>
              <ContentWrapper>
                <ItemTitle>{htmlDecode(title.rendered)}</ItemTitle>
                <Excerpt dangerouslySetInnerHTML={{ __html: excerpt.rendered }} />
                <CategoriesInfo>
                  <div>
                    Etapa:
                    <Category colorName={type}>{etapa}</Category>
                  </div>
                  <div>
                    Serie:
                    <Category colorName={type}>{serie}</Category>
                  </div>
                  <ButtonReadMore onClick={() => history.push(`/conteudos/${type}/${serieId}/${slug}`)}>
                    Ver aula
                    <BsArrowReturnRight />
                  </ButtonReadMore>
                </CategoriesInfo>
              </ContentWrapper>
            </SearchItem>
          )
        })}
      </SearchResultsContainer>
      <FooterContainer>
        <Container>
          <FooterWrapper>
            <TextFooter>
              O Portal NetEscola é um portal direcionado a professores e estudantes da rede pública de educação, que
              disponibiliza conteúdos para pesquisas escolares, atividades educativas e outros conteúdos que auxiliarão
              nos estudos.
            </TextFooter>
            <img
              className="logo-footer"
              src="https://repositorioweb.educacao.go.gov.br/logos/logo-seduc-branca.svg"
              alt="logo footer"
              width="190"
            />
          </FooterWrapper>
        </Container>
      </FooterContainer>
    </>
  )
}

export default Search
