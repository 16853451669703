import SearchInput from '../../forms/SearchInput/SearchInput'
import UserInfo from '../../DataDisplay/UserInfo/UserInfo'
import { Link } from 'react-router-dom'
import MenuMobile from '../MenuMobile/MenuMobile'
import { useRecoilValue } from 'recoil'
import UserState from '../../../store/UserAtom'
import { HeaderWrapper, LogoNetEscola, MainHeader, BrandWrapper, Wrapper, MenuItem, BottomMenu } from './style'
import { useMemo } from 'react'

function Header() {
  const user = useRecoilValue(UserState)

  const codigosSeriesAnoTerminarios: string[] = [
    '1413',
    '1533',
    '1543',
    '1603',
    '4433',
    '4434',
    '5213',
    '5303',
    '5313',
    '5344',
    '5353',
    '5363',
    '5393',
    '5463',
    '5513',
    '5533',
    '5573',
    '5583',
    '5593',
    '5603',
  ]

  const showMenuEnem = useMemo(() => {
    return (
      !!user &&
      user.matricula &&
      (user.codigoNivelEnsino == 3 || user.codigoNivelEnsino == 4) &&
      codigosSeriesAnoTerminarios.includes(user.codigoSerie)
    )
  }, [user])

  return (
    <MainHeader>
      <MenuMobile />
      <HeaderWrapper>
        <BrandWrapper>
          <Link to="/">
            <LogoNetEscola>NetEscola</LogoNetEscola>
          </Link>
        </BrandWrapper>
        <Wrapper>
          <SearchInput />
          <BottomMenu>
            <MenuItem to="/contato">Contato</MenuItem>
            {showMenuEnem && (
              <MenuItem to="/notas-enem">
                <span>DADOS ENEM</span>
              </MenuItem>
            )}
          </BottomMenu>
        </Wrapper>
        <UserInfo />
      </HeaderWrapper>
    </MainHeader>
  )
}

export default Header
