import { useState } from 'react'
import { validateFields } from '../../../helpers/formHelpers'
import { TextInputWrapper, Input, WarningIcon } from './style'

function InputText({ name, label = false, getValue, onError = () => {}, validation, disabled = false, ...props }: any) {
  const [error, setErrors] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState('')
  const [initialValue, setInitialValue] = useState(true)

  function handleInputValue(value: any) {
    if (initialValue) {
      getValue((current: any) => ({ ...current, [name]: value }))
      setInitialValue(false)
    }

    if (error) {
      setErrors(false)
      onError((current: any) => ({ ...current, [name]: false }))
    }

    setInputValue(value)
    getValue((current: any) => ({ ...current, [name]: value }))
  }

  function handleError() {
    if (error) return

    const hasError = validateFields(validation, inputValue)
    setErrors(hasError)
    onError((current: any) => ({ ...current, [name]: hasError }))
  }

  return (
    <TextInputWrapper htmlFor={name}>
      {label && <span>{label}</span>}
      <Input
        disabled={disabled}
        error={error}
        value={inputValue}
        onChange={(event) => handleInputValue(event.target.value)}
        {...props}
        onBlur={() => handleError()}
      />
      {error && <WarningIcon error={error} />}
    </TextInputWrapper>
  )
}

export default InputText
