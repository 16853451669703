import { useState } from 'react'
import vamosAprenderLogo from '../../../assets/images/vamos-aprender.png'
import emocionarioLogo from '../../../assets/images/emocionario.png'
import questionarioSeduc from '../../../assets/images/questionario-seduc.png'
import logoFoco from '../../../assets/images/logo-foco.png'
import logoSenac from '../../../assets/images/senac-educacao.png'
import concursoLogo from '../../../assets/images/concurso.png'
import { Row } from '../Grid/Grid'
import {
  FlexWrapper,
  InfoButton,
  InfoButtonHref,
  InfoCardContent,
  InfoCardHeader,
  InfoCardTabs,
  InfoDescription,
  InfoImage,
  InfoTitle,
} from './style'
import { useRecoilValue } from 'recoil'
import UserState from '../../../store/UserAtom'

const tabsContent: { [key: string]: any } = {
  alunoConectividadeChipsTablets: {
    title: 'Formulário Aluno - Projeto Conectividade Chips/Tablets',
    logo: questionarioSeduc,
    description: 'Formulário Aluno - Projeto Conectividade Chips/Tablets',
    links: [
      {
        label: 'Ver Formulário',
        href: 'https://pesquisa-aluno.educacao.go.gov.br/',
      },
    ],
  },
  professorConectividadeChips: {
    title: 'Formulário Professor - Projeto Conectividade Chips',
    logo: questionarioSeduc,
    description: 'Formulário Professor - Projeto Conectividade Chips',
    links: [
      {
        label: 'Ver Formulário',
        href: 'https://pesquisa-professor.educacao.go.gov.br/',
      },
    ],
  },
  formularioSouPaiMaeAluno: {
    title: 'Pesquisa do Programa Sou Pai/Mãe e Sou Aluno',
    logo: questionarioSeduc,
    description: 'Pesquisa do Programa Sou Pai/Mãe e Sou Aluno',
    links: [
      {
        label: 'Ver Formulário',
        href: process.env.REACT_APP_FORMULARIO_SOCIOECONOMICO,
      },
    ],
  },
  pesquisaSatisfacao: {
    title: 'Pesquisa de Satisfação',
    logo: questionarioSeduc,
    description:
      'O objetivo da pesquisa é avaliar o desempenho, durabilidade e satisfação dos usuários em relação aos dispositivos adquiridos, buscando melhorias e é de suma importância a participação de todos os Professores/Gestores Escolares. Para responder o questionário será necessário informar o e-mail institucional (seduc.go.gov.br).',
    links: [
      {
        label: 'Notebook do Professor',
        href: 'https://forms.office.com/r/9D6qxqZgwx',
      },
      {
        label: 'Laboratório móvel',
        href: 'https://forms.office.com/r/C1CxCh1RpY',
      },
    ],
  },
  vamosAprender: {
    title: 'Vamos Aprender',
    logo: vamosAprenderLogo,
    description:
      'O Governo do Estado de Goiás, por meio da Seduc, firmou parceria com o Senac, com o objetivo de implementar o Projeto Senac Educação, o qual visa disponibilizar videoaulas de Língua Portuguesa, Redação e Matemática às turmas de 9º ano e 3ª série.',
    links: [
      {
        label: 'Ver Conteúdos',
        path: '/conteudos/vamos_aprender/00/?title=Vamos Aprender',
      },
    ],
  },
  concursoLiterario: {
    title: 'Concurso Literário',
    logo: concursoLogo,
    description:
      'Projeto interdisciplinar que visa identificar, valorizar e dar visibilidade à produção cultural dos/das estudantes.',
    links: [
      {
        label: 'Saiba Mais',
        href: 'https://cirandadaarte.com.br/concursoliterario',
      },
    ],
  },
  focoAprendizagem: {
    title: 'Foco na Aprendizagem',
    logo: logoFoco,
    description:
      'Conjunto de Atividades Preparatórias de língua portuguesa e matemática para Simulados, a fim de melhorar a proficiência do estudante de 2º, 5º e 9º anos do ensino fundamental.',
    links: [
      {
        label: '2° Ano',
        path: '/conteudos/foco_aprendizagem/170/?turma=2º Ano&title=Foco na Aprendizagem',
      },
      {
        label: '5° Ano',
        path: '/conteudos/foco_aprendizagem/171/?turma=5º Ano&title=Foco na Aprendizagem',
      },
      {
        label: '9° Ano',
        path: '/conteudos/foco_aprendizagem/172/?turma=9º Ano&title=Foco na Aprendizagem',
      },
    ],
  },
  emocionario: {
    title: 'Emocionário',
    logo: emocionarioLogo,
    description:
      'Projeto desenvolvido pela Superintendência de Educação Infantil e Ensino Fundamental em parceria com O SEBRAE/GO com materiais digitais e conteúdos interativos.',
    links: [
      {
        label: 'Acessar conteúdos',
        path: '/conteudos/emocionario/00/?title=Emocionário',
      },
    ],
  },
  senacEducacao: {
    title: 'Senac Educação',
    logo: logoSenac,
    description:
      'O Governo do Estado de Goiás, por meio da Seduc, firmou parceria com o Senac, com o objetivo de implementar o Projeto Senac Educação, o qual visa disponibilizar videoaulas de Língua Portuguesa, Redação e Matemática às turmas de 9º ano e 3ª série.',
    links: [
      {
        label: '9° Ano',
        path: '/conteudos/senac_educacao/245/?turma=9º Ano&title=Senac Educação',
      },
      {
        label: '3ª Série',
        path: '/conteudos/senac_educacao/244/?turma=3ª Série&title=Senac Educação',
      },
    ],
  },
  boletimEscolar: {
    title: 'Boletim Escolar',
    logo: questionarioSeduc,
    description: 'Acesse o Expresso para emitir o Boletim Escolar.',
    links: [
      {
        label: 'Ver Boletim Escolar',
        href: 'https://www.go.gov.br/servicos/servico/solicitar-boletim-escolar-da-rede-estadual-de-educacao',
      },
    ],
  },
  merendaEscolar: {
    title: 'Questionário Merenda Escolar',
    logo: questionarioSeduc,
    description: 'Acesse o questionário para avaliar a alimentação escolar.',
    links: [
      {
        label: 'Ver Questionário de Merenda',
        href: 'https://questionariomerenda.educacao.go.gov.br/QuestionarioMerenda/Inicio.aspx',
      },
    ],
  },
  acessarSimulado: {
    title: 'Acessar Simulado',
    logo: questionarioSeduc,
    description: 'Clique em "Acessar Simulado" para acessá-lo.',
    links: [
      {
        label: 'Acessar Simulado',
        href: 'https://avaliacao.educacao.go.gov.br',
      },
    ],
  },
}

export const TabsSection = () => {
  const [activetab, setActiveTab] = useState<{ [key: string]: any }>(tabsContent.alunoConectividadeChipsTablets)

  const user = useRecoilValue(UserState)
  const linkMerenda = `https://questionariomerenda.educacao.go.gov.br/QuestionarioMerenda/Inicio.aspx?matricula=${user?.matricula}&escola=${user?.codigoEscola}&ano=${user?.anoLetivo}`

  function showLinkChromebook() {
    const seriesPermitidas = ['9º ano', '3º Ano', '3ª Série', '1ª Série - Goiás Tec', '3ª Série - Goiás Tec']
    return seriesPermitidas.includes(user?.serie)
  }

  return (
    <FlexWrapper>
      <InfoCardTabs>
        <InfoCardHeader
          active={activetab.title === 'Formulário aluno- Projeto Conectividade Chips/tablets'}
          onClick={() => setActiveTab(tabsContent.alunoConectividadeChipsTablets)}
        >
          <InfoImage src={questionarioSeduc} />
          <InfoTitle>Formulário aluno- Projeto Conectividade Chips/tablets</InfoTitle>
        </InfoCardHeader>

        <InfoCardHeader
          active={activetab.title === 'Formulário Professor - Projeto Conectividade Chips'}
          onClick={() => setActiveTab(tabsContent.professorConectividadeChips)}
        >
          <InfoImage src={questionarioSeduc} />
          <InfoTitle>Formulário Professor - Projeto Conectividade Chips</InfoTitle>
        </InfoCardHeader>

        <InfoCardHeader
          active={activetab.title === 'Pesquisa do Programa Sou Pai/Mãe e Sou Aluno'}
          onClick={() => setActiveTab(tabsContent.formularioSouPaiMaeAluno)}
        >
          <InfoImage src={questionarioSeduc} />
          <InfoTitle>Pesquisa do Programa Sou Pai/Mãe e Sou Aluno</InfoTitle>
        </InfoCardHeader>

        <InfoCardHeader
          active={activetab.title === 'Vamos Aprender'}
          onClick={() => setActiveTab(tabsContent.vamosAprender)}
        >
          <InfoImage src={vamosAprenderLogo} />
          <InfoTitle>Vamos Aprender</InfoTitle>
        </InfoCardHeader>
        <InfoCardHeader
          active={activetab.title === 'Concurso Literário'}
          onClick={() => setActiveTab(tabsContent.concursoLiterario)}
        >
          <InfoImage src={concursoLogo} />
          <InfoTitle>Concurso Literário</InfoTitle>
        </InfoCardHeader>
        <InfoCardHeader
          active={activetab.title === 'Emocionário'}
          onClick={() => setActiveTab(tabsContent.emocionario)}
        >
          <InfoImage src={emocionarioLogo} />
          <InfoTitle>Emocionário</InfoTitle>
        </InfoCardHeader>
        <InfoCardHeader
          active={activetab.title === 'Foco na Aprendizagem'}
          onClick={() => setActiveTab(tabsContent.focoAprendizagem)}
        >
          <InfoImage src={logoFoco} />
          <InfoTitle>Foco na aprendizagem</InfoTitle>
        </InfoCardHeader>
        <InfoCardHeader
          active={activetab.title === 'Senac Educação'}
          onClick={() => setActiveTab(tabsContent.senacEducacao)}
        >
          <InfoImage src={logoSenac} />
          <InfoTitle>Senac Educação</InfoTitle>
        </InfoCardHeader>
        <InfoCardHeader
          active={activetab.title === 'Boletim Escolar'}
          onClick={() => setActiveTab(tabsContent.boletimEscolar)}
        >
          <InfoImage src={questionarioSeduc} />
          <InfoTitle>Boletim Escolar</InfoTitle>
        </InfoCardHeader>
        <InfoCardHeader
          active={activetab.title === 'Questionário Merenda Escolar'}
          onClick={() => setActiveTab(tabsContent.merendaEscolar)}
        >
          <InfoImage src={questionarioSeduc} />
          <InfoTitle>Merenda Escolar</InfoTitle>
        </InfoCardHeader>
        <InfoCardHeader
          active={activetab.title === 'II Simulado - Revisa Goiás'}
          onClick={() => setActiveTab(tabsContent.acessarSimulado)}
        >
          <InfoImage src={questionarioSeduc} />
          <InfoTitle>II Simulado - Revisa Goiás</InfoTitle>
        </InfoCardHeader>
      </InfoCardTabs>

      <InfoCardContent>
        <InfoImage src={activetab.logo} />
        <InfoDescription>{activetab.description}</InfoDescription>
        <Row justify="space-around">
          {activetab.links &&
            activetab.links.map((link: { label: string; path: string; href: string }) =>
              link.href ? (
                <InfoButtonHref
                  key={link.path}
                  href={activetab.title === 'Questionário Merenda Escolar' ? linkMerenda : link.href}
                >
                  {link.label}
                </InfoButtonHref>
              ) : (
                <InfoButton key={link.path} to={link.path}>
                  {link.label}
                </InfoButton>
              ),
            )}
        </Row>
      </InfoCardContent>
    </FlexWrapper>
  )
}
