function Geografia () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
      <circle
        cx="506.43"
        cy="410.62"
        r="386.71"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="17.69"
      />
      <line
        x1="508.25"
        y1="864.48"
        x2="508.25"
        y2="1056.09"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="17.69"
      />
      <line
        x1="311.57"
        y1="1056.09"
        x2="704.93"
        y2="1056.09"
        fill="none"
        stroke="#1a1a1a"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="17.69"
      />
      <path
        d="M247.24,783.1a451.63,451.63,0,0,0,259.19,81.38c250.65,0,453.85-203.2,453.85-453.86,0-161.08-84-302.45-210.48-383"
        fill="none"
        stroke="#1a1a1a"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="27"
      />
    </svg>
  )
}

export default Geografia
