import styled from 'styled-components/macro'
import React from 'react'

type ButtonProps = {
  icon?: React.ReactElement
  onClick?: () => void
  iconSize?: number
  iconColor?: string
  title?: string
  testId?: string
}

const IconButton = ({ icon, onClick, iconSize, iconColor, title = '', testId = '' }: ButtonProps) => {
  return (
    <ActionButton data-testid={testId} title={title} iconColor={iconColor} iconSize={iconSize || 16} onClick={onClick}>
      {icon}
    </ActionButton>
  )
}

const ActionButton = styled.span<ButtonProps>`
  cursor: pointer;
  font-size: ${(props) => props.iconSize}px;
  line-height: 0;
  color: ${(props) => props.iconColor};
  :active {
    opacity: 0.9;
  }
`

export default IconButton
