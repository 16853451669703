import { useRecoilState } from 'recoil'
import UserState from '../../../store/UserAtom'
import { useHistory } from 'react-router-dom'
import { FaUserGraduate, FaRegBuilding, FaRegIdCard, FaSignOutAlt } from 'react-icons/fa'
import { removeLocalStorageItem } from '../../../helpers'
import { UserInfoWrapp, InfoText, SignOutButton } from './style'

const UserInfo = () => {
  const [userdata, setUserData] = useRecoilState(UserState)
  const history = useHistory()

  async function signOut() {
    await removeLocalStorageItem('netescola')
    await setUserData(null)
    history.push('/login')
  }

  if (userdata?.matricula) {
    return (
      <UserInfoWrapp>
        <InfoText>
          <FaUserGraduate />
          <span>{userdata?.nome}</span>
        </InfoText>
        <InfoText>
          <FaRegIdCard />
          <span>
            {userdata?.serie} - Turma {userdata.turma}
          </span>
        </InfoText>
        <InfoText>
          <FaRegBuilding />
          <span>{userdata?.escola}</span>
        </InfoText>
        <InfoText>
          <FaSignOutAlt />
          <SignOutButton role="button" onClick={signOut}>
            sair
          </SignOutButton>
        </InfoText>
      </UserInfoWrapp>
    )
  }

  return (
    <UserInfoWrapp>
      <InfoText>
        <FaUserGraduate />
        <span>{userdata?.displayName || userdata?.firstName || 'Professor'}</span>
      </InfoText>
      <InfoText>
        <FaRegIdCard />
        <span>{userdata?.mailAddress}</span>
      </InfoText>
      <InfoText>
        <FaRegBuilding />
        <span>{userdata?.city || 'SEDUC/GOIÁS'}</span>
      </InfoText>
      <InfoText>
        <FaSignOutAlt />
        <SignOutButton role="button" onClick={signOut}>
          sair
        </SignOutButton>
      </InfoText>
    </UserInfoWrapp>
  )
}

export default UserInfo
