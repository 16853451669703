import Icons from '../Media/Icons'
import styled from 'styled-components/macro'

type SideMenuIteProps = {
  label: string,
  onClick: (e) => Promise<void>,
  active: boolean,
}

function SideMenuItem({ label, onClick, active }: SideMenuIteProps) {
  return (
    <ItemWrapper onClick={onClick} active={active}>
      <Icons name={label} size={25} />
      <ItemLabel>{label}</ItemLabel>
    </ItemWrapper>
  )
}

const ItemWrapper = styled.a`
  display: flex;
  align-items: center;
  margin: 0 0 15px;
  padding: 7px 5px;
  cursor: pointer;
  transition: all ease 300ms;
  background-color: ${({ active }) => (active ? '#efefef' : 'none')};

  &:hover {
    background-color: #efefef;
    i {
      transform: rotateY(360deg);
    }
  }
`

const ItemLabel = styled.p`
  color: ${({ theme }) => theme.primary};
  display: inline-block;
  text-decoration: none;
  font-size: 1rem;
  font-family: 'BwMitga', -apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”,
    “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif;
  transition: all 500ms;
  margin-left: 10px;

  &:hover {
    color: ${({ theme }) => theme.secondary};

    i {
      transform: rotateY(360deg);
    }
  }

  @media (max-width: 768px) {
    color: ${({ theme }) => theme.primary};
  }
`

export default SideMenuItem
