import styled from 'styled-components/macro'

export const LoginContainer = styled.div`
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-between;
`

export const ContentWrapper = styled.div`
height: 100vh;
max-width: 1600px;
display: flex;
justify-content: space-between;
align-items: center;

@media (max-width: 980px) {
  height: auto;
  align-items: flex-start;
}

#seduc-illustration {
  position: absolute;
  max-width: 800px;
  max-height: 100%;
  right: 0;
  top: 0;
  z-index: -10;

  @media (min-width: 1600px) {
    max-width: 920px;
  }
}
`

export const Logo = styled.h1`
font-weight: 900;
font-size: 4rem;
color: ${({ theme }) => theme.primary};
margin-right: 10px;
letter-spacing: 1px;
@media (max-width: 980px) {
  font-size: 3.5rem;
}
`

export const Description = styled.h4`
margin-top: 40px;
font-size: 1rem;
font-weight: normal;
line-height: 28px;
color: ${({ theme }) => theme.primary};
@media (max-width: 980px) {
  margin-top: 20px;
}
`

export const FormLabel = styled.p`
margin-top: 30px;
font-size: 2rem;
font-weight: bold;
color: ${({ theme }) => theme.primary};
text-align: center;
`

export const ErrorMessage = styled.p`
color: #f55b5b;
margin: 5px 0 0;
`

export const LoginBox = styled.div`
max-width: 550px;
min-height: 300px;
padding: 53px;
display: flex;
flex-direction: column;

@media (max-width: 1200px) {
  background-color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 768px) {
  padding: 15px;
  align-items: center;
  max-width: unset;
}
`

export const InputsWrap = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
margin-top: 30px;
@media (max-width: 768px) {
  margin-top: 15px;
  width: 95%;
}
`

export const LoginButton = styled.button`
background: none;
font-weight: bold;
font-size: 18px;
border: none;
cursor: pointer;
background: #51a34f;
color: white;
width: 180px;
height: 40px;
border-radius: 25px;
margin: 20px auto 0;
@media (max-width: 768px) {
  margin: 0 auto 0;
}
`

export const LoadingWrapper = styled.div`
height: 115px;
text-align: center;
`

export const FirstLogin = styled.a<any>`
display: block;
text-align: center;
color: ${({ theme }) => theme.primary};
margin-top: 25px;
transition: 300ms opacity ease;
opacity: ${({ show }) => (show ? 0 : 1)};
pointer-events: ${({ show }) => (show ? 'none' : 'initial')};

@media (max-width: 768px) {
  margin-top: 10px;
}
`

export const LogoSeduc = styled.img`
max-width: 300px;
position: absolute;
left: 50%;
bottom: 15px;
transform: translateX(-50%);

@media (max-width: 768px) {
  position: relative;
  margin-top: 15px;
  max-width: 135px;
}
`
