import styled from 'styled-components/macro'

type ButtonProps = {
  textcolor?: string
  bgcolor: string
  label: string
  disabled?: boolean
  width?: number
  opacity?: number
}

const Button = ({ disabled, label, bgcolor = '#51a34f', textcolor = '#fff', onClick, ...props }: any): any => {
  return (
    <StyledButton disabled={disabled} bgcolor={bgcolor} textcolor={textcolor} onClick={onClick} {...props}>
      {label}
    </StyledButton>
  )
}

const StyledButton = styled.button<ButtonProps>`
  width: ${({ width }) => (width ? width : '100%')};
  padding: 14px 15px;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
  max-height: 60px;
  min-height: 45px;
  cursor: pointer;
  background-color: ${({ bgcolor, disabled }) => (disabled ? '#c1c1c1' : bgcolor)};
  color: ${({ textcolor, disabled }) => (disabled ? '#a6a6a6' : textcolor)};
  transition: all 100ms;
  box-shadow: 0 1px 5px -1px #737373;
  opacity: ${({ opacity }) => (opacity ? '0.9px' : opacity)};
  border: none;

  &:active {
    opacity: 1;
    box-shadow: unset;
  }

  @media (max-width: 768px) {
    margin: 0 0 10px;
  }
`

export default Button
