import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'

import bannerLimeAluno from '../../assets/images/banner-lime-aluno.jpg'
import bannerLimeGestor from '../../assets/images/banner-lime-gestor.jpg'
import bannerLimeProfessor from '../../assets/images/banner-lime-professor.jpg'
import bannerInterativo from '../../assets/images/banner-sergoias.png'
import bannerSUAE from '../../assets/images/banner-ouvir-acolher.png'
import bannerGoEdu from '../../assets/images/bannerGoEdu.png'
import themes from '../../assets/styles/themes'
import Modal from '../../components/DataDisplay/Modal/Modal'
import ModalBolsaEstudo from '../../components/DataDisplay/ModalBolsaEstudo/ModalBolsaEstudo'
import Footer from '../../components/layout/Footer/Footer'
import GradeCard from '../../components/layout/GradeCard/GradeCard'
import { Col, Container, Row } from '../../components/layout/Grid/Grid'
import Header from '../../components/layout/Header/Header'
import { TabsSection } from '../../components/layout/TabsSection/TabsSection'
import IllustrationEnem from '../../components/Media/IllustrationEnem'
import IllustrationSVG from '../../components/Media/IllustrationSVG'
import LinkButton from '../../components/navigation/LinkButton/LinkButton'
import Title from '../../components/Typography/Title'
import { UserType } from '../../domain/enuns/UserType.enum'
import { limeSurvey } from '../../services/limeSurvey'
import themeState from '../../store/themeAtom'
import UserState from '../../store/UserAtom'
import { ResponseFormularioEnem } from '../../domain/entities/EnemForm'
import {
  BannerDescription,
  BannerInterativo,
  BannerInterativoContainer,
  BannerSPP,
  CardsContent,
  ColSvg,
  FlexContainer,
  FlexWrapper,
  HighlightedWord,
  LinksWrapper,
  MainSection,
  PresentationText,
} from './styles'
import { ButtonWrapper } from '../Contact/style'
import api from '../../services/api'
import { ResponseDatasEnem } from '../../domain/entities/EnemForm'

const typeBanner = {
  [UserType.student]: bannerLimeAluno,
  [UserType.teacher]: bannerLimeProfessor,
  [UserType.manager]: bannerLimeGestor,
}

function Home({ history }: any) {
  const user = useRecoilValue(UserState)
  const [theme, setTheme] = useRecoilState(themeState)
  const [showModalBolsaEstudo] = useState(false)
  const [controlLimeModal, setControlLimeModal] = useState({
    show: false,
    url: '',
    token: '',
    tipoUsuario: UserType.student,
  })

  const codigosSeriesAnoTerminarios: string[] = [
    '1413',
    '1533',
    '1543',
    '1603',
    '4433',
    '4434',
    '5213',
    '5303',
    '5313',
    '5344',
    '5353',
    '5363',
    '5393',
    '5463',
    '5513',
    '5533',
    '5573',
    '5583',
    '5593',
    '5603',
  ]

  const showMenuEnem = useMemo(() => {
    return (
      !!user &&
      user.matricula &&
      codigosSeriesAnoTerminarios.includes(user.codigoSerie) &&
      (user.codigoNivelEnsino == 3 || user.codigoNivelEnsino == 4) &&
      user.respondeuFormularioEnem !== 1
    )
  }, [user])

  const linksSimulado: Record<string, string> = {
    '5º Ano': 'https://forms.gle/vxzqyijf4WskTvDUA',
    '9º Ano': 'https://forms.gle/RMd7Vf9LtGKBurLg8',
    '3º Ano': 'https://forms.gle/1tPnmec71ze4imMh9',
    '3ª Série': 'https://forms.gle/1tPnmec71ze4imMh9',
  }

  const currentDate = new Date()
  const currentYear = currentDate.getMonth() >= 9 ? currentDate.getFullYear() : currentDate.getFullYear() - 1
  const [iniciouFormularioEnem, setIniciouFormularioEnem] = useState<boolean>(false)
  const [acessaSerGoias, setAcessaSerGoias] = useState<boolean>(false)
  const [modalEnemVisivel, setModalEnemVisivel] = useState<boolean>(false)
  const [datasFormularioEnem, setDatasFormularioEnem] = useState<ResponseDatasEnem>({} as ResponseDatasEnem)
  const [mensagemModalEnemPt1, setMensagemModalEnemPt1] = useState<string>('')
  const [mensagemModalEnemPt2, setMensagemModalEnemPt2] = useState<string>('')
  const [periodoParticipacao, setPeriodoParticipacao] = useState<boolean>(false)
  const [periodoNotas, setPeriodoNotas] = useState<boolean>(false)
  const [periodoSisu, setPeriodoSisu] = useState<boolean>(false)

  function SetMensagemModalEnem(formularioEnem: ResponseFormularioEnem) {
    if (
      new Date(datasFormularioEnem.dataInicioParticipacaoPrimeiraFase) <= currentDate &&
      currentDate <= new Date(datasFormularioEnem.dataFimParticipacao)
    ) {
      setPeriodoParticipacao(true)
    }
    if (
      new Date(datasFormularioEnem.dataInicioNotas) <= currentDate &&
      currentDate <= new Date(datasFormularioEnem.dataFimNotas)
    ) {
      setPeriodoNotas(true)
    }
    if (
      new Date(datasFormularioEnem.dataInicioSisu) <= currentDate &&
      currentDate <= new Date(datasFormularioEnem.dataFimSisu)
    ) {
      setPeriodoSisu(true)
    }

    if (!codigosSeriesAnoTerminarios.includes(user.codigoSerie)) {
      setMensagemModalEnemPt1('')
      setMensagemModalEnemPt1('')
      setModalEnemVisivel(false)
      return
    }
    if (showMenuEnem && (periodoParticipacao || periodoNotas || periodoSisu)) {
      if (
        periodoSisu &&
        (formularioEnem?.primeiraFase == false ||
          formularioEnem?.segundaFase == false ||
          formularioEnem?.notaRedacao == null)
      ) {
        setModalEnemVisivel(false)
        return
      }
      if (
        (periodoParticipacao && formularioEnem?.primeiraFase != null && formularioEnem?.segundaFase != null) ||
        (periodoNotas && !Number.isNaN(parseFloat(formularioEnem?.notaRedacao))) ||
        (periodoSisu && formularioEnem?.situacaoEnem != null)
      ) {
        setModalEnemVisivel(false)
        return
      }
    }

    if (periodoParticipacao) {
      setMensagemModalEnemPt1('Sabemos que você está inscrito no Exame Nacional do Ensino Médio - ENEM.')
      setMensagemModalEnemPt2('Conte-nos sobre a sua participação no 1° e 2° dias. Segue o formulário!')
    } else if (periodoNotas) {
      setMensagemModalEnemPt1('Você participou do Exame Nacional do Ensino Médio - ENEM. Vamos saber de suas notas?')
      setMensagemModalEnemPt2(
        'Gostaríamos que você participasse do formulário a seguir para sabermos mais sobre o seu desempenho!',
      )
    } else if (periodoSisu) {
      setMensagemModalEnemPt1('Parabéns pela sua participação no ENEM!')
      setMensagemModalEnemPt2(
        'Gostaríamos que nos contasse se você foi aprovado para ingressar em alguma instituição de Ensino Superior. Para isso, pedimos que preencha o formulário a seguir.',
      )
    }
    if (mensagemModalEnemPt1.length > 0 && mensagemModalEnemPt2.length > 0) {
      setModalEnemVisivel(true)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const data = await api.get(`${process.env.REACT_APP_LOGIN_API}/enem/DatasFormulario/${currentYear}`)
        if (!data.data) return
        setDatasFormularioEnem(data.data)
      } catch (e) {
        window.alert('Ocorreu um problema')
        console.log(e)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (user?.matricula != null)
        try {
          const dataFormulario = await api.get(
            `${process.env.REACT_APP_LOGIN_API}/enem/FomarularioEnem/${user?.matricula}/${currentYear}`,
          )
          SetMensagemModalEnem(dataFormulario.data)
          if (dataFormulario.data == null) {
            setIniciouFormularioEnem(false)
            return
          } else {
            setIniciouFormularioEnem(true)
            return
          }
        } catch (e) {
          console.log(e)
          window.alert('Ocorreu um problema')
        }
    })()
  })

  useEffect(() => {
    (async () => {
      if (user) {
        try {
          const escolas = await api.get(`${process.env.REACT_APP_LOGIN_API}/escola/EscolasPermitidas`);
          if (!escolas.data?.some((escola: { codigoMec: number }) => escola.codigoMec == user?.codigoEscola)) {
            setAcessaSerGoias(true);
          }
        } catch (e) {
          window.alert('Ocorreu um problema.')
        }
      }
    })()
  }, [user])

  useEffect(() => {
    if (user) {
      const activeTheme = showMenuEnem ? 'home_enem' : 'home'
      setTheme(activeTheme)
    }
  }, [user])

  useEffect(() => {
    ;(async () => {
      if (user?.token && user?.matricula) {
        const response = await axios.post(`${process.env.REACT_APP_LOGIN_API}/user/GetAlunoByToken`, {
          token: user.token,
        })
        const userData = response.data
        if (userData == null) {
          localStorage.removeItem('netescola')
          history.push('/login')
        }
      }
    })()
  }, [user])

  useEffect(() => {
    const getLimeControls = async () => {
      const limeControls = await limeSurvey(user)

      if (limeControls) {
        setControlLimeModal({ ...limeControls, show: limeControls.questionarioLimesurvey })
      }
    }
    user && getLimeControls()
  }, [user])

  return (
    <>
      <Modal show={controlLimeModal.show} link={controlLimeModal.url && `${controlLimeModal.url}`}>
        <img src={typeBanner[controlLimeModal.tipoUsuario]} alt="banner lime" />
      </Modal>
      {modalEnemVisivel && (
        <Modal key="modalEnem" show={modalEnemVisivel}>
          Caro(a) estudante {user?.nome}!
          <br /> <br />
          <p>{mensagemModalEnemPt1}</p> <br />
          <p>{mensagemModalEnemPt2}</p> <br />
          <ButtonWrapper>
            <Link to="/notas-enem" style={{ color: 'blue' }}>
              <u>RESPONDER FORMULÁRIO ENEM</u>
            </Link>
          </ButtonWrapper>
        </Modal>
      )}
      <ModalBolsaEstudo show={showModalBolsaEstudo} />
      <Header />
      <MainSection>
        <Container>
          <Row align="center">
            <Col>
              <FlexContainer>
                <HighlightedWord>Olá,</HighlightedWord>
                <PresentationText>
                  Seja bem-vindo à plataforma de conteúdos didáticos da rede pública estadual de ensino! Aqui você
                  encontra videoaulas, materiais, atividades e listas de exercícios produzidas pelos professores da
                  Secretaria de Estado de Educação. Agora ficou muito mais fácil aprender pela Internet! Vamos juntos?
                  Clique na série desejada e comece o estudo de hoje!
                </PresentationText>
                <LinksWrapper>
                  <LinkButton
                    bgcolor={theme === 'home_enem' ? themes[theme].primary : '#FF3E5F'}
                    text="Dicas de Estudo"
                    textcolor="#fff"
                    to="/dicas-para-estudar-em-casa"
                  />
                  <LinkButton
                    bgcolor={theme === 'home_enem' ? themes[theme].primary : '#A9D18E'}
                    text="Biossegurança"
                    textcolor="#fff"
                    href="https://site.educacao.go.gov.br/wp-content/uploads/2021/07/GUIA-DE-IMPLEMENTACAO-VOLTAS-AS-AULAS.pdf"
                    target="_blank"
                  />
                  <LinkButton
                    bgcolor={theme === 'home_enem' ? themes[theme].primary : '#8FAADC'}
                    text="Educação Hibrida"
                    textcolor="#fff"
                    href="https://site.educacao.go.gov.br/wp-content/uploads/2021/07/GUIA-DE-POSSIBILIDADES.pdf"
                  />
                </LinksWrapper>
              </FlexContainer>
            </Col>
            <ColSvg>{theme === 'home_enem' ? <IllustrationEnem /> : <IllustrationSVG />}</ColSvg>
          </Row>
        </Container>
        <BannerInterativoContainer target="_blank" href="https://www.goedu.site/">
          <div style={{ marginBottom: '3em' }}>
            <BannerInterativo src={bannerGoEdu} />
          </div>
        </BannerInterativoContainer>
      </MainSection>
      {acessaSerGoias && (
        <BannerInterativoContainer
          target="_blank"
          href={
            user?.authToken
              ? `${process.env.REACT_APP_SAGRES}?token=${user?.authToken}&matricula=${user?.matricula}`
              : '/login'
          }
        >
          <BannerInterativo src={bannerInterativo} />
        </BannerInterativoContainer>
      )}
      <BannerInterativoContainer
        target="_blank"
        href=
        {
          user?.authToken
            ? `${process.env.REACT_APP_SUAE}?token=${user?.authToken}`
            : '/login'
        }
      >
        <BannerInterativo src={bannerSUAE} />
      </BannerInterativoContainer>
      <CardsContent>
        <Container>
          <Title textcolor="#51A34F">Aulas</Title>
          <FlexWrapper>
            <GradeCard
              theme={themes.fundamental}
              title="Fundamental - Anos Iniciais"
              links={[
                {
                  externo: true,
                  label: '1º Ano',
                  link: '/conteudos/fundamental/18/?turma=1º Ano&title=Fundamental - Anos Iniciais',
                },
                {
                  externo: true,
                  label: '2º Ano',
                  link: '/conteudos/fundamental/19/?turma=2º Ano&title=Fundamental - Anos Iniciais',
                },
                {
                  externo: true,
                  label: '3º Ano',
                  link: '/conteudos/fundamental/20/?turma=3º Ano&title=Fundamental - Anos Iniciais',
                },
                {
                  externo: true,
                  label: '4º Ano',
                  link: '/conteudos/fundamental/21/?turma=4º Ano&title=Fundamental - Anos Iniciais',
                },
                {
                  externo: true,
                  label: '5º Ano',
                  link: '/conteudos/fundamental/22/?turma=5º Ano&title=Fundamental - Anos Iniciais',
                },
              ]}
            />
            <GradeCard
              theme={themes.fundamental_dois}
              title="Fundamental - Anos Finais"
              links={[
                {
                  externo: true,
                  label: '6º Ano',
                  link: '/conteudos/fundamental_dois/23/?turma=6º Ano&title=Fundamental - Anos Finais',
                },
                {
                  externo: true,
                  label: '8º Ano - Goiás TEC',
                  link: '/conteudos/fundamental_dois/297/?turma=8º Ano Goiás Tec&title=Fundamental - Anos Finais',
                },
                {
                  externo: true,
                  label: '7º Ano',
                  link: '/conteudos/fundamental_dois/24/?turma=7º Ano&title=Fundamental - Anos Finais',
                },
                {
                  externo: true,
                  label: '9º Ano - Goiás TEC',
                  link: '/conteudos/fundamental_dois/298/?turma=9º Ano Goiás Tec&title=Fundamental - Anos Finais',
                },
                {
                  externo: true,
                  label: '8º Ano',
                  link: '/conteudos/fundamental_dois/25/?turma=8º Ano&title=Fundamental - Anos Finais',
                },
                {
                  link: false,
                  style: { hover: 'none', cursor: 'default' },
                },
                {
                  externo: true,
                  label: '9º Ano',
                  link: '/conteudos/fundamental_dois/26/?turma=9º Ano&title=Fundamental - Anos Finais',
                },
              ]}
            />
            <GradeCard
              theme={themes.ensino_medio}
              title="Ensino Médio - Seduc em Ação"
              links={[
                {
                  externo: true,
                  label: '1ª Série',
                  link: '/conteudos/ensino_medio/27/?turma=1ª Série&title=Ensino Médio - Seduc em Ação',
                },
                {
                  externo: true,
                  label: '1ª Série - Goiás Tec',
                  link: 'conteudos/goias_tec/191/?turma=1ª Série&title=Ensino Médio - Goiás Tec',
                },
                {
                  externo: true,
                  label: '2ª Série',
                  link: '/conteudos/ensino_medio/28/?turma=2ª Série&title=Ensino Médio - Seduc em Ação',
                },
                {
                  externo: true,
                  label: '2ª Série - Goiás Tec',
                  link: '/conteudos/goias_tec/192/?turma=2ª Série&title=Ensino Médio - Goiás Tec',
                },
                {
                  externo: true,
                  label: '3ª Série',
                  link: '/conteudos/ensino_medio/29/?turma=3ª Série&title=Ensino Médio - Seduc em Ação',
                },
                {
                  externo: true,
                  label: '3ª Série - Goiás Tec',
                  link: '/conteudos/goias_tec/193/?turma=3ª Série&title=Ensino Médio - Goiás Tec',
                },
                {
                  externo: true,
                  label: 'Cursos FIC',
                  link: 'https://formacao.educacao.go.gov.br/',
                },
              ]}
            />
            <GradeCard
              theme={themes.enem}
              title="Ensino Médio - ENEM"
              links={[
                {
                  externo: true,
                  label: 'Goiás bem no ENEM',
                  link: '/conteudos/enem/00/enem/?turma=Goiás Bem no ENEM - GOBEM&title=Ensino Médio - ENEM',
                },
              ]}
            />
            <GradeCard
              theme={themes.eja}
              title="EJA"
              links={[
                {
                  externo: true,
                  label: '1º semestre',
                  link: '/conteudos/eja/119/?turma=1º semestre&title=EJA',
                },
                {
                  externo: true,
                  label: '2º semestre',
                  link: '/conteudos/eja/120/?turma=2º semestre&title=EJA',
                },
                {
                  externo: true,
                  label: '3º semestre',
                  link: '/conteudos/eja/121/?turma=3º semestre&title=EJA',
                },
                {
                  externo: true,
                  label: '4º semestre',
                  link: '/conteudos/eja/122/?turma=4º semestre&title=EJA',
                },
              ]}
            />
          </FlexWrapper>
        </Container>
        <BannerSPP theme={themes.BannerSPP}>
          <Title color="white">Subsídio à Prática Pedagógica</Title>
          <BannerDescription>
            <Link to="/conteudos/pratica_pedagogica/252/?&title=Fundamental - Anos Finais&turma=Sub. à Prática Pedagógica">
              Ensino Fundamental
            </Link>
            <br />
            <Link to="/conteudos/pratica_pedagogica/251/?title=Ensino Médio&turma=Sub. à Prática Pedagógica">
              Ensino Médio
            </Link>
          </BannerDescription>
        </BannerSPP>
        <Container>
          <TabsSection key="uniqueKey" />
        </Container>
      </CardsContent>
      <Footer />
    </>
  )
}

export default Home
function setTheme(activeTheme: string) {
  throw new Error('Function not implemented.')
}
