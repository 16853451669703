function Linguas () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
      <path
        d="M807.21,25.85H272.79c-140,0-253.45,113.47-253.45,253.45V581.36c0,130.88,99.2,238.6,226.53,252a17.77,17.77,0,0,1,13.94,26l-98.1,185.56a6.26,6.26,0,0,0,9.06,8.1L460.68,856a122.23,122.23,0,0,1,68.72-21.15H807.21c140,0,253.45-113.47,253.45-253.45V279.3C1060.66,139.32,947.19,25.85,807.21,25.85Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="17.36"
      />
      <path
        d="M467.36,429.3H301.24c-9.73,21.93-19.49,43.87-27.76,65.29H198C253.51,372.82,321.71,236.41,382.1,129.23h4.38C446.89,236.41,515.1,372.82,570.63,494.59h-75.5C486.84,472.65,477.1,451.23,467.36,429.3ZM328,370.87H441C422.54,329.94,403,289,384.54,251,365.54,289,346.54,329.94,328,370.87Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="11.83"
      />
      <path
        d="M882,666.32v57.2H617.54c0-54.79,118.18-209.19,163.93-295.45H620.39v-57.2H879.17c0,54.79-118.66,209.19-164.41,295.45Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="11.83"
      />
    </svg>
  )
}

export default Linguas
