import { useState } from 'react'
import styled from 'styled-components/macro'
import { useRecoilState } from 'recoil'
import UserState from '../../../store/UserAtom'
import { Link, useHistory } from 'react-router-dom'
import { HiBookOpen, HiMail, HiOutlineHome } from 'react-icons/hi'

import { FaGraduationCap } from 'react-icons/fa'
import { MenuContainer, MenuButton, UserData, Divisor, SideMenuNav, SideMenuItem, LogoutButton } from './style'

const MenuMobile = () => {
  const [user, setUser] = useRecoilState(UserState)
  const history = useHistory()
  const [open, setopen] = useState(false)

  async function signOut() {
    await localStorage.removeItem('netescola')
    await setUser(null)
    history.push('/login')
  }

  return (
    <>
      <MenuContainer open={open}>
        <MenuButton onClick={() => setopen(!open)}>
          <div></div>
          <div></div>
          <div></div>
        </MenuButton>
        {user?.matricula ? (
          <UserData>
            <FaGraduationCap style={{ fontSize: '4.5rem', textAlign: 'center' }} />
            <p>{user?.nome}</p>
            <p>
              Turma: {user?.turma} {user?.composicao}
            </p>
            <p>{user?.escola}</p>
            <Divisor />
          </UserData>
        ) : (
          <UserData>
            <FaGraduationCap style={{ fontSize: '4.5rem', textAlign: 'center' }} />
            <p>{user?.displayName}</p>
            <p>{user?.mailAddress && 'E-mail:' + user?.mailAddress}</p>
            <Divisor />
          </UserData>
        )}
        <SideMenuNav>
          <SideMenuItem to="/">
            <HiOutlineHome />
            <span>Home</span>
          </SideMenuItem>
          <SideMenuItem to="/contato">
            <HiMail /> <span>Contato</span>
          </SideMenuItem>
          <SideMenuItem to="/dicas-de-estudo">
            <HiBookOpen />
            <span>Dicas de Estudo</span>
          </SideMenuItem>
          <SideMenuItem to="/notas-enem">
            <HiBookOpen />
            <span>Notas Enem</span>
          </SideMenuItem>
        </SideMenuNav>
        <LogoutButton onClick={() => signOut()}>Sair</LogoutButton>
      </MenuContainer>
    </>
  )
}

export default MenuMobile
