import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Container } from '../Grid/Grid'
import ga from 'react-ga'
import { FooterContainer, FooterWrapper, TextFooter } from './style'

function Footer() {
  const { pathname } = useLocation()

  useEffect(() => {
    ga.pageview(pathname)
  }, [pathname])

  return (
    <FooterContainer>
      <Container>
        <FooterWrapper>
          <TextFooter>
            O Portal NetEscola é um portal direcionado a professores e estudantes da rede pública de educação, que
            disponibiliza conteúdos para pesquisas escolares, atividades educativas e outros conteúdos que auxiliarão
            nos estudos.
          </TextFooter>
          <img
            className="logo-footer"
            src="https://repositorioweb.educacao.go.gov.br/logos/logo-seduc-branca.svg"
            alt="logo footer"
            width="300"
          />
        </FooterWrapper>
      </Container>
    </FooterContainer>
  )
}

export default Footer
