import React from 'react'
import { Link } from 'react-router-dom'
import { CardWrapper, Illustration, CardContent, CardTitle } from './style'

type GradeCardProps = {
  theme: {
    primary: string
    secondary: string
    banner: string
    illustration: string
  }
  title: string
  links: any[]
  label?: any
}

const GradeCard: React.FC<GradeCardProps> = ({ theme, title, links = [] }: GradeCardProps) => (
  <CardWrapper>
    <Illustration src={theme.illustration} />
    <CardContent bgcolor={theme.primary}>
      <CardTitle>{title}</CardTitle>
      {links.map((linkItem, i) => {
        return linkItem.externo ? (
          <a key={i} href={linkItem.link}>
            {linkItem.label}
          </a>
        ) : (
          <Link key={i} to={linkItem.link}>
            {linkItem.label}
          </Link>
        )
      })}
    </CardContent>
  </CardWrapper>
)

export default GradeCard
