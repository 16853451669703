import styled from 'styled-components/macro'
import banner from '../../assets/images/banner-spp.jpg'
import bannerMobile from '../../assets/images/banner-spp-mobile.jpg'

export const MainSection = styled.main``

export const PresentationText = styled.p`
  font-size: 18px;
  line-height: 38px;
  color: ${({ theme }) => theme.primary};

  @media (max-width: 980px) {
    font-size: 16px;
    line-height: 32px;
    text-align: justify;
  }
`

export const HighlightedWord = styled.p`
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.primary};
  margin-top: 10px;
  text-transform: capitalize;
`

export const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 80px -10px 0;

  @media (max-width: 1100px) {
    margin: 15px 0 15px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ColSvg = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    position: absolute;
    z-index: -1;
    opacity: 0.1;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @media (max-width: 768px) {
    margin-top: 10%;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  padding: 50px 0;
  flex-wrap: wrap;
  gap: 60px;
  justify-content: center;
  

  @media (max-width: 986px) {
    justify-content: center;
  }
`

export const CardsContent = styled.section`
  padding-top: 70px;
  min-height: 100vh;
`

export const BannerSPP = styled.div`
  text-align: center;
  color: white;
  font-size: 50px;
  font-weight: bold;
  height: 373px;
  background: url(${banner}) no-repeat center center;
  background-size: contain;

  display: grid;
  place-content: center;

  @media (max-width: 650px) {
    background: url(${bannerMobile}) no-repeat center center;
    background-size: cover;
    height: 200px;
  }
`

export const BannerInterativo = styled.img`
  max-width: 100%;
`

export const BannerInterativoContainer = styled.a`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const BannerDescription = styled.p`
  font-size: 34px;
  color: #1fa22e;
  margin: 15px auto;
  border-radius: 16px;
  padding: 30px;
  background: white;
  opacity: 0.82;

  a {
    color: #02ab6c;
  }

  @media (max-width: 986px) {
    font-size: 24px;
    padding: 15px;
  }

  @media (max-width: 769px) {
    font-size: 16px;
    padding: 10px;
  }
`
