import styled from 'styled-components/macro'

export const PostTitle = styled.h2`
font-size: 2rem;
margin-bottom: 20px;
color: ${({ theme }) => theme.primary};

@media (max-width: 768px) {
  font-size: 1rem;
}
`

export const PostContent = styled.article`
color: #707070;
  
*{
  word-break: break-word;
}

p {
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”,
    “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif;
  text-indent: 36px;
  margin-bottom: 1.8rem;

  strong {
    line-height: initial;
  }

  .has-large-font-size {
    line-height: 2.8rem;
  }
}

p,
ol,
li {
  line-height: 2.1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 10px;
}

.justificado {
  text-align: justify;
}
`
