import styled from 'styled-components/macro'

export const InputWrapper = styled.div`
width: 100%;
height: 55px;
position: relative;
border-bottom: 1px solid #eee;
`

export const InputText = styled.input`
position: absolute;
width: 100%;
height: 55px;
border: none;
padding-left: 70px;
background-color: transparent;
font-size: 22px;
font-family: "BwMitga", -apple-system, BlinkMacSystemFont, “Segoe UI”,
  “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”,
  “Helvetica Neue”, sans-serif;
color: #808080;

:focus-visible {
  outline-width: 0;
  border-width: 0;
  outline: none;
}

::placeholder {
  color: #bbbbbb;
}
`

export const InputIcon = styled.div`
width: 30px;
margin: 13px 0 0 20px;
position: absolute;
`

export const SearchButton = styled.button`
width: 50px;
height: 100%;
position: absolute;
background-color: transparent;
padding: 7px;
border: none;
right: 0;
cursor: pointer;

&:active {
  path {
    fill: #e6e6e6;
  }
}
`
