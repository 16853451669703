import bannerFundamental from '../images/banner-fundamental.jpg'
import bannerFundamentalDois from '../images/banner-fundamental-dois.jpg'
import bannerEnem from '../images/banner-enem.jpg'
import bannerEja from '../images/banner-eja.jpg'
import bannerEnsinoMedio from '../images/banner-ensino-medio.jpg'
import bannerGoiasTec from '../images/banner-goias-tec.jpg'
import bannerGeral from '../images/jovens.jpg'
import bannerFormulario from '../images/banner-formulario.jpg'

import fotofundamental from '../images/foto-fundamental.jpg'
import fotofundamentaldois from '../images/foto-fundamental-dois.jpg'
import fotoenem from '../images/foto-enem.jpg'
import fotomedio from '../images/foto-medio.jpg'
import fotoeja from '../images/foto-eja.jpg'
import fotogoiastec from '../images/foto-goiastec.jpg'

const themes: { [unit: string]: any } = {
  home: {
    primary: '#51A34F',
    secondary: '#0D5E16',
    banner: bannerGeral,
    illustration: fotofundamental,
  },
  home_enem: {
    primary: '#084C94',
    secondary: '#0C335D',
    banner: bannerFormulario,
    illustration: fotofundamentaldois,
  },
  fundamental: {
    primary: '#E99A22',
    secondary: '#9c6716',
    banner: bannerFundamental,
    illustration: fotofundamental,
  },
  fundamental_dois: {
    primary: '#65B337',
    secondary: '#12721d',
    banner: bannerFundamentalDois,
    illustration: fotofundamentaldois,
  },
  ensino_medio: {
    primary: '#FF6951',
    secondary: '#EE472D',
    banner: bannerEnsinoMedio,
    illustration: fotomedio,
  },
  enem: {
    primary: '#8479e2',
    secondary: '#6258b6',
    banner: bannerEnem,
    illustration: fotoenem,
  },
  eja: {
    primary: '#40C4B7',
    secondary: '#1c7a71',
    banner: bannerEja,
    illustration: fotoeja,
  },
  goias_tec: {
    primary: '#F33D8A',
    secondary: '#9e2457',
    banner: bannerGoiasTec,
    illustration: fotogoiastec,
  },
  emocionario: {
    primary: '#cfc13e',
    secondary: '#918416',
    banner: bannerGeral,
    illustration: fotogoiastec,
  },
  foco_aprendizagem: {
    primary: '#51A34F',
    secondary: '#0D5E16',
    banner: bannerGeral,
    illustration: fotofundamental,
  },
  vamos_aprender: {
    primary: '#51A34F',
    secondary: '#0D5E16',
    banner: bannerGeral,
    illustration: fotofundamental,
  },
  senac_educacao: {
    primary: '#51A34F',
    secondary: '#0D5E16',
    banner: bannerGeral,
    illustration: fotofundamental,
  },
  pratica_pedagogica: {
    primary: '#51A34F',
    secondary: '#0D5E16',
    banner: bannerGeral,
    illustration: fotofundamental,
  },
}

export default themes
