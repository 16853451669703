import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import Routes from './routes'
import { RecoilRoot } from 'recoil'
import ga from 'react-ga'
ga.initialize('UA-157357095-2')

Sentry.init({
  dsn: 'https://cc68d559354546c3a068514679c32485@sentry.educacao.go.gov.br/3',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  tracesSampleRate: 0.2
})

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Routes />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
)
