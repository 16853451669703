const IllustrationSVG = () => (
  <svg id="seduc-illustration" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 696.767 595.29">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Retângulo_26"
          data-name="Retângulo 26"
          width="696.767"
          height="595.29"
          transform="translate(94.17 64.38)"
          fill="none"
        />
      </clipPath>
      <clipPath id="clip-path-2">
        <path
          id="Caminho_373"
          data-name="Caminho 373"
          d="M395.258,210.78s-22.509,36.744-7.509,97.447,40.509,91.064,40.509,91.064l3-26.356S371.241,273.092,420.013,210.8C420.013,210.78,411.767,198,395.258,210.78Z"
          transform="translate(-382.594 -205.099)"
          fill="#006ab2"
        />
      </clipPath>
      <clipPath id="clip-path-3">
        <path
          id="Caminho_377"
          data-name="Caminho 377"
          d="M440.313,228.914S439.66,196.9,420.9,198.5s-30.755,16.755-30.755,16.755,37.509-3.2,30.755,31.952"
          transform="translate(-390.15 -198.445)"
          fill="#00a8fd"
        />
      </clipPath>
      <clipPath id="clip-path-4">
        <path
          id="Caminho_380"
          data-name="Caminho 380"
          d="M510.758,210.78s22.492,36.744,7.492,97.447-40.509,91.064-40.509,91.064l-3-26.356S534.758,273.092,486,210.8C486,210.78,494.249,198,510.758,210.78Z"
          transform="translate(-474.74 -205.099)"
          fill="#006ab2"
        />
      </clipPath>
      <clipPath id="clip-path-5">
        <path
          id="Caminho_384"
          data-name="Caminho 384"
          d="M466.29,228.914s.67-32.019,19.425-30.41,30.755,16.755,30.755,16.755-37.509-3.2-30.755,31.952"
          transform="translate(-466.29 -198.445)"
          fill="#00a8fd"
        />
      </clipPath>
      <clipPath id="clip-path-6">
        <path
          id="Caminho_387"
          data-name="Caminho 387"
          d="M472.8,194.557c-2.933-4.541-8.631-7.171-16.475-7.607a52.876,52.876,0,0,0-18.017,2.346,11.177,11.177,0,0,0-7.828,7.7c-1.391,4.842,0,11.293,4.257,19.185a81.555,81.555,0,0,0,6.235,9.8l5.816-5.026c-4.626-6.065-10.525-16.755-9.134-21.581.218-.787.737-1.759,2.8-2.429,9.721-3.1,22.861-2.982,26.163,2.145,2.464,3.8-.335,11.812-7.693,21.949l5.933,4.876C474.76,212.2,477.425,201.711,472.8,194.557Z"
          transform="translate(-429.96 -186.901)"
          fill="#00a8fd"
        />
      </clipPath>
      <clipPath id="clip-path-7">
        <path
          id="Caminho_390"
          data-name="Caminho 390"
          d="M537.966,422.73l-126.925-2.4a14.663,14.663,0,0,1-14.179-15.08l6.7-125.093c0-41.687,31.744-75.481,70.9-75.481h0c39.152,0,70.879,33.795,70.879,75.481l6.7,127.489a14.663,14.663,0,0,1-14.079,15.079Z"
          transform="translate(-396.856 -204.68)"
          fill="#00a7fa"
        />
      </clipPath>
      <clipPath id="clip-path-8">
        <path
          id="Caminho_394"
          data-name="Caminho 394"
          d="M488.525,359.593l-60.7-1.139a7,7,0,0,1-6.788-7.221l3.235-59.816c0-19.938,15.185-36.107,33.906-36.107h0c18.721,0,33.906,16.169,33.906,36.107l3.218,60.955a7,7,0,0,1-6.771,7.221Z"
          transform="translate(-421.028 -255.31)"
          fill="#ffbe3b"
        />
      </clipPath>
      <clipPath id="clip-path-9">
        <path
          id="Caminho_403"
          data-name="Caminho 403"
          d="M425.258,97.43l10.542,5.429,8.983,12.147,3.235,12.08s-33.353,37.464-77.382,54.94c0,0-30.436-.3-30.7-2.631s13.241-29.807,13.241-29.807S409.453,118.491,425.258,97.43Z"
          transform="translate(-339.928 -97.43)"
          fill="#ffd7b1"
        />
      </clipPath>
      <clipPath id="clip-path-10">
        <path
          id="Caminho_401"
          data-name="Caminho 401"
          d="M346.59,153.176s11.849,4.273,11.514,8.076-2.414,10.723,0,12.767,8.631.117,8.581,3.167-1.123,12.667-1.123,12.667l56.817-26.389,29.967-37.3L436.273,102.3,422.865,90.73,409.91,96.041Z"
          transform="translate(-346.59 -90.73)"
          fill="#ea344a"
        />
      </clipPath>
      <clipPath id="clip-path-11">
        <path
          id="Caminho_410"
          data-name="Caminho 410"
          d="M222.233,126.377l-8.548-.955-9.067-6.049-5.028-7.506s14.363-34.834,40.794-58.643c0,0,21.168-7.875,21.906-6.333s-2.2,24.178-2.2,24.178S228.25,107.527,222.233,126.377Z"
          transform="translate(-199.59 -46.691)"
          fill="#ffd7b1"
        />
      </clipPath>
      <clipPath id="clip-path-12">
        <path
          id="Caminho_408"
          data-name="Caminho 408"
          d="M263.671,69.568s-9.218.184-9.872-2.53-.838-8.093-3.017-8.847-6.017,2.212-6.7,0S241.9,49.11,241.9,49.11L208.681,82.57,196.63,116.382l16.76,12.281,11.967,4.474,7.71-7.138Z"
          transform="translate(-196.63 -49.11)"
          fill="#36a0e8"
        />
      </clipPath>
      <clipPath id="clip-path-13">
        <path
          id="Caminho_417"
          data-name="Caminho 417"
          d="M609.685-9l8.179,2.915,7.626,7.992,3.352,8.512S607.724,41.1,577.053,58.2c0,0-22.274,2.781-22.677,1.106s7.14-23.072,7.14-23.072S599.964,8.023,609.685-9Z"
          transform="translate(-554.361 9)"
          fill="#ffd7b1"
        />
      </clipPath>
      <clipPath id="clip-path-14">
        <path
          id="Caminho_415"
          data-name="Caminho 415"
          d="M557.68,39.113s9.017,1.96,9.1,4.758-.821,8.093,1.156,9.349,6.3-.771,6.553,1.474.268,9.349.268,9.349l39.236-24.881L632.665,9,618.838-6.846,608.078-14l-9,5.161Z"
          transform="translate(-557.68 14)"
          fill="#36a0e8"
        />
      </clipPath>
      <clipPath id="clip-path-15">
        <path
          id="Caminho_422"
          data-name="Caminho 422"
          d="M524.624,124.418l-1.911-1.558-11.95,6.451s-8.38,20.91-6.6,21.028,36.152,12,52.275,25.769c0,0,12.637-1.357,13.408-2.614S524.624,124.418,524.624,124.418Z"
          transform="translate(-503.913 -122.86)"
          fill="#fff9ee"
        />
      </clipPath>
      <clipPath id="clip-path-16">
        <path
          id="Caminho_425"
          data-name="Caminho 425"
          d="M510.46,145.234s6.7-17.76,7.877-21.932,43.9,16.37,54.755,29.07c0,0-6.134,22.05-8.548,22.033S543.444,156.678,510.46,145.234Z"
          transform="translate(-510.46 -122.752)"
          fill="#fff9ee"
        />
      </clipPath>
      <clipPath id="clip-path-17">
        <path
          id="Caminho_442"
          data-name="Caminho 442"
          d="M290.676,105.35s-44.967,44.3-55.426,71.862c0,0,15.319,13.4,19.827,17.341,0,0,33.855-55.727,56.214-70.74C311.291,123.814,298.7,110.762,290.676,105.35Z"
          transform="translate(-235.25 -105.35)"
          fill="#00c040"
        />
      </clipPath>
      <clipPath id="clip-path-18">
        <path
          id="Caminho_455"
          data-name="Caminho 455"
          d="M500.01,54.848c-23.3-7.825-45.99,10.053-46.04,35.99v.955c.436,31.835,39.1,48.255,59.214,26.808,19.174-20.475,10.525-43.446,1.927-53.616a33.337,33.337,0,0,0-15.1-10.137Z"
          transform="translate(-453.97 -52.993)"
          fill="#4eb7f2"
        />
      </clipPath>
      <clipPath id="clip-path-19">
        <path
          id="Caminho_465"
          data-name="Caminho 465"
          d="M358.65,177.284l3.35,7.255a1.274,1.274,0,0,0,.939.737l7.525,1.156a1.357,1.357,0,0,1,.687,2.262L365.7,194.34a1.458,1.458,0,0,0-.352,1.19l1.274,7.975a1.257,1.257,0,0,1-1.793,1.391l-6.7-3.77a1.19,1.19,0,0,0-1.156,0l-6.7,3.77a1.257,1.257,0,0,1-1.81-1.391l1.291-7.975a1.457,1.457,0,0,0-.352-1.19l-5.447-5.646a1.357,1.357,0,0,1,.687-2.262l7.525-1.156a1.274,1.274,0,0,0,.939-.737l3.352-7.255a1.207,1.207,0,0,1,2.2,0Z"
          transform="translate(-343.576 -176.579)"
          fill="#eac426"
        />
      </clipPath>
      <clipPath id="clip-path-20">
        <path
          id="Caminho_470"
          data-name="Caminho 470"
          d="M293.906,57.117l7.224,2.564a1.207,1.207,0,0,0,1.14-.2l6.067-4.876a1.24,1.24,0,0,1,1.994,1.056V63.77a1.357,1.357,0,0,0,.536,1.089L317.1,69.5a1.357,1.357,0,0,1-.352,2.346l-7.24,2.446a1.324,1.324,0,0,0-.821.888l-2.212,7.741a1.224,1.224,0,0,1-2.212.385l-4.425-6.585a1.19,1.19,0,0,0-1.022-.553l-7.609.134a1.324,1.324,0,0,1-1.022-2.111l4.508-6.518a1.357,1.357,0,0,0,.184-1.206l-2.531-7.674a1.29,1.29,0,0,1,1.559-1.676Z"
          transform="translate(-289.925 -54.346)"
          fill="#eac426"
        />
      </clipPath>
      <clipPath id="clip-path-21">
        <path
          id="Caminho_475"
          data-name="Caminho 475"
          d="M546.794,52.6l12.738,4.524a2.079,2.079,0,0,0,2.028-.352l10.71-8.6a2.2,2.2,0,0,1,3.52,1.893l-.1,14.292a2.446,2.446,0,0,0,.939,1.944l11.009,8.2a2.4,2.4,0,0,1-.587,4.138l-12.8,4.323a2.279,2.279,0,0,0-1.441,1.558l-3.922,13.672a2.145,2.145,0,0,1-3.888.67l-7.81-11.628a2.162,2.162,0,0,0-1.827-.972l-13.408.251a2.346,2.346,0,0,1-1.81-3.72l7.978-11.511a2.462,2.462,0,0,0,.318-2.161l-4.391-13.5A2.3,2.3,0,0,1,546.8,52.6Z"
          transform="translate(-539.694 -47.733)"
          fill="#eac426"
        />
      </clipPath>
      <clipPath id="clip-path-22">
        <path
          id="Caminho_480"
          data-name="Caminho 480"
          d="M446.146,146.981l4.307,1.525a.721.721,0,0,0,.687-.117l3.62-2.915a.737.737,0,0,1,1.19.637v4.825a.8.8,0,0,0,.318.653l3.7,2.765a.8.8,0,0,1-.2,1.407l-4.324,1.458a.871.871,0,0,0-.486.519l-1.324,4.624a.721.721,0,0,1-1.307.235l-2.648-3.937a.687.687,0,0,0-.6-.318h-4.542a.8.8,0,0,1-.62-1.257l2.7-3.887a.821.821,0,0,0,.1-.737l-1.475-4.557a.771.771,0,0,1,.9-.921Z"
          transform="translate(-443.776 -145.316)"
          fill="#eac426"
        />
      </clipPath>
      <clipPath id="clip-path-23">
        <path
          id="Caminho_510"
          data-name="Caminho 510"
          d="M308.962,9.271l4.307,1.525a.7.7,0,0,0,.67-.117l3.62-2.915a.737.737,0,0,1,1.19.637v4.825a.838.838,0,0,0,.318.67l3.721,2.748a.8.8,0,0,1-.2,1.391l-4.324,1.458a.788.788,0,0,0-.486.536l-1.324,4.624a.721.721,0,0,1-1.307.218L312.5,20.949a.721.721,0,0,0-.62-.335h-4.525a.787.787,0,0,1-.62-1.257l2.7-3.887a.787.787,0,0,0,.1-.72l-1.475-4.574a.771.771,0,0,1,.9-.9Z"
          transform="translate(-306.577 -7.606)"
          fill="#eac426"
        />
      </clipPath>
      <clipPath id="clip-path-24">
        <path
          id="Caminho_515"
          data-name="Caminho 515"
          d="M535.132,183.5l4.307,1.525a.7.7,0,0,0,.67-.117l3.62-2.915a.737.737,0,0,1,1.19.637v4.842a.821.821,0,0,0,.318.653l3.721,2.765a.821.821,0,0,1-.2,1.407l-4.324,1.458a.72.72,0,0,0-.486.519l-1.324,4.624a.721.721,0,0,1-1.307.218l-2.648-3.921a.721.721,0,0,0-.62-.335h-4.525a.787.787,0,0,1-.62-1.257l2.7-3.887a.787.787,0,0,0,.1-.72l-1.475-4.574a.771.771,0,0,1,.905-.922Z"
          transform="translate(-532.747 -181.836)"
          fill="#eac426"
        />
      </clipPath>
      <clipPath id="clip-path-25">
        <path
          id="Caminho_520"
          data-name="Caminho 520"
          d="M338.139,262.2l3.184-2.061a.7.7,0,0,0,.3-.519l.2-3.971a.6.6,0,0,1,1.006-.452l2.832,2.58a.6.6,0,0,0,.57.151l3.6-1.022a.637.637,0,0,1,.721.888l-1.441,3.669a.77.77,0,0,0,0,.6l2.028,3.351a.653.653,0,0,1-.57,1.005l-3.721-.318a.57.57,0,0,0-.52.235l-2.363,3.083a.6.6,0,0,1-1.073-.268l-.855-3.87a.687.687,0,0,0-.385-.452l-3.486-1.441a.67.67,0,0,1-.033-1.19Z"
          transform="translate(-337.794 -255.038)"
          fill="#eac426"
        />
      </clipPath>
      <clipPath id="clip-path-26">
        <path
          id="Caminho_525"
          data-name="Caminho 525"
          d="M506.823,301.692l3.2-2.061a.7.7,0,0,0,.285-.519l.218-3.988a.6.6,0,0,1,1.006-.452l2.833,2.6a.6.6,0,0,0,.553.134l3.62-1.005a.637.637,0,0,1,.721.871l-1.441,3.669a.636.636,0,0,0,0,.6l2.011,3.351a.653.653,0,0,1-.553,1l-3.738-.335a.62.62,0,0,0-.52.251l-2.363,3.083a.6.6,0,0,1-1.073-.268l-.855-3.87a.67.67,0,0,0-.369-.469l-3.486-1.424a.687.687,0,0,1-.05-1.173Z"
          transform="translate(-506.518 -294.518)"
          fill="#eac426"
        />
      </clipPath>
      <clipPath id="clip-path-27">
        <path
          id="Caminho_534"
          data-name="Caminho 534"
          d="M290.83,58.934,328.607,13.7s7.928-7.7,16.643-.39,48.1,59.631,48.1,59.631-13.241-6.9-19.492-1.676S335.077,118,335.077,118Z"
          transform="translate(-290.83 -10.16)"
          fill="#ffbc1b"
        />
      </clipPath>
      <clipPath id="clip-path-28">
        <path
          id="Caminho_541"
          data-name="Caminho 541"
          d="M425.258,208.753l-22.727-13.22a6.466,6.466,0,0,1-1.877-7.825C402.8,182.85,420.6,158.84,420.6,158.84s-1.525,6.953,1.274,9.4,23.464,13.521,23.464,13.521Z"
          transform="translate(-400.049 -158.84)"
          fill="#ffbc1b"
        />
      </clipPath>
      <clipPath id="clip-path-29">
        <path
          id="Caminho_546"
          data-name="Caminho 546"
          d="M239.239-30.688h0a8.279,8.279,0,0,1,3-2.262l6-2.614a7.964,7.964,0,0,1,3.1-.637h1.006a8.212,8.212,0,0,1,7.626,5.563c3.771,10.438,11.447,34.231,13.408,59.8,0,0-1.911,20.843-3.352,20.592S260.809,33.5,260.809,33.5,248.138-2.355,238.25-20.551a9.146,9.146,0,0,1,.989-10.137Z"
          transform="translate(-237.159 36.2)"
          fill="#4eb7f2"
        />
      </clipPath>
    </defs>
    <g id="vetor-mochila" transform="translate(-94.17 -64.38)" clipPath="url(#clip-path)">
      <path
        id="Caminho_369"
        data-name="Caminho 369"
        d="M669.771,395.769s-26.515-74.845-115.059-59.129S498.783,230.782,452.19,223.34s-191.853,64.51-190.29-64.5C262.885,76.469,118.982-13.992,70.83-11.512h0L963.375-25s34.66,310.84-28.073,290.616c-67.745-21.782-112.125-14.728-130.495,46.311-19.408,64.49-83.1,50.433-79.225,83.524"
        transform="translate(-52.366 47.492)"
        fill="#51a34f"
      />
      <path
        id="Caminho_370"
        data-name="Caminho 370"
        d="M601.139,338.229c0,6.166-49.727,11.176-111.07,11.176S379,344.378,379,338.229s49.727-11.159,111.07-11.159S601.139,332.063,601.139,338.229Z"
        transform="translate(155.962 285.317)"
        fill="#002e47"
        opacity="0.16"
      />
      <path
        id="Caminho_371"
        data-name="Caminho 371"
        d="M395.258,210.78s-22.509,36.744-7.509,97.447,40.509,91.064,40.509,91.064l3-26.356S371.241,273.092,420.013,210.8C420.013,210.78,411.767,198,395.258,210.78Z"
        transform="translate(158.392 202.925)"
        fill="#006ab2"
      />
      <g id="Grupo_61" data-name="Grupo 61" transform="translate(540.986 408.025)" clipPath="url(#clip-path-2)">
        <path
          id="Caminho_372"
          data-name="Caminho 372"
          d="M389.25,214.062s24.721,5.6,18.755,34.365,3,90.259-13.492,111.823,28.492,18.431,28.492,18.431l8.38-164.535S398.25,182.915,389.25,214.062Z"
          transform="translate(-378.095 -208.381)"
          fill="#006ab2"
          opacity="0.42"
        />
      </g>
      <path
        id="Caminho_374"
        data-name="Caminho 374"
        d="M440.313,228.914S439.66,196.9,420.9,198.5s-30.755,16.755-30.755,16.755,37.509-3.2,30.755,31.952"
        transform="translate(163.5 198.43)"
        fill="#00a8fd"
      />
      <g id="Grupo_62" data-name="Grupo 62" transform="translate(553.65 396.875)" clipPath="url(#clip-path-3)">
        <path
          id="Caminho_375"
          data-name="Caminho 375"
          d="M432.237,238.135l-3.737-.268c.922-14.527-2.062-24.462-8.883-29.573-10.056-7.59-25.14-2.094-25.14-2.044l-1.676-3.586c1.827-.972,17.431-5.864,28.492,2.011C429.605,210.556,433.36,221.816,432.237,238.135Z"
          transform="translate(-388.359 -197.211)"
          fill="#ffbe3b"
        />
        <path
          id="Caminho_376"
          data-name="Caminho 376"
          d="M403.58,231.971s11.246-15.968,36-23.155-6.7,35.94-6.7,35.94S403.58,249.547,403.58,231.971Z"
          transform="translate(-381.071 -191.985)"
          fill="#006ab2"
          opacity="0.42"
        />
      </g>
      <path
        id="Caminho_378"
        data-name="Caminho 378"
        d="M510.758,210.78s22.492,36.744,7.492,97.447-40.509,91.064-40.509,91.064l-3-26.356S534.758,273.092,486,210.8C486,210.78,494.249,198,510.758,210.78Z"
        transform="translate(220.684 202.925)"
        fill="#006ab2"
      />
      <g id="Grupo_63" data-name="Grupo 63" transform="translate(695.424 408.025)" clipPath="url(#clip-path-4)">
        <path
          id="Caminho_379"
          data-name="Caminho 379"
          d="M514.108,214.064s-24.755,5.6-18.755,34.348-3,90.259,13.509,111.823-28.492,18.431-28.492,18.431l-8.38-164.535S505.108,182.917,514.108,214.064Z"
          transform="translate(-476.599 -208.383)"
          fill="#006ab2"
          opacity="0.42"
        />
      </g>
      <path
        id="Caminho_381"
        data-name="Caminho 381"
        d="M466.29,228.914s.67-32.019,19.425-30.41,30.755,16.755,30.755,16.755-37.509-3.2-30.755,31.952"
        transform="translate(214.971 198.43)"
        fill="#00a8fd"
      />
      <g id="Grupo_64" data-name="Grupo 64" transform="translate(681.262 396.875)" clipPath="url(#clip-path-5)">
        <path
          id="Caminho_382"
          data-name="Caminho 382"
          d="M470.119,238.135l3.737-.268c-.922-14.527,2.062-24.462,8.866-29.573,10.056-7.59,25.14-2.094,25.14-2.044l1.676-3.586c-1.827-.972-17.431-5.864-28.492,2.011C472.751,210.556,469.08,221.816,470.119,238.135Z"
          transform="translate(-463.818 -197.211)"
          fill="#ffbe3b"
        />
        <path
          id="Caminho_383"
          data-name="Caminho 383"
          d="M501.161,231.971S489.915,216,465.144,208.816s6.7,35.94,6.7,35.94S501.161,249.547,501.161,231.971Z"
          transform="translate(-473.49 -191.985)"
          fill="#006ab2"
          opacity="0.42"
        />
      </g>
      <path
        id="Caminho_385"
        data-name="Caminho 385"
        d="M472.8,194.557c-2.933-4.541-8.631-7.171-16.475-7.607a52.876,52.876,0,0,0-18.017,2.346,11.177,11.177,0,0,0-7.828,7.7c-1.391,4.842,0,11.293,4.257,19.185a81.555,81.555,0,0,0,6.235,9.8l5.816-5.026c-4.626-6.065-10.525-16.755-9.134-21.581.218-.787.737-1.759,2.8-2.429,9.721-3.1,22.861-2.982,26.163,2.145,2.464,3.8-.335,11.812-7.693,21.949l5.933,4.876C474.76,212.2,477.425,201.711,472.8,194.557Z"
        transform="translate(190.412 190.632)"
        fill="#00a8fd"
      />
      <g id="Grupo_65" data-name="Grupo 65" transform="translate(620.371 377.533)" clipPath="url(#clip-path-6)">
        <path
          id="Caminho_386"
          data-name="Caminho 386"
          d="M439.627,206.3c-1.492,1.676-15.084-14.811,9.738-19.386h0s23.464,2.9,14.363,19.52S439.627,206.3,439.627,206.3Z"
          transform="translate(-426.838 -186.894)"
          fill="#006ab2"
        />
      </g>
      <path
        id="Caminho_388"
        data-name="Caminho 388"
        d="M537.966,422.73l-126.925-2.4a14.663,14.663,0,0,1-14.179-15.08l6.7-125.093c0-41.687,31.744-75.481,70.9-75.481h0c39.152,0,70.879,33.795,70.879,75.481l6.7,127.489a14.663,14.663,0,0,1-14.079,15.079Z"
        transform="translate(168.033 202.642)"
        fill="#00a7fa"
      />
      <g id="Grupo_66" data-name="Grupo 66" transform="translate(564.889 407.322)" clipPath="url(#clip-path-7)">
        <rect
          id="Retângulo_22"
          data-name="Retângulo 22"
          width="209.301"
          height="91.064"
          transform="translate(-14.257 139.787)"
          fill="#006ab2"
        />
        <path
          id="Caminho_389"
          data-name="Caminho 389"
          d="M485.779,203.7s66.84,20.609,66.84,92.655S513.534,414.9,416.191,417.645s18.436,68.092,18.436,68.092H565.055l40.392-46.311,7.71-72.8L524.663,198.74,483.5,202.878"
          transform="translate(-410.117 -208.693)"
          fill="#006ab2"
          opacity="0.21"
        />
      </g>
      <path
        id="Caminho_391"
        data-name="Caminho 391"
        d="M404.547,278.922a68.465,68.465,0,0,1,16.391-49.8,65.712,65.712,0,0,1,21.671-16.6,62.616,62.616,0,0,1,53.633,0,66.117,66.117,0,0,1,21.7,16.6,68.824,68.824,0,0,1,13.19,23.457,68.055,68.055,0,0,1,3.151,26.3,75.387,75.387,0,0,0-20.246-46.277,60.969,60.969,0,0,0-89.281,0,75.387,75.387,0,0,0-20.209,46.315Z"
        transform="translate(173.103 203.864)"
        fill="#006ab2"
      />
      <path
        id="Caminho_392"
        data-name="Caminho 392"
        d="M489.864,361.5l-60.705-1.139a7.021,7.021,0,0,1-6.788-7.221l3.235-59.832c0-19.938,15.185-36.09,33.906-36.09h0c18.721,0,33.906,16.152,33.906,36.09l3.235,60.972a7.021,7.021,0,0,1-6.788,7.221Z"
        transform="translate(185.28 238.133)"
        fill="#006ab2"
        opacity="0.42"
      />
      <path
        id="Caminho_393"
        data-name="Caminho 393"
        d="M488.525,359.593l-60.7-1.139a7,7,0,0,1-6.788-7.221l3.235-59.816c0-19.938,15.185-36.107,33.906-36.107h0c18.721,0,33.906,16.169,33.906,36.107l3.218,60.955a7,7,0,0,1-6.771,7.221Z"
        transform="translate(184.374 236.843)"
        fill="#ffbe3b"
      />
      <g id="Grupo_67" data-name="Grupo 67" transform="translate(605.401 492.153)" clipPath="url(#clip-path-8)">
        <line
          id="Linha_16"
          data-name="Linha 16"
          x2="100.527"
          transform="translate(-4.505 33.376)"
          fill="none"
          stroke="#00a7fa"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Linha_17"
          data-name="Linha 17"
          d="M0,88.668"
          transform="translate(36.759 33.376)"
          fill="none"
          stroke="#00a7fa"
          strokeWidth="1"
        />
        <line
          id="Linha_18"
          data-name="Linha 18"
          x2="15.755"
          transform="translate(10.495 46.964)"
          fill="none"
          stroke="#00a7fa"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <line
          id="Linha_19"
          data-name="Linha 19"
          x2="15.755"
          transform="translate(45.759 46.964)"
          fill="none"
          stroke="#00a7fa"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
      <rect
        id="Retângulo_23"
        data-name="Retângulo 23"
        width="46.509"
        height="31.952"
        rx="3.39"
        transform="translate(618.914 445.658)"
        fill="#006ab2"
        opacity="0.42"
      />
      <rect
        id="Retângulo_24"
        data-name="Retângulo 24"
        width="46.509"
        height="31.952"
        rx="3.39"
        transform="translate(617.405 443.262)"
        fill="#ffbe3b"
      />
      <rect
        id="Retângulo_25"
        data-name="Retângulo 25"
        width="18"
        height="12.365"
        rx="1.31"
        transform="translate(631.651 453.064)"
        fill="#00a8fd"
      />
      <path
        id="Caminho_395"
        data-name="Caminho 395"
        d="M429.639,239.238c-.1-1.223-.168-2.446-.235-3.686v-1.826a5.479,5.479,0,0,1,.235-1.944,5.228,5.228,0,0,1,2.531-3.016,5.147,5.147,0,0,1,1.995-.553h1.844c4.911-.168,9.8-.184,14.715-.2s9.8,0,14.7.184h1.86a4.793,4.793,0,0,1,1.978.553,5.312,5.312,0,0,1,2.548,3.016,5.9,5.9,0,0,1,.235,1.944l-.1,1.826c0,1.24-.117,2.463-.218,3.686-.084-1.223-.168-2.446-.218-3.686a10.045,10.045,0,0,0-.486-3.485,4.29,4.29,0,0,0-2.212-2.346,3.89,3.89,0,0,0-1.559-.335h-1.827c-4.894.151-9.8.168-14.7.184s-9.8,0-14.715-.2H434.2a3.837,3.837,0,0,0-1.559.335,4.256,4.256,0,0,0-2.229,2.346,10.049,10.049,0,0,0-.469,3.485C429.806,236.792,429.722,238.048,429.639,239.238Z"
        transform="translate(190.029 218.4)"
        fill="#006ab2"
      />
      <path
        id="Caminho_396"
        data-name="Caminho 396"
        d="M425.068,289.626a30.416,30.416,0,1,1,60.789,0,31.612,31.612,0,0,0-9.335-21.312,29.907,29.907,0,0,0-42.068,0,31.613,31.613,0,0,0-9.386,21.313Z"
        transform="translate(187.091 238.701)"
        fill="#00a8fd"
      />
      <g id="lapis">
        <path
          id="Caminho_397"
          data-name="Caminho 397"
          d="M425.258,97.43l10.542,5.429,8.983,12.147,3.235,12.08s-33.353,37.464-77.382,54.94c0,0-30.436-.3-30.7-2.631s13.241-29.807,13.241-29.807S409.453,118.491,425.258,97.43Z"
          transform="translate(129.549 130.194)"
          fill="#ffd7b1"
        />
        <g id="Grupo_69" data-name="Grupo 69" transform="translate(469.476 227.624)" clipPath="url(#clip-path-9)">
          <path
            id="Caminho_398"
            data-name="Caminho 398"
            d="M346.59,153.176s11.849,4.273,11.514,8.076-2.414,10.723,0,12.767,8.631.117,8.581,3.167-1.123,12.667-1.123,12.667l56.817-26.389,29.967-37.3L436.273,102.3,422.865,90.73,409.91,96.041Z"
            transform="translate(-335.424 -101.956)"
            fill="#ea344a"
          />
          <g id="Grupo_68" data-name="Grupo 68" transform="translate(11.166 -11.226)" clipPath="url(#clip-path-10)">
            <path
              id="Caminho_399"
              data-name="Caminho 399"
              d="M353.718,163.005c1.19.168,75.856-43.345,83.667-66.048s-89.047,43.278-89.047,43.278S338.583,160.693,353.718,163.005Z"
              transform="translate(-347.5 -89.735)"
              fill="#d12a46"
            />
            <path
              id="Caminho_400"
              data-name="Caminho 400"
              d="M446.3,105.818c-1.173.218-42.487,48.59-87.371,62.094S371.1,214.089,371.1,214.089l56.13-18.782,35.2-54.655S454.357,104.343,446.3,105.818Z"
              transform="translate(-349.891 -80.568)"
              fill="#ff3e5f"
            />
          </g>
          <path
            id="Caminho_402"
            data-name="Caminho 402"
            d="M345.6,139.07c1.073,1.391,1.274,9.835,9.218,17.543s-18.822-3.2-18.822-3.2Z"
            transform="translate(-342.583 -69.302)"
            fill="#ea344a"
          />
        </g>
      </g>
      <g id="lapis-azul">
        <path
          id="Caminho_404"
          data-name="Caminho 404"
          d="M222.233,126.377l-8.548-.955-9.067-6.049-5.028-7.506s14.363-34.834,40.794-58.643c0,0,21.168-7.875,21.906-6.333s-2.2,24.178-2.2,24.178S228.25,107.527,222.233,126.377Z"
          transform="translate(34.678 95.92)"
          fill="#ffd7b1"
        />
        <g id="Grupo_71" data-name="Grupo 71" transform="translate(234.268 142.61)" clipPath="url(#clip-path-11)">
          <path
            id="Caminho_405"
            data-name="Caminho 405"
            d="M263.671,69.568s-9.218.184-9.872-2.53-.838-8.093-3.017-8.847-6.017,2.212-6.7,0S241.9,49.11,241.9,49.11L208.681,82.57,196.63,116.382l16.76,12.281,11.967,4.474,7.71-7.138Z"
            transform="translate(-201.591 -45.056)"
            fill="#36a0e8"
          />
          <g id="Grupo_70" data-name="Grupo 70" transform="translate(-4.961 4.054)" clipPath="url(#clip-path-12)">
            <path
              id="Caminho_406"
              data-name="Caminho 406"
              d="M249.7,57.916c-.855.184-42.454,50.181-42.537,67.992s51.6-53.616,51.6-53.616S260.742,55.5,249.7,57.916Z"
              transform="translate(-189.512 -43.322)"
              fill="#2a97d3"
            />
            <path
              id="Caminho_407"
              data-name="Caminho 407"
              d="M209.721,130.315c.754-.452,18.1-44.92,46.04-66.233s-19.241-28.8-19.241-28.8L201.995,63.211,190.43,110.426S204.475,133.431,209.721,130.315Z"
              transform="translate(-200.821 -58.452)"
              fill="#4eb7f2"
            />
          </g>
          <path
            id="Caminho_409"
            data-name="Caminho 409"
            d="M242.268,57.4c-1.073-.687-3.2-6.484-10.509-9.718s13.861-2.8,13.861-2.8Z"
            transform="translate(-178.881 -47.922)"
            fill="#36a0e8"
          />
        </g>
      </g>
      <path
        id="Caminho_411"
        data-name="Caminho 411"
        d="M609.685-9l8.179,2.915,7.626,7.992,3.352,8.512S607.724,41.1,577.053,58.2c0,0-22.274,2.781-22.677,1.106s7.14-23.072,7.14-23.072S599.964,8.023,609.685-9Z"
        transform="translate(274.509 58.3)"
        fill="#ffd7b1"
      />
      <g id="Grupo_73" data-name="Grupo 73" transform="translate(828.869 49.3)" clipPath="url(#clip-path-13)">
        <path
          id="Caminho_412"
          data-name="Caminho 412"
          d="M557.68,39.113s9.017,1.96,9.1,4.758-.821,8.093,1.156,9.349,6.3-.771,6.553,1.474.268,9.349.268,9.349l39.236-24.881L632.665,9,618.838-6.846,608.078-14l-9,5.161Z"
          transform="translate(-552.117 5.622)"
          fill="#36a0e8"
        />
        <g id="Grupo_72" data-name="Grupo 72" transform="translate(5.563 -8.378)" clipPath="url(#clip-path-14)">
          <path
            id="Caminho_413"
            data-name="Caminho 413"
            d="M563.91,46.166c.888,0,51.689-39.157,55.409-56.515s-61.325,40.4-61.325,40.4S552.564,46.049,563.91,46.166Z"
            transform="translate(-558.346 14.269)"
            fill="#2a97d3"
          />
          <path
            id="Caminho_414"
            data-name="Caminho 414"
            d="M625.969-4.141c-.838.285-26.816,39.659-58.476,54s12.889,32.555,12.889,32.555l39.4-19.235,20.933-43.4S631.718-6,625.969-4.141Z"
            transform="translate(-558.728 20.595)"
            fill="#4eb7f2"
          />
        </g>
        <path
          id="Caminho_416"
          data-name="Caminho 416"
          d="M557.449,26.28c.905.922,1.777,7.071,8.246,11.93s-14.1-.486-14.1-.486Z"
          transform="translate(-556.227 32.832)"
          fill="#36a0e8"
        />
      </g>
      <path
        id="Caminho_418"
        data-name="Caminho 418"
        d="M524.624,124.418l-1.911-1.558-11.95,6.451s-8.38,20.91-6.6,21.028,36.152,12,52.275,25.769c0,0,12.637-1.357,13.408-2.614S524.624,124.418,524.624,124.418Z"
        transform="translate(240.405 147.372)"
        fill="#fff9ee"
      />
      <g id="Grupo_74" data-name="Grupo 74" transform="translate(744.317 270.232)" clipPath="url(#clip-path-15)">
        <path
          id="Caminho_419"
          data-name="Caminho 419"
          d="M502.8,144.525l19.609-10.455s84.84,17.476,62.616,31.6S534.628,179.426,502.8,144.525Z"
          transform="translate(-504.665 -115.288)"
          fill="#f9efe1"
        />
        <path
          id="Caminho_420"
          data-name="Caminho 420"
          d="M521.65,155.428c1.257-.9,18.269-12.818,18.269-12.818l22.09,14.728-5.4,14.292L536.6,173.3Z"
          transform="translate(-491.922 -109.519)"
          fill="#0095ad"
        />
        <path
          id="Caminho_421"
          data-name="Caminho 421"
          d="M561.268,182.775A159.417,159.417,0,0,0,507.15,151.51s6.972-24.429,11.732-33.51"
          transform="translate(-501.724 -126.143)"
          fill="none"
          stroke="#ff606b"
          strokeMiterlimit="10"
          strokeWidth="0.32"
        />
      </g>
      <path
        id="Caminho_423"
        data-name="Caminho 423"
        d="M510.46,145.234s6.7-17.76,7.877-21.932,43.9,16.37,54.755,29.07c0,0-6.134,22.05-8.548,22.033S543.444,156.678,510.46,145.234Z"
        transform="translate(244.831 147.299)"
        fill="#fff9ee"
      />
      <g id="Grupo_75" data-name="Grupo 75" transform="translate(755.291 270.051)" clipPath="url(#clip-path-16)">
        <path
          id="Caminho_424"
          data-name="Caminho 424"
          d="M528.56,159.474s10.777-23.457,12.654-30.276S569.1,140.926,569.1,140.926l-3.05,19.436-18.537,21.631Z"
          transform="translate(-498.224 -119.416)"
          fill="#00a5d3"
        />
      </g>
      <g id="regua">
        <path
          id="Caminho_426"
          data-name="Caminho 426"
          d="M290.676,105.35s-44.967,44.3-55.426,71.862c0,0,15.319,13.4,19.827,17.341,0,0,33.855-55.727,56.214-70.74C311.291,123.814,298.7,110.762,290.676,105.35Z"
          transform="translate(58.785 135.544)"
          fill="#00c040"
        />
        <g id="Grupo_76" data-name="Grupo 76" transform="translate(294.035 240.894)" clipPath="url(#clip-path-17)">
          <path
            id="Caminho_427"
            data-name="Caminho 427"
            d="M240.6,183.868s27.319-53.9,57.806-70.606-8.95-13.253-8.95-13.253l-25.14,26.909-18.5,26.892-7.964,22.99-5.732,6.467,8.481.57"
            transform="translate(-237.366 -109.157)"
            fill="#0cc645"
          />
          <path
            id="Caminho_428"
            data-name="Caminho 428"
            d="M307.474,112.265c-1.676.536,3.453,5.663,3.453,5.663s-24.889,18.632-54.94,70.187l-22.107-18.43,5.749,11.209,18.9,18.782,16.911-12.08L308.8,134.164l12.738-14.108S311.966,110.774,307.474,112.265Z"
            transform="translate(-236.176 -100.788)"
            fill="#04a029"
          />
          <path
            id="Caminho_429"
            data-name="Caminho 429"
            d="M246.715,117.675c.436.6.888,1.206,1.341,1.793a.737.737,0,0,0,.419.318.62.62,0,0,0,.52,0,.67.67,0,0,0,.318-.419.6.6,0,0,0,0-.536l-1.341-1.793a.8.8,0,0,0-.419-.318.7.7,0,0,0-.52,0,.637.637,0,0,0-.318.4.6.6,0,0,0,0,.536Z"
            transform="translate(-227.542 -97.703)"
            fill="#04a029"
          />
          <path
            id="Caminho_430"
            data-name="Caminho 430"
            d="M236.456,142.839a14.111,14.111,0,0,0,3.184,2.882c.184.134.369-.168.184-.3a13.559,13.559,0,0,1-3.117-2.832C236.557,142.42,236.322,142.587,236.456,142.839Z"
            transform="translate(-234.462 -80.242)"
            fill="#04a029"
          />
          <path
            id="Caminho_431"
            data-name="Caminho 431"
            d="M238.559,140.028a34.388,34.388,0,0,0,3.536,3.452c.168.134.419-.1.235-.251a34.065,34.065,0,0,1-3.52-3.351c-.151-.168-.4,0-.251.235Z"
            transform="translate(-233.045 -82.072)"
            fill="#04a029"
          />
          <path
            id="Caminho_432"
            data-name="Caminho 432"
            d="M241.014,137.565a6.436,6.436,0,0,0,2.447,2.145c.2.1.385-.2.184-.3a6.184,6.184,0,0,1-2.33-2.027C241.181,137.2,240.88,137.381,241.014,137.565Z"
            transform="translate(-231.376 -83.765)"
            fill="#04a029"
          />
          <path
            id="Caminho_433"
            data-name="Caminho 433"
            d="M242.836,133.752a27.522,27.522,0,0,0,3.453,3.535c.168.134.419-.1.251-.251a27.378,27.378,0,0,1-3.453-3.519C242.937,133.35,242.7,133.517,242.836,133.752Z"
            transform="translate(-230.149 -86.369)"
            fill="#04a029"
          />
          <path
            id="Caminho_434"
            data-name="Caminho 434"
            d="M246.023,130.65l2.263,1.91c.168.151.419-.1.251-.235l-2.279-1.927C246.09,130.248,245.856,130.5,246.023,130.65Z"
            transform="translate(-228.006 -88.461)"
            fill="#04a029"
          />
          <path
            id="Caminho_435"
            data-name="Caminho 435"
            d="M248.013,127.022a29.373,29.373,0,0,0,3.352,3.25c.168.151.419-.1.251-.235a29.526,29.526,0,0,1-3.352-3.25c-.151-.168-.386,0-.235.235Z"
            transform="translate(-226.644 -90.915)"
            fill="#04a029"
          />
          <path
            id="Caminho_436"
            data-name="Caminho 436"
            d="M250.62,124.2a6.7,6.7,0,0,0,1.676,1.81.2.2,0,0,0,.251,0,.184.184,0,0,0,0-.235,6,6,0,0,1-1.559-1.676.184.184,0,0,0-.3.184Z"
            transform="translate(-224.86 -92.717)"
            fill="#04a029"
          />
          <path
            id="Caminho_437"
            data-name="Caminho 437"
            d="M252.279,122a13.826,13.826,0,0,0,2.832,2.3.184.184,0,0,0,.184-.3,13.678,13.678,0,0,1-2.765-2.228c-.151-.168-.4.084-.251.235Z"
            transform="translate(-223.77 -94.301)"
            fill="#04a029"
          />
          <path
            id="Caminho_438"
            data-name="Caminho 438"
            d="M255.563,118.456a11.445,11.445,0,0,0,3,3.351c.168.134.352-.168.168-.3a10.793,10.793,0,0,1-2.866-3.167.184.184,0,0,0-.3.184Z"
            transform="translate(-221.523 -96.608)"
            fill="#04a029"
          />
          <path
            id="Caminho_439"
            data-name="Caminho 439"
            d="M257.289,115.257c1.006,1.022,1.994,2.061,2.983,3.1.168.168.4,0,.251-.251l-2.983-3.083c-.151-.168-.4.084-.251.251Z"
            transform="translate(-220.383 -98.854)"
            fill="#04a029"
          />
          <path
            id="Caminho_440"
            data-name="Caminho 440"
            d="M261.006,111.8a14.293,14.293,0,0,0,2.715,3.971c.151.168.4,0,.235-.235a13.824,13.824,0,0,1-2.648-3.921c-.084-.2-.385,0-.3.184Z"
            transform="translate(-217.848 -101.175)"
            fill="#04a029"
          />
          <path
            id="Caminho_441"
            data-name="Caminho 441"
            d="M264.541,109.788l1.006,1.541c.117.184.419,0,.3-.168l-1.006-1.541C264.725,109.437,264.423,109.621,264.541,109.788Z"
            transform="translate(-215.467 -102.517)"
            fill="#04a029"
          />
        </g>
      </g>
      <g id="globo">
        <path
          id="Caminho_443"
          data-name="Caminho 443"
          d="M494.407,53.456,484.351,66.122c-1.207,1.525,1.24,3.217,2.43,1.676l9.956-12.667c1.207-1.525-1.224-3.217-2.414-1.675Z"
          transform="translate(226.961 100.078)"
          fill="#ffbe3b"
        />
        <path
          id="Caminho_444"
          data-name="Caminho 444"
          d="M500.01,54.848c-23.3-7.825-45.99,10.053-46.04,35.99v.955c.436,31.835,39.1,48.255,59.214,26.808,19.174-20.475,10.525-43.446,1.927-53.616a33.337,33.337,0,0,0-15.1-10.137Z"
          transform="translate(206.643 100.177)"
          fill="#4eb7f2"
        />
        <path
          id="Caminho_456"
          data-name="Caminho 456"
          d="M511.659,117.819C530.833,97.361,522.2,74.39,513.586,64.2A33.119,33.119,0,0,0,498.5,54.15h0c6.2,4.1,14.531,11.729,15.721,23.222,1.911,18.665-4.224,33.393-20.213,39.894-11.967,4.859-28.492-3.535-37.761-10.388C466.423,127.621,495.234,135.345,511.659,117.819Z"
          transform="translate(208.184 100.959)"
          fill="#4eb7f2"
          opacity="0.27"
        />
        <path
          id="Caminho_457"
          data-name="Caminho 457"
          d="M461.628,66.714a5.159,5.159,0,0,0,.5,5.244c1.911,2.346,7.81-9.081,21.671-12.114,5.95-1.29,2.129-5.161-2.8-4.624C477.919,55.556,464.679,59.359,461.628,66.714Z"
          transform="translate(211.488 101.647)"
          fill="#fff"
          opacity="0.24"
        />
        <path
          id="Caminho_458"
          data-name="Caminho 458"
          d="M480.1,54.613a2.6,2.6,0,0,1,3.067,1.944c.1,1.223-1.106,2.329-2.715,2.48a2.615,2.615,0,0,1-3.067-1.944,2.681,2.681,0,0,1,2.715-2.48Z"
          transform="translate(222.469 101.233)"
          fill="#fff"
          opacity="0.24"
        />
        <g id="Grupo_77" data-name="Grupo 77" transform="translate(660.613 153.171)" clipPath="url(#clip-path-18)">
          <path
            id="Caminho_445"
            data-name="Caminho 445"
            d="M473.606,49.842c2.7,1.19,5.732,4.206,6.151,10.053s4.76,2.178,5.916,5.127,4.542,7.54.57,9.55-5.028-.586-7.911-.72-8.632,3.183-9.185,6.534-7.576,1.776-6.419,4.725-1.408,7.674,4.224,9.634,12.537,1.826,12.4,6.886-4.123,10.288-5.564,11.829,2.715,15.08-1.006,11.042-12.8-10.757-10.056-15.934,6.9-10.5,4.207-11.628S457.8,92.9,459.19,89.467s3.57-14.208,2.48-11.729-.52-4.055-3.067-4.055-2.967-8.512,1.24-12.315S470.1,48.267,473.606,49.842Z"
            transform="translate(-452.136 -55.242)"
            fill="#00c040"
          />
          <path
            id="Caminho_446"
            data-name="Caminho 446"
            d="M513.058,65.687c-.737-.184-10.626-4.407-11.38-.419s-.788,5.177-5.3,5.814-10.056-.469-10.358,2.5-4.777,4.624-1.676,5.228,4.844-.687,6.955-.8,2.581-2.161,3.034,1.056,0,5.462-1.458,5.261-4.777-4.206-7.073-2.463-6.05,0-6.62,1.776-2.1,9.148-1.039,11.192,1.676,6.333,4.643,6.333,5.129-2.128,4.626,1.676-1.676,7.422-.939,8.176,5.028-.285,8.045-6.886.721-10.623,2.765-12.633-2.464-5.965-1.022-5.965a7.609,7.609,0,0,1,6,3.8c1.559,2.748,2.414,1,3.989-1.508s6.4-4.222,7.257-6.987S516.326,66.475,513.058,65.687Z"
            transform="translate(-437.872 -45.855)"
            fill="#00c040"
          />
          <path
            id="Caminho_447"
            data-name="Caminho 447"
            d="M470.732,66.957c-1.559.989.2,1.927,2.028,2.228S473.263,65.3,470.732,66.957Z"
            transform="translate(-443.044 -43.824)"
            fill="#00c040"
          />
          <path
            id="Caminho_448"
            data-name="Caminho 448"
            d="M489.749,88.18c-.251,0-.318.7-1.676,2.748s-2.346,4.189-1.006,3.234S491.156,88.18,489.749,88.18Z"
            transform="translate(-432.027 -29.225)"
            fill="#00c040"
          />
          <path
            id="Caminho_449"
            data-name="Caminho 449"
            d="M462.256,72.91c.57.922,1.324,2.061,1.542,1.491s1.961,0,1.173-.536S461.569,71.854,462.256,72.91Z"
            transform="translate(-448.446 -39.791)"
            fill="#00c040"
          />
          <path
            id="Caminho_450"
            data-name="Caminho 450"
            d="M463.221,75.718c.436.134,2.481-.117,1.391-.486S462.786,75.568,463.221,75.718Z"
            transform="translate(-447.802 -38.042)"
            fill="#00c040"
          />
          <path
            id="Caminho_451"
            data-name="Caminho 451"
            d="M465.784,74.488c-.536.4-1.425.653-.419,1.357s1.508.1,1.358-.285S466.353,74.069,465.784,74.488Z"
            transform="translate(-446.576 -38.537)"
            fill="#00c040"
          />
          <path
            id="Caminho_452"
            data-name="Caminho 452"
            d="M462.87,79.462a1.675,1.675,0,0,0,.1,1.91c.352.385,1.173.352,1.123-.369s-3.52-1.223-3.52-1.223l.637,2.011.955,2.145.134,1.776s-1.576-.8-.184,1.81,2.765.972,2.765.972L468.452,80l-1.341-1.089s-11.531-2.681-4.659,3.351"
            transform="translate(-449.714 -35.888)"
            fill="#00c040"
          />
          <path
            id="Caminho_453"
            data-name="Caminho 453"
            d="M481.821,68.743c-.419-.385-.62,1.307-.687,2.228a6.072,6.072,0,0,0,.184,1.877S483.564,70.334,481.821,68.743Z"
            transform="translate(-435.613 -42.393)"
            fill="#00c040"
          />
          <path
            id="Caminho_454"
            data-name="Caminho 454"
            d="M483.65,73.109s2.447-.318,3.067,1.676,1.911,3.452,1.676,2.128-3.017-6.183-1.492-4.675,1.76,2.5,3.067,2.8,3.034,2.865,2.43.6a10.622,10.622,0,0,0-2.363-4.071l-2.263-.737-3.738.838-.285.938Z"
            transform="translate(-433.906 -40.945)"
            fill="#00c040"
          />
        </g>
        <path
          id="Caminho_459"
          data-name="Caminho 459"
          d="M450.37,115.309c10.308,20.29,34.425,31.566,55.309,24.228,23.883-8.378,35.85-38.352,27-62.764-4.944-13.655-15.084-24.529-28.375-28.785-1.676-.519-2.4,2.094-.8,2.614,21.788,6.953,34.945,33.711,27.889,56.766-7.341,23.993-30.4,36.978-52.862,29.271A45.818,45.818,0,0,1,452.67,114.07a1.307,1.307,0,1,0-2.3,1.24Z"
          transform="translate(204.076 96.75)"
          fill="#ffbe3b"
        />
        <path
          id="Caminho_460"
          data-name="Caminho 460"
          d="M470.87,89.317l-21.453,27.26c-1.073,1.357,1.089,2.865,2.145,1.508l21.453-27.26c1.073-1.357-1.089-2.865-2.145-1.525Z"
          transform="translate(203.369 124.35)"
          fill="#ffbe3b"
        />
        <ellipse
          id="Elipse_11"
          data-name="Elipse 11"
          cx="5.533"
          cy="17.178"
          rx="5.533"
          ry="17.178"
          transform="translate(636.294 235.518) rotate(-47.83)"
          fill="#db9a26"
        />
      </g>
      <path
        id="Caminho_461"
        data-name="Caminho 461"
        d="M358.65,177.284l3.35,7.255a1.274,1.274,0,0,0,.939.737l7.525,1.156a1.357,1.357,0,0,1,.687,2.262L365.7,194.34a1.458,1.458,0,0,0-.352,1.19l1.274,7.975a1.257,1.257,0,0,1-1.793,1.391l-6.7-3.77a1.19,1.19,0,0,0-1.156,0l-6.7,3.77a1.257,1.257,0,0,1-1.81-1.391l1.291-7.975a1.457,1.457,0,0,0-.352-1.19l-5.447-5.646a1.357,1.357,0,0,1,.687-2.262l7.525-1.156a1.274,1.274,0,0,0,.939-.737l3.352-7.255a1.207,1.207,0,0,1,2.2,0Z"
        transform="translate(132.015 183.66)"
        fill="#eac426"
      />
      <g id="Grupo_80" data-name="Grupo 80" transform="translate(475.591 360.239)" clipPath="url(#clip-path-19)">
        <path
          id="Caminho_462"
          data-name="Caminho 462"
          d="M358.02,176.584l3.352,7.255a1.274,1.274,0,0,0,.939.737l7.525,1.156a1.357,1.357,0,0,1,.687,2.262l-5.447,5.663a1.407,1.407,0,0,0-.352,1.173l1.291,7.975a1.257,1.257,0,0,1-1.81,1.391l-6.7-3.77a1.19,1.19,0,0,0-1.156,0l-6.7,3.77a1.257,1.257,0,0,1-1.81-1.391l1.291-7.975a1.407,1.407,0,0,0-.352-1.173l-5.447-5.663a1.357,1.357,0,0,1,.687-2.262l7.525-1.156a1.274,1.274,0,0,0,.939-.737l3.352-7.255a1.207,1.207,0,0,1,2.2,0Z"
          transform="translate(-344.002 -177.052)"
          fill="#ffda3e"
        />
        <g id="Grupo_78" data-name="Grupo 78" transform="translate(2.444 9.581)" opacity="0.44">
          <path
            id="Caminho_463"
            data-name="Caminho 463"
            d="M345.48,183.877l5.028-.787.7-.1c.452,0,.268-.754-.184-.687L346,183.09l-.7.117C344.843,183.206,345.027,183.96,345.48,183.877Z"
            transform="translate(-345.034 -182.298)"
            fill="#fff"
          />
        </g>
        <g id="Grupo_79" data-name="Grupo 79" transform="translate(10.426 2.103)" opacity="0.44">
          <path
            id="Caminho_464"
            data-name="Caminho 464"
            d="M350.372,183.876l2.2-4.809.318-.687c.184-.419-.419-.771-.6-.369l-2.145,4.825-.3.687c-.2.4.419.771.6.352Z"
            transform="translate(-349.796 -177.834)"
            fill="#fff"
          />
        </g>
      </g>
      <path
        id="Caminho_466"
        data-name="Caminho 466"
        d="M293.906,57.117l7.224,2.564a1.207,1.207,0,0,0,1.14-.2l6.067-4.876a1.24,1.24,0,0,1,1.994,1.056V63.77a1.357,1.357,0,0,0,.536,1.089L317.1,69.5a1.357,1.357,0,0,1-.352,2.346l-7.24,2.446a1.324,1.324,0,0,0-.821.888l-2.212,7.741a1.224,1.224,0,0,1-2.212.385l-4.425-6.585a1.19,1.19,0,0,0-1.022-.553l-7.609.134a1.324,1.324,0,0,1-1.022-2.111l4.508-6.518a1.357,1.357,0,0,0,.184-1.206l-2.531-7.674a1.29,1.29,0,0,1,1.559-1.676Z"
        transform="translate(95.746 101.091)"
        fill="#eac426"
      />
      <g id="Grupo_83" data-name="Grupo 83" transform="translate(385.67 155.438)" clipPath="url(#clip-path-20)">
        <path
          id="Caminho_467"
          data-name="Caminho 467"
          d="M293.039,57.142l7.207,2.564a1.174,1.174,0,0,0,1.14-.2l6.034-4.943a1.257,1.257,0,0,1,2.011,1.072v8.093a1.39,1.39,0,0,0,.536,1.106l6.235,4.641a1.357,1.357,0,0,1-.335,2.346l-7.257,2.446a1.341,1.341,0,0,0-.821.871l-2.212,7.741a1.207,1.207,0,0,1-2.2.385l-4.425-6.585a1.274,1.274,0,0,0-1.039-.553l-7.609.151a1.324,1.324,0,0,1-1.022-2.111l4.509-6.518a1.374,1.374,0,0,0,.184-1.223l-2.514-7.607a1.29,1.29,0,0,1,1.575-1.676Z"
          transform="translate(-290.533 -54.372)"
          fill="#ffda3e"
        />
        <g id="Grupo_81" data-name="Grupo 81" transform="translate(1.77 13.874)" opacity="0.44">
          <path
            id="Caminho_468"
            data-name="Caminho 468"
            d="M291.65,68.077l2.983-4.306.419-.62c.268-.385-.352-.737-.6-.369l-2.983,4.323-.419.62C290.779,68.094,291.4,68.463,291.65,68.077Z"
            transform="translate(-290.981 -62.627)"
            fill="#fff"
          />
        </g>
        <g id="Grupo_82" data-name="Grupo 82" transform="translate(3.524 5.148)" opacity="0.44">
          <path
            id="Caminho_469"
            data-name="Caminho 469"
            d="M294.646,63.419l-1.676-5.027-.235-.737c-.151-.419-.838-.235-.687.184l1.676,5.027.235.72c.134.436.821.251.687-.184Z"
            transform="translate(-292.027 -57.419)"
            fill="#fff"
          />
        </g>
      </g>
      <path
        id="Caminho_471"
        data-name="Caminho 471"
        d="M546.794,52.6l12.738,4.524a2.079,2.079,0,0,0,2.028-.352l10.71-8.6a2.2,2.2,0,0,1,3.52,1.893l-.1,14.292a2.446,2.446,0,0,0,.939,1.944l11.009,8.2a2.4,2.4,0,0,1-.587,4.138l-12.8,4.323a2.279,2.279,0,0,0-1.441,1.558l-3.922,13.672a2.145,2.145,0,0,1-3.888.67l-7.81-11.628a2.162,2.162,0,0,0-1.827-.972l-13.408.251a2.346,2.346,0,0,1-1.81-3.72l7.978-11.511a2.462,2.462,0,0,0,.318-2.161l-4.391-13.5A2.3,2.3,0,0,1,546.8,52.6Z"
        transform="translate(264.594 96.624)"
        fill="#eac426"
      />
      <g id="Grupo_86" data-name="Grupo 86" transform="translate(804.288 144.357)" clipPath="url(#clip-path-21)">
        <path
          id="Caminho_472"
          data-name="Caminho 472"
          d="M545.224,52.621l12.738,4.524a2.079,2.079,0,0,0,2.028-.352L570.7,48.18a2.2,2.2,0,0,1,3.52,1.893l-.1,14.292a2.412,2.412,0,0,0,.955,1.944L586.068,74.5a2.4,2.4,0,0,1-.587,4.139l-12.8,4.323A2.262,2.262,0,0,0,571.24,84.5l-3.922,13.689a2.146,2.146,0,0,1-3.888.67l-7.81-11.628a2.162,2.162,0,0,0-1.827-.972l-13.408.251a2.346,2.346,0,0,1-1.81-3.72l7.978-11.511a2.462,2.462,0,0,0,.318-2.161l-4.4-13.521a2.279,2.279,0,0,1,2.749-2.982Z"
          transform="translate(-540.755 -47.733)"
          fill="#ffda3e"
        />
        <g id="Grupo_84" data-name="Grupo 84" transform="translate(3.186 24.616)" opacity="0.44">
          <path
            id="Caminho_473"
            data-name="Caminho 473"
            d="M542.777,72.051l5.263-7.624.754-1.089c.453-.67-.637-1.29-1.089-.637l-5.246,7.624-.754,1.089C541.251,72.085,542.324,72.7,542.777,72.051Z"
            transform="translate(-541.595 -62.424)"
            fill="#fff"
          />
        </g>
        <g id="Grupo_85" data-name="Grupo 85" transform="translate(6.278 9.015)" opacity="0.44">
          <path
            id="Caminho_474"
            data-name="Caminho 474"
            d="M548.049,63.747l-2.95-8.93-.419-1.273c-.251-.754-1.458-.436-1.207.335l2.95,8.914.419,1.29C547.094,64.836,548.3,64.518,548.049,63.747Z"
            transform="translate(-543.44 -53.114)"
            fill="#fff"
          />
        </g>
      </g>
      <path
        id="Caminho_476"
        data-name="Caminho 476"
        d="M446.146,146.981l4.307,1.525a.721.721,0,0,0,.687-.117l3.62-2.915a.737.737,0,0,1,1.19.637v4.825a.8.8,0,0,0,.318.653l3.7,2.765a.8.8,0,0,1-.2,1.407l-4.324,1.458a.871.871,0,0,0-.486.519l-1.324,4.624a.721.721,0,0,1-1.307.235l-2.648-3.937a.687.687,0,0,0-.6-.318h-4.542a.8.8,0,0,1-.62-1.257l2.7-3.887a.821.821,0,0,0,.1-.737l-1.475-4.557a.771.771,0,0,1,.9-.921Z"
        transform="translate(199.752 162.542)"
        fill="#eac426"
      />
      <g id="Grupo_89" data-name="Grupo 89" transform="translate(643.528 307.858)" clipPath="url(#clip-path-22)">
        <path
          id="Caminho_477"
          data-name="Caminho 477"
          d="M445.616,146.981l4.307,1.525a.721.721,0,0,0,.687-.117l3.62-2.915a.737.737,0,0,1,1.19.637v4.842a.821.821,0,0,0,.319.653l3.7,2.765a.8.8,0,0,1-.2,1.391l-4.324,1.474a.754.754,0,0,0-.486.519l-1.324,4.624a.721.721,0,0,1-1.307.218l-2.648-3.921a.7.7,0,0,0-.6-.335h-4.542a.8.8,0,0,1-.62-1.257l2.7-3.887a.787.787,0,0,0,.1-.72l-1.475-4.574a.771.771,0,0,1,.9-.922Z"
          transform="translate(-444.135 -145.316)"
          fill="#ffda3e"
        />
        <g id="Grupo_87" data-name="Grupo 87" transform="translate(1.043 8.324)" opacity="0.44">
          <path
            id="Caminho_478"
            data-name="Caminho 478"
            d="M444.8,153.544l1.777-2.58.251-.369c.151-.235-.2-.436-.369-.218l-1.76,2.58-.268.369C444.284,153.544,444.652,153.762,444.8,153.544Z"
            transform="translate(-444.399 -150.284)"
            fill="#fff"
          />
        </g>
        <g id="Grupo_88" data-name="Grupo 88" transform="translate(2.159 3.053)" opacity="0.44">
          <path
            id="Caminho_479"
            data-name="Caminho 479"
            d="M446.632,150.726l-1.006-3.016-.134-.419c0-.268-.5-.151-.419.1l1.006,3.016.134.436C446.3,151.094,446.713,150.994,446.632,150.726Z"
            transform="translate(-445.064 -147.139)"
            fill="#fff"
          />
        </g>
      </g>
      <path
        id="Caminho_481"
        data-name="Caminho 481"
        d="M428.531,135.623l2.916,1.022a.419.419,0,0,0,.452-.084l2.447-1.96a.5.5,0,0,1,.8.436v3.351a.553.553,0,0,0,.218.436l2.514,1.877a.553.553,0,0,1-.134.938l-2.933.989a.486.486,0,0,0-.318.352l-.9,3.133a.486.486,0,0,1-.888.151l-1.776-2.647a.5.5,0,0,0-.419-.235h-3.067a.536.536,0,0,1-.419-.854l1.827-2.631a.553.553,0,0,0,0-.486l-1.006-3.1a.5.5,0,0,1,.687-.687Z"
        transform="translate(188.355 155.235)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_482"
        data-name="Caminho 482"
        d="M357.884,84.7l.469-1.424a.251.251,0,0,0,0-.218l-.821-1.139a.235.235,0,0,1,.168-.385h1.358a.184.184,0,0,0,.184-.1l.771-1.19a.218.218,0,0,1,.4,0l.419,1.391a.218.218,0,0,0,.151.151l1.29.4a.234.234,0,0,1,0,.419l-1.089.855a.235.235,0,0,0-.1.2V85.1a.218.218,0,0,1-.352.2l-1.089-.855a.252.252,0,0,0-.218,0l-1.274.486C358,85.069,357.834,84.952,357.884,84.7Z"
        transform="translate(141.412 118.495)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_483"
        data-name="Caminho 483"
        d="M245.295,42.531l.419-1.391a.335.335,0,0,0,0-.218l-.838-1.139a.251.251,0,0,1,.184-.385h1.358a.235.235,0,0,0,.184-.1l.771-1.19a.2.2,0,0,1,.386,0l.436,1.374a.2.2,0,0,0,.134.151l1.307.4a.235.235,0,0,1,0,.419l-1.089.855a.235.235,0,0,0-.1.2V42.95a.218.218,0,0,1-.352.2L247,42.3a.335.335,0,0,0-.218,0l-1.274.486a.218.218,0,0,1-.218-.251Z"
        transform="translate(65.265 90.025)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_484"
        data-name="Caminho 484"
        d="M413.514,75.581l.419-1.374a.251.251,0,0,0,0-.218l-.821-1.139a.235.235,0,0,1,.168-.385h1.358a.185.185,0,0,0,.184-.1l.771-1.19a.218.218,0,0,1,.4,0l.318,1.474a.218.218,0,0,0,.151.151l1.291.4a.235.235,0,0,1,0,.419l-1.089.855a.234.234,0,0,0-.1.2v1.441a.218.218,0,0,1-.352.2l-1.089-.854a.319.319,0,0,0-.218,0l-1.274.486C413.632,76,413.464,75.782,413.514,75.581Z"
        transform="translate(178.985 112.368)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_485"
        data-name="Caminho 485"
        d="M350.955,15.834l.419-1.374a.335.335,0,0,0,0-.218l-.838-1.139a.251.251,0,0,1,.184-.385h1.358a.235.235,0,0,0,.184-.1l.771-1.19a.2.2,0,0,1,.386,0l.436,1.374a.235.235,0,0,0,.134.168l1.307.4a.234.234,0,0,1,0,.419l-1.089.855a.235.235,0,0,0-.1.2v1.441a.218.218,0,0,1-.352.2l-1.089-.855a.252.252,0,0,0-.218,0l-1.274.486a.235.235,0,0,1-.218-.285Z"
        transform="translate(136.693 72.003)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_486"
        data-name="Caminho 486"
        d="M237.415,87.921l.419-1.374a.251.251,0,0,0,0-.218l-.821-1.139a.235.235,0,0,1,.168-.385h1.358a.184.184,0,0,0,.184-.1l.771-1.19a.218.218,0,0,1,.4,0l.419,1.391a.218.218,0,0,0,.151.151l1.291.4a.235.235,0,0,1,0,.419l-1.089.854a.234.234,0,0,0-.1.2v1.441a.218.218,0,0,1-.352.2l-1.089-.855a.252.252,0,0,0-.218,0l-1.274.486C237.532,88.289,237.364,88.122,237.415,87.921Z"
        transform="translate(59.939 120.704)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_487"
        data-name="Caminho 487"
        d="M320.836,115.07l.419-1.374a.335.335,0,0,0,0-.218l-.838-1.156a.235.235,0,0,1,.184-.369h1.358a.285.285,0,0,0,.184-.1l.771-1.206a.218.218,0,0,1,.386,0l.419,1.374a.218.218,0,0,0,.151.151l1.307.419a.251.251,0,0,1,0,.419l-1.106.855a.235.235,0,0,0-.084.184v1.458a.218.218,0,0,1-.352.2l-1.106-.855a.185.185,0,0,0-.2,0l-1.274.486a.235.235,0,0,1-.218-.268Z"
        transform="translate(116.331 139.044)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_488"
        data-name="Caminho 488"
        d="M203.125,20.3l.419-1.374a.335.335,0,0,0,0-.218l-.838-1.139a.251.251,0,0,1,.184-.385h1.358a.235.235,0,0,0,.184-.1l.771-1.19a.2.2,0,0,1,.385,0l.419,1.391a.335.335,0,0,0,.151.151l1.307.4a.235.235,0,0,1,0,.419l-1.106.855a.285.285,0,0,0,0,.2v1.441a.218.218,0,0,1-.352.2L204.9,20.1a.268.268,0,0,0-.2,0l-1.274.486a.218.218,0,0,1-.3-.285Z"
        transform="translate(36.758 75.022)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_489"
        data-name="Caminho 489"
        d="M357.9,60.281l.419-1.374a.251.251,0,0,0,0-.218L357.5,57.55a.235.235,0,0,1,.168-.385h1.358a.184.184,0,0,0,.184-.1l.771-1.19a.218.218,0,0,1,.4,0l.419,1.391a.218.218,0,0,0,.151.151l1.291.4a.235.235,0,0,1,0,.419l-1.089.855a.235.235,0,0,0-.1.2v1.441a.218.218,0,0,1-.352.2l-1.089-.854a.252.252,0,0,0-.218,0l-1.274.486C358.022,60.649,357.854,60.482,357.9,60.281Z"
        transform="translate(141.392 102.033)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_490"
        data-name="Caminho 490"
        d="M432.528,10.41l.419-1.374a.251.251,0,0,0,0-.218l-.855-1.089a.235.235,0,0,1,.168-.385h1.391a.184.184,0,0,0,.184-.1l.771-1.19a.218.218,0,0,1,.4,0l.419,1.374a.218.218,0,0,0,.151.168l1.29.4a.235.235,0,0,1,0,.419l-1.089.855a.235.235,0,0,0-.1.2v1.441a.218.218,0,0,1-.352.168l-1.089-.855a.252.252,0,0,0-.218,0l-1.274.486C432.645,10.779,432.478,10.6,432.528,10.41Z"
        transform="translate(191.816 68.379)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_491"
        data-name="Caminho 491"
        d="M486.734,134.831l.419-1.374a.251.251,0,0,0,0-.218l-.821-1.139a.235.235,0,0,1,.168-.385h1.358a.184.184,0,0,0,.184-.1l.771-1.19a.218.218,0,0,1,.4,0l.419,1.374a.218.218,0,0,0,.151.168l1.291.4a.235.235,0,0,1,0,.419l-1.089.854a.234.234,0,0,0-.1.2v1.441a.218.218,0,0,1-.352.2l-1.089-.855a.218.218,0,0,0-.2,0l-1.29.486C486.852,135.166,486.684,134.981,486.734,134.831Z"
        transform="translate(228.483 152.391)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_492"
        data-name="Caminho 492"
        d="M516.857,75.089l.419-1.374a.218.218,0,0,0,0-.218l-.821-1.156a.218.218,0,0,1,.168-.369H518a.218.218,0,0,0,.168-.1l.771-1.206a.235.235,0,0,1,.4,0l.419,1.374a.2.2,0,0,0,.151.151l1.307.419a.251.251,0,0,1,0,.419l-1.106.855a.184.184,0,0,0,0,.2v1.441a.218.218,0,0,1-.352.2l-1.106-.854a.184.184,0,0,0-.2,0l-1.291.486a.216.216,0,0,1-.3-.268Z"
        transform="translate(248.842 112.038)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_493"
        data-name="Caminho 493"
        d="M490.907,170.349l.419-1.374a.218.218,0,0,0,0-.218L490.5,167.6a.218.218,0,0,1,.168-.369h1.358a.235.235,0,0,0,.184-.1l.771-1.206a.235.235,0,0,1,.4,0l.419,1.374a.2.2,0,0,0,.151.151l1.29.419a.235.235,0,0,1,0,.419l-1.089.854a.218.218,0,0,0-.1.2v1.441a.218.218,0,0,1-.352.2l-1.089-.854a.184.184,0,0,0-.2,0l-1.291.486C491.024,170.718,490.857,170.534,490.907,170.349Z"
        transform="translate(231.3 176.387)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_494"
        data-name="Caminho 494"
        d="M424.186,189.6l.419-1.374a.317.317,0,0,0,0-.218l-.838-1.156a.235.235,0,0,1,.184-.369h1.358a.251.251,0,0,0,.184-.1l.771-1.206a.235.235,0,0,1,.4,0l.419,1.374a.184.184,0,0,0,.134.151l1.307.419a.235.235,0,0,1,0,.419l-1.089.855a.218.218,0,0,0-.1.184v1.458a.218.218,0,0,1-.352.2l-1.089-.855a.218.218,0,0,0-.218,0l-1.274.486a.235.235,0,0,1-.218-.268Z"
        transform="translate(186.197 189.39)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_495"
        data-name="Caminho 495"
        d="M460.324,183.184l.419-1.374a.268.268,0,0,0,0-.218l-.821-1.139a.235.235,0,0,1,.184-.385h1.358a.235.235,0,0,0,.184-.1l.771-1.19a.2.2,0,0,1,.386,0l.419,1.391a.336.336,0,0,0,.151.151l1.307.4a.251.251,0,0,1,0,.419l-1.106.855a.284.284,0,0,0,0,.2v1.441a.218.218,0,0,1-.352.2l-1.106-.855a.218.218,0,0,0-.2,0l-1.274.486a.218.218,0,0,1-.318-.285Z"
        transform="translate(210.63 185.048)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_496"
        data-name="Caminho 496"
        d="M446.415,52.4l.419-1.374a.2.2,0,0,0,0-.218l-.821-1.156a.235.235,0,0,1,.168-.385h1.374a.184.184,0,0,0,.168-.1l.771-1.19a.218.218,0,0,1,.4,0l.419,1.374a.234.234,0,0,0,.151.151l1.307.419a.251.251,0,0,1,0,.419l-1.106.838a.184.184,0,0,0-.084.2v1.441a.218.218,0,0,1-.2.318l-1.106-.854a.218.218,0,0,0-.2,0l-1.29.469a.255.255,0,1,1-.369-.352Z"
        transform="translate(201.226 96.696)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_497"
        data-name="Caminho 497"
        d="M538.635,8.951l.419-1.374a.218.218,0,0,0,0-.218l-.821-1.139a.234.234,0,0,1,.184-.385h1.358a.234.234,0,0,0,.184-.1l.754-1.19a.218.218,0,0,1,.4,0l.419,1.374a.251.251,0,0,0,.151.168l1.307.4a.251.251,0,0,1,0,.419l-1.106.855a.284.284,0,0,0,0,.2V9.4a.218.218,0,0,1-.352.2l-1.123-.855a.218.218,0,0,0-.2,0l-1.291.486C538.752,9.319,538.584,9.135,538.635,8.951Z"
        transform="translate(263.568 67.359)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_498"
        data-name="Caminho 498"
        d="M326.4,56.83l.419-1.374a.3.3,0,0,0,0-.218l-.838-1.156a.235.235,0,0,1,.184-.369h1.358a.251.251,0,0,0,.184-.117l.771-1.19a.218.218,0,0,1,.385,0l.419,1.374a.268.268,0,0,0,.151.151l1.307.419a.235.235,0,0,1,0,.419l-1.106.855a.234.234,0,0,0-.084.184v1.458a.218.218,0,0,1-.352.184l-1.106-.838a.184.184,0,0,0-.2,0l-1.274.486a.235.235,0,0,1-.218-.268Z"
        transform="translate(120.089 99.703)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_499"
        data-name="Caminho 499"
        d="M269.854,14.381l.419-1.374a.251.251,0,0,0,0-.218l-.821-1.139a.235.235,0,0,1,.168-.385h1.358a.235.235,0,0,0,.184-.1l.771-1.19a.218.218,0,0,1,.4,0l.419,1.374a.184.184,0,0,0,.151.151l1.29.419a.235.235,0,0,1,0,.419l-1.089.855a.235.235,0,0,0-.1.2v1.441a.218.218,0,0,1-.352.2l-1.089-.854a.251.251,0,0,0-.218,0l-1.274.486C269.972,14.749,269.8,14.565,269.854,14.381Z"
        transform="translate(81.869 71.027)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_500"
        data-name="Caminho 500"
        d="M381.534,87.924l.419-1.374a.218.218,0,0,0,0-.218l-.821-1.139a.235.235,0,0,1,.184-.385h1.307a.235.235,0,0,0,.184-.1l.771-1.19a.2.2,0,0,1,.386,0l.419,1.391a.268.268,0,0,0,.151.151l1.307.4a.251.251,0,0,1,0,.419l-1.106.855a.285.285,0,0,0-.084.2v1.441a.218.218,0,0,1-.352.2l-1.106-.855a.218.218,0,0,0-.2,0l-1.29.486C381.652,88.293,381.484,88.126,381.534,87.924Z"
        transform="translate(157.366 120.7)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_501"
        data-name="Caminho 501"
        d="M549.469,95.1l2.916,1.039a.486.486,0,0,0,.453,0l2.464-1.96a.5.5,0,0,1,.8.419V97.95a.569.569,0,0,0,.218.452l2.514,1.877a.536.536,0,0,1-.134.938l-2.933.989a.52.52,0,0,0-.318.352l-.905,3.133a.486.486,0,0,1-.888.151l-1.777-2.664a.52.52,0,0,0-.419-.218H548.4a.536.536,0,0,1-.419-.838l1.777-2.748a.586.586,0,0,0,0-.5l-1-3.083a.536.536,0,0,1,.721-.687Z"
        transform="translate(270.13 127.931)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_502"
        data-name="Caminho 502"
        d="M512.864,33.909l2.916,1.039a.486.486,0,0,0,.453,0l2.464-1.977a.5.5,0,0,1,.8.436v3.351a.536.536,0,0,0,.218.436l2.514,1.877a.553.553,0,0,1-.134.955l-2.933.989a.52.52,0,0,0-.318.352l-.905,3.116a.486.486,0,0,1-.888.168l-1.777-2.664a.452.452,0,0,0-.419-.218h-3.067a.536.536,0,0,1-.419-.855l1.794-2.8a.553.553,0,0,0,0-.486l-1.006-3.083a.536.536,0,0,1,.7-.637Z"
        transform="translate(245.377 86.583)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_503"
        data-name="Caminho 503"
        d="M321.818,72.279l1.86-1.843a.453.453,0,0,0,.134-.385l-.386-2.681a.436.436,0,0,1,.62-.452l2.229,1.307a.352.352,0,0,0,.385,0l2.28-1.139a.419.419,0,0,1,.6.486l-.486,2.647a.452.452,0,0,0,.117.4l1.777,1.927a.452.452,0,0,1-.251.754l-2.514.318a.419.419,0,0,0-.319.235l-1.173,2.413a.419.419,0,0,1-.754,0l-1.089-2.48a.419.419,0,0,0-.3-.251l-2.514-.436a.469.469,0,0,1-.218-.821Z"
        transform="translate(117.193 109.554)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_504"
        data-name="Caminho 504"
        d="M408.938,62.918l1.844-1.86a.452.452,0,0,0,.134-.385l-.369-2.664a.419.419,0,0,1,.6-.469l2.229,1.324a.4.4,0,0,0,.385,0l2.279-1.206a.419.419,0,0,1,.587.486l-.486,2.647a.486.486,0,0,0,.117.4l1.777,1.927a.452.452,0,0,1-.251.754l-2.514.335a.419.419,0,0,0-.318.235l-1.173,2.4a.4.4,0,0,1-.737,0L411.953,64.4a.385.385,0,0,0-.3-.251l-2.514-.452a.452.452,0,0,1-.2-.771Z"
        transform="translate(176.103 103.215)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_505"
        data-name="Caminho 505"
        d="M371.4,170.015l1.86-1.843a.419.419,0,0,0,.117-.385l-.369-2.681a.419.419,0,0,1,.62-.452l2.212,1.307a.4.4,0,0,0,.386,0l2.279-1.206a.419.419,0,0,1,.587.469l-.486,2.664a.469.469,0,0,0,.117.385l1.776,1.944a.436.436,0,0,1-.235.737l-2.531.335a.419.419,0,0,0-.318.235l-1.173,2.4a.4.4,0,0,1-.737,0l-1.073-2.446a.486.486,0,0,0-.318-.251l-2.5-.436a.452.452,0,0,1-.218-.771Z"
        transform="translate(150.727 175.565)"
        fill="#fff"
        opacity="0.54"
      />
      <path
        id="Caminho_506"
        data-name="Caminho 506"
        d="M308.962,9.271l4.307,1.525a.7.7,0,0,0,.67-.117l3.62-2.915a.737.737,0,0,1,1.19.637v4.825a.838.838,0,0,0,.318.67l3.721,2.748a.8.8,0,0,1-.2,1.391l-4.324,1.458a.788.788,0,0,0-.486.536l-1.324,4.624a.721.721,0,0,1-1.307.218L312.5,20.949a.721.721,0,0,0-.62-.335h-4.525a.787.787,0,0,1-.62-1.257l2.7-3.887a.787.787,0,0,0,.1-.72l-1.475-4.574a.771.771,0,0,1,.9-.9Z"
        transform="translate(107.003 69.518)"
        fill="#eac426"
      />
      <g id="Grupo_92" data-name="Grupo 92" transform="translate(413.581 77.125)" clipPath="url(#clip-path-23)">
        <path
          id="Caminho_507"
          data-name="Caminho 507"
          d="M308.464,9.271l4.307,1.525a.7.7,0,0,0,.67-.117l3.62-2.915a.737.737,0,0,1,1.19.637v4.842a.821.821,0,0,0,.318.653l3.687,2.748a.821.821,0,0,1-.2,1.407l-4.324,1.458a.72.72,0,0,0-.486.519l-1.324,4.624a.721.721,0,0,1-1.307.235l-2.648-3.937a.721.721,0,0,0-.62-.335l-4.525.1a.8.8,0,0,1-.62-1.273l2.7-3.887a.787.787,0,0,0,.1-.72l-1.475-4.574a.771.771,0,0,1,.939-.989Z"
          transform="translate(-306.934 -7.606)"
          fill="#ffda3e"
        />
        <g id="Grupo_90" data-name="Grupo 90" transform="translate(1.092 8.36)" opacity="0.44">
          <path
            id="Caminho_508"
            data-name="Caminho 508"
            d="M307.633,15.82l1.777-2.547.251-.369c.151-.218-.218-.436-.369-.218l-1.777,2.58-.251.369c-.151.235.218.436.369.218Z"
            transform="translate(-307.229 -12.596)"
            fill="#fff"
          />
        </g>
        <g id="Grupo_91" data-name="Grupo 91" transform="translate(2.138 3.062)" opacity="0.44">
          <path
            id="Caminho_509"
            data-name="Caminho 509"
            d="M309.407,13.03l-.989-3.016-.151-.436c-.084-.251-.486-.151-.4.117l.989,3,.151.436c0,.268.486.151.4-.1Z"
            transform="translate(-307.853 -9.434)"
            fill="#fff"
          />
        </g>
      </g>
      <path
        id="Caminho_511"
        data-name="Caminho 511"
        d="M535.132,183.5l4.307,1.525a.7.7,0,0,0,.67-.117l3.62-2.915a.737.737,0,0,1,1.19.637v4.842a.821.821,0,0,0,.318.653l3.721,2.765a.821.821,0,0,1-.2,1.407l-4.324,1.458a.72.72,0,0,0-.486.519l-1.324,4.624a.721.721,0,0,1-1.307.218l-2.648-3.921a.721.721,0,0,0-.62-.335h-4.525a.787.787,0,0,1-.62-1.257l2.7-3.887a.787.787,0,0,0,.1-.72l-1.475-4.574a.771.771,0,0,1,.905-.922Z"
        transform="translate(259.898 187.211)"
        fill="#eac426"
      />
      <g id="Grupo_95" data-name="Grupo 95" transform="translate(792.645 369.048)" clipPath="url(#clip-path-24)">
        <path
          id="Caminho_512"
          data-name="Caminho 512"
          d="M534.6,183.494l4.307,1.525a.654.654,0,0,0,.67-.117l3.62-2.9a.737.737,0,0,1,1.19.637v4.825a.8.8,0,0,0,.318.653l3.721,2.765a.821.821,0,0,1-.2,1.407l-4.324,1.458a.754.754,0,0,0-.486.519l-1.324,4.624a.721.721,0,0,1-1.307.235l-2.648-3.937a.754.754,0,0,0-.62-.335l-4.525.1a.8.8,0,0,1-.62-1.273l2.7-3.887a.787.787,0,0,0,.1-.72L533.7,184.5a.771.771,0,0,1,.905-1Z"
          transform="translate(-533.104 -181.83)"
          fill="#ffda3e"
        />
        <g id="Grupo_93" data-name="Grupo 93" transform="translate(1.058 8.333)" opacity="0.44">
          <path
            id="Caminho_513"
            data-name="Caminho 513"
            d="M533.783,190.061l1.777-2.58.251-.369c.151-.218-.218-.436-.369-.2l-1.777,2.564-.252.369c-.151.235.218.436.369.218Z"
            transform="translate(-533.379 -186.81)"
            fill="#fff"
          />
        </g>
        <g id="Grupo_94" data-name="Grupo 94" transform="translate(2.106 3.062)" opacity="0.44">
          <path
            id="Caminho_514"
            data-name="Caminho 514"
            d="M535.556,187.26l-.989-3.016-.151-.436c0-.251-.486-.151-.4.117l.989,3.016.151.419C535.154,187.628,535.64,187.511,535.556,187.26Z"
            transform="translate(-534.004 -183.664)"
            fill="#fff"
          />
        </g>
      </g>
      <path
        id="Caminho_516"
        data-name="Caminho 516"
        d="M338.139,262.2l3.184-2.061a.7.7,0,0,0,.3-.519l.2-3.971a.6.6,0,0,1,1.006-.452l2.832,2.58a.6.6,0,0,0,.57.151l3.6-1.022a.637.637,0,0,1,.721.888l-1.441,3.669a.77.77,0,0,0,0,.6l2.028,3.351a.653.653,0,0,1-.57,1.005l-3.721-.318a.57.57,0,0,0-.52.235l-2.363,3.083a.6.6,0,0,1-1.073-.268l-.855-3.87a.687.687,0,0,0-.385-.452l-3.486-1.441a.67.67,0,0,1-.033-1.19Z"
        transform="translate(128.106 236.66)"
        fill="#eac426"
      />
      <g id="Grupo_98" data-name="Grupo 98" transform="translate(465.9 491.698)" clipPath="url(#clip-path-25)">
        <path
          id="Caminho_517"
          data-name="Caminho 517"
          d="M337.884,262.562l3.184-2.061a.737.737,0,0,0,.3-.519l.2-3.971a.6.6,0,0,1,1.006-.469l2.832,2.6a.654.654,0,0,0,.57.134l3.6-1.005a.62.62,0,0,1,.721.871l-1.441,3.669a.687.687,0,0,0,0,.6l2.028,3.351a.654.654,0,0,1-.57,1.005l-3.62-.3a.587.587,0,0,0-.52.251l-2.413,3.1a.6.6,0,0,1-1.073-.268l-.872-3.87a.57.57,0,0,0-.369-.452l-3.486-1.441a.67.67,0,0,1-.084-1.223Z"
          transform="translate(-337.975 -254.802)"
          fill="#ffda3e"
        />
        <g id="Grupo_96" data-name="Grupo 96" transform="translate(4.753 9.989)" opacity="0.44">
          <path
            id="Caminho_518"
            data-name="Caminho 518"
            d="M341.619,264.1l-.57-2.564-.084-.369a.168.168,0,0,0-.335,0l.57,2.563v.369a.168.168,0,0,0,.335,0Z"
            transform="translate(-340.63 -261)"
            fill="#fff"
          />
        </g>
        <g id="Grupo_97" data-name="Grupo 97" transform="translate(1.109 7.887)" opacity="0.44">
          <path
            id="Caminho_519"
            data-name="Caminho 519"
            d="M341.312,260.825l-2.3-.938-.335-.134a.175.175,0,1,0-.1.335l2.313.938.335.134c.084,0,.184,0,.218-.134a.2.2,0,0,0-.134-.2Z"
            transform="translate(-338.456 -259.746)"
            fill="#fff"
          />
        </g>
      </g>
      <path
        id="Caminho_521"
        data-name="Caminho 521"
        d="M506.823,301.692l3.2-2.061a.7.7,0,0,0,.285-.519l.218-3.988a.6.6,0,0,1,1.006-.452l2.833,2.6a.6.6,0,0,0,.553.134l3.62-1.005a.637.637,0,0,1,.721.871l-1.441,3.669a.636.636,0,0,0,0,.6l2.011,3.351a.653.653,0,0,1-.553,1l-3.738-.335a.62.62,0,0,0-.52.251l-2.363,3.083a.6.6,0,0,1-1.073-.268l-.855-3.87a.67.67,0,0,0-.369-.469l-3.486-1.424a.687.687,0,0,1-.05-1.173Z"
        transform="translate(242.166 263.328)"
        fill="#eac426"
      />
      <g id="Grupo_101" data-name="Grupo 101" transform="translate(748.684 557.847)" clipPath="url(#clip-path-26)">
        <path
          id="Caminho_522"
          data-name="Caminho 522"
          d="M506.544,302.052l3.2-2.078a.653.653,0,0,0,.285-.519l.218-3.971a.6.6,0,0,1,1.006-.452l2.832,2.6a.6.6,0,0,0,.553.134l3.62-1.022a.654.654,0,0,1,.721.888l-1.441,3.669a.636.636,0,0,0,0,.6l2.011,3.351a.637.637,0,0,1-.553.989l-3.738-.318a.587.587,0,0,0-.52.235l-2.363,3.083a.6.6,0,0,1-1.073-.251l-.855-3.887a.67.67,0,0,0-.369-.452l-3.486-1.441a.67.67,0,0,1-.05-1.156Z"
          transform="translate(-506.708 -294.275)"
          fill="#ffda3e"
        />
        <g id="Grupo_99" data-name="Grupo 99" transform="translate(4.678 10.035)" opacity="0.44">
          <path
            id="Caminho_523"
            data-name="Caminho 523"
            d="M510.316,303.561,509.73,301l-.084-.369c0-.218-.369-.117-.335.1l.587,2.564v.369c0,.218.386.117.335-.1Z"
            transform="translate(-509.309 -300.507)"
            fill="#fff"
          />
        </g>
        <g id="Grupo_100" data-name="Grupo 100" transform="translate(1.054 7.89)" opacity="0.44">
          <path
            id="Caminho_524"
            data-name="Caminho 524"
            d="M510,300.3l-2.313-.938-.335-.134a.168.168,0,0,0-.2.117.184.184,0,0,0,.117.218l2.3.938.335.134a.167.167,0,0,0,.218-.117A.184.184,0,0,0,510,300.3Z"
            transform="translate(-507.147 -299.227)"
            fill="#fff"
          />
        </g>
      </g>
      <g id="transferidor">
        <path
          id="Caminho_526"
          data-name="Caminho 526"
          d="M461.55,195.888a27.81,27.81,0,0,0,36.487-10.556,32.162,32.162,0,0,0-4.793-39.927l1.911-3.049a.737.737,0,0,0-.184-.972l-5.028-3.619a.654.654,0,0,0-.922.2l-35.2,56.146a.737.737,0,0,0,.184.972l5.028,3.6a.62.62,0,0,0,.905-.184Zm30.956-14.476a21.456,21.456,0,0,1-27.218,8.528l24.2-38.537a24.775,24.775,0,0,1,3.017,30.008Z"
          transform="translate(206.471 157.366)"
          fill="#ff3474"
        />
        <path
          id="Caminho_527"
          data-name="Caminho 527"
          d="M462.2,195.528a27.81,27.81,0,0,0,36.487-10.556,32.129,32.129,0,0,0-4.793-39.927L495.719,142a.737.737,0,0,0-.184-.972l-5.028-3.619a.654.654,0,0,0-.922.2l-35.2,56.146a.72.72,0,0,0,.168.972l5.028,3.6a.637.637,0,0,0,.922-.184Zm30.956-14.476a21.423,21.423,0,0,1-27.2,8.528l24.2-38.537a24.775,24.775,0,0,1,2.967,30.008Z"
          transform="translate(206.845 157.123)"
          fill="#fc4989"
        />
        <path
          id="Caminho_528"
          data-name="Caminho 528"
          d="M461.55,195.888a27.81,27.81,0,0,0,36.487-10.556,32.162,32.162,0,0,0-4.793-39.927l1.911-3.049a.737.737,0,0,0-.184-.972l-5.028-3.619a.654.654,0,0,0-.922.2l-35.2,56.146a.737.737,0,0,0,.184.972l5.028,3.6a.62.62,0,0,0,.905-.184Zm30.956-14.476a21.456,21.456,0,0,1-27.218,8.528l24.2-38.537a24.775,24.775,0,0,1,3.017,30.008Z"
          transform="translate(206.471 157.366)"
          fill="#ff3474"
        />
      </g>
      <path
        id="esquadro"
        d="M450.987,41.407l-5.9,74.007L403.07,37ZM439.825,90.348l3.352-41.988L415.992,45.9Z"
        transform="translate(172.234 89.374)"
        fill="#00c3e6"
      />
      <g id="livro-grande">
        <path
          id="Caminho_530"
          data-name="Caminho 530"
          d="M310.886,154.156,302.07,138.44l16.911,8.88-8.1,6.836"
          transform="translate(140.544 50.028)"
          fill="#ffbd00"
        />
        <path
          id="Caminho_531"
          data-name="Caminho 531"
          d="M318.79,92.563s13.911.838,19.492,17.157l40.342-44.8.888-9.952L365.719,44.61l-19.392,6.7L330,58.785Z"
          transform="translate(115.259 94.514)"
          fill="#fff"
        />
        <path
          id="Caminho_532"
          data-name="Caminho 532"
          d="M290.83,58.934,328.607,13.7s7.928-7.7,16.643-.39,48.1,59.631,48.1,59.631-13.241-6.9-19.492-1.676S335.077,118,335.077,118Z"
          transform="translate(96.358 71.243)"
          fill="#ffbc1b"
        />
        <g id="Grupo_103" data-name="Grupo 103" transform="translate(387.188 81.403)" clipPath="url(#clip-path-27)">
          <path
            id="Caminho_533"
            data-name="Caminho 533"
            d="M314.212,24.044l55.811,68.444,23.129-14.493L361.743,28.6,326.9,7.037s-21.4-36.543-12.687,17.006"
            transform="translate(-276.434 -20.492)"
            fill="#0090ce"
          />
        </g>
        <path
          id="Caminho_535"
          data-name="Caminho 535"
          d="M319.421,96l18.8-22.67,13.09-15.783c2.246-2.7,4.743-5.311,6.788-8.176-.184.268-1.056.536-.084.2s1.793-.871,2.765-1.156A15.424,15.424,0,0,1,372,49.456c3.671,1.776,7.844,5.027,8.028,9.8a11.324,11.324,0,0,1-2.112,6.384c-.369.6-.218.352-.084.184-.251.3-.52.57-.788.855l-3.687,4.1q-6.3,7.02-12.637,14.041-11.52,12.874-23.03,25.759c-1.542,1.676.654,4.591,2.212,2.882l21.57-23.96L375.3,74.136a80.607,80.607,0,0,0,5.514-6.183,13.518,13.518,0,0,0,.151-15.984,19.847,19.847,0,0,0-22.14-6.7c-2.8,1.022-4.039,2.664-5.95,5.027L340.791,64.871,316.555,93.857c-1.475,1.793,1.391,3.921,2.866,2.145Z"
          transform="translate(113.471 94.242)"
          fill="#ed930f"
        />
        <path
          id="Caminho_536"
          data-name="Caminho 536"
          d="M308.272,101.356l-18.621,19.62-5.732-6.183,18.62-19.6Z"
          transform="translate(128.274 20.812)"
          fill="#0090ce"
        />
      </g>
      <g id="livro-pequeno">
        <path
          id="Caminho_537"
          data-name="Caminho 537"
          d="M410.018,231.606l-5.648,5.78,1.978-8.9,3.671,3.116"
          transform="translate(209.7 110.857)"
          fill="#ffbd00"
        />
        <path
          id="Caminho_538"
          data-name="Caminho 538"
          d="M436.4,182.2a11.728,11.728,0,0,1,5.145-10.991L418.737,156.73l-4.24.6-2.916,7.59,4.777,8.478,4.793,6.953Z"
          transform="translate(177.987 170.252)"
          fill="#fff"
        />
        <path
          id="Caminho_539"
          data-name="Caminho 539"
          d="M425.258,208.753l-22.727-13.22a6.466,6.466,0,0,1-1.877-7.825C402.8,182.85,420.6,158.84,420.6,158.84s-1.525,6.953,1.274,9.4,23.464,13.521,23.464,13.521Z"
          transform="translate(170.191 171.677)"
          fill="#ffbc1b"
        />
        <g id="Grupo_104" data-name="Grupo 104" transform="translate(570.24 330.517)" clipPath="url(#clip-path-28)">
          <path
            id="Caminho_540"
            data-name="Caminho 540"
            d="M406.482,198.019l22.827-33.41-8.38-9.45-17.364,19.922-5.43,18.682s-13.039,13.873,8.38,4.256"
            transform="translate(-403.999 -161.326)"
            fill="#0090ce"
          />
        </g>
        <path
          id="Caminho_542"
          data-name="Caminho 542"
          d="M437.972,182.869l-11.4-6.568-7.911-4.574c-1.358-.788-2.715-1.676-4.123-2.379.134,0,.335.452.1,0s-.553-.771-.771-1.19a8.476,8.476,0,0,1-.721-5.412c.369-1.927,1.341-4.239,3.268-4.809a4.493,4.493,0,0,1,2.883.3c.3.117.168.084.084,0l.452.285,2.078,1.324,7.157,4.524,13.106,8.378c.872.553,1.844-.787.972-1.34l-12.2-7.758-7.827-5.027a36.432,36.432,0,0,0-3.151-1.96,5.264,5.264,0,0,0-6.7,1.558,10.553,10.553,0,0,0-.536,11.159,6.066,6.066,0,0,0,2.682,2.312l7.307,4.222,14.632,8.378c.9.519,1.492-1.056.6-1.558Z"
          transform="translate(177.858 169.798)"
          fill="#ed930f"
        />
        <path
          id="Caminho_543"
          data-name="Caminho 543"
          d="M389.054,239.58l10.09,6.786-1.995,3.351-10.09-6.8Z"
          transform="translate(197.998 118.348)"
          fill="#0090ce"
        />
      </g>
      <path
        id="Caminho_544"
        data-name="Caminho 544"
        d="M239.239-30.688h0a8.279,8.279,0,0,1,3-2.262l6-2.614a7.964,7.964,0,0,1,3.1-.637h1.006a8.212,8.212,0,0,1,7.626,5.563c3.771,10.438,11.447,34.231,13.408,59.8,0,0-1.911,20.843-3.352,20.592S260.809,33.5,260.809,33.5,248.138-2.355,238.25-20.551a9.146,9.146,0,0,1,.989-10.137Z"
        transform="translate(60.076 39.927)"
        fill="#4eb7f2"
      />
      <g id="Grupo_105" data-name="Grupo 105" transform="translate(297.235 3.727)" clipPath="url(#clip-path-29)">
        <path
          id="Caminho_545"
          data-name="Caminho 545"
          d="M240.939-4.909c1.24,2.161-6.8-4.507,31.727-19.8S242.314-44.82,242.314-44.82s-19.928,2.865-4.207,39.42"
          transform="translate(-240.603 30.377)"
          fill="#ea344a"
        />
      </g>
      <path
        id="Caminho_547"
        data-name="Caminho 547"
        d="M252.466,7.129s-4.793,2.848-2.581,13.019,15.185,12.767,10.777,24.58c0,0,28.207-28.835,4.425-41.888Z"
        transform="translate(68.284 66.298)"
        fill="#ff4d29"
      />
      <path
        id="Caminho_548"
        data-name="Caminho 548"
        d="M259.766,80.466a1.559,1.559,0,1,1-1.542-1.675A1.676,1.676,0,0,1,259.766,80.466Z"
        transform="translate(73.253 117.603)"
        fill="#ffda3e"
      />
      <ellipse
        id="Elipse_12"
        data-name="Elipse 12"
        cx="0.771"
        cy="0.821"
        rx="0.771"
        ry="0.821"
        transform="translate(232.827 146.815)"
        fill="#ffda3e"
      />
      <ellipse
        id="Elipse_13"
        data-name="Elipse 13"
        cx="0.385"
        cy="0.419"
        rx="0.385"
        ry="0.419"
        transform="translate(211.86 88.909)"
        fill="#ffda3e"
      />
      <ellipse
        id="Elipse_14"
        data-name="Elipse 14"
        cx="1.944"
        cy="2.061"
        rx="1.944"
        ry="2.061"
        transform="translate(343.109 142.676)"
        fill="#ffda3e"
      />
      <ellipse
        id="Elipse_15"
        data-name="Elipse 15"
        cx="1.173"
        cy="1.24"
        rx="1.173"
        ry="1.24"
        transform="translate(294.186 83.95)"
        fill="#ffda3e"
      />
      <path
        id="Caminho_549"
        data-name="Caminho 549"
        d="M328.816,119.946a1.559,1.559,0,1,1-1.542-1.675A1.676,1.676,0,0,1,328.816,119.946Z"
        transform="translate(119.932 144.272)"
        fill="#ffda3e"
      />
      <ellipse
        id="Elipse_16"
        data-name="Elipse 16"
        cx="1.559"
        cy="1.659"
        rx="1.559"
        ry="1.659"
        transform="translate(548.169 146.798)"
        fill="#ffda3e"
      />
      <ellipse
        id="Elipse_17"
        data-name="Elipse 17"
        cx="1.944"
        cy="2.061"
        rx="1.944"
        ry="2.061"
        transform="translate(413.015 212.964)"
        fill="#ffda3e"
      />
      <path
        id="Caminho_550"
        data-name="Caminho 550"
        d="M399.86,150.37Z"
        transform="translate(170.064 165.956)"
        fill="#ffda3e"
      />
      <path
        id="Caminho_551"
        data-name="Caminho 551"
        d="M450.4,12.67a2.33,2.33,0,1,1-2.33-2.48,2.43,2.43,0,0,1,2.33,2.48Z"
        transform="translate(201.081 71.263)"
        fill="#ffda3e"
      />
      <ellipse
        id="Elipse_18"
        data-name="Elipse 18"
        cx="1.944"
        cy="2.061"
        rx="1.944"
        ry="2.061"
        transform="translate(611.858 232.818)"
        fill="#ffda3e"
      />
      <path
        id="Caminho_552"
        data-name="Caminho 552"
        d="M535.371,50.041a3.117,3.117,0,1,1-3.1-3.351,3.217,3.217,0,0,1,3.1,3.351Z"
        transform="translate(257.463 95.919)"
        fill="#ffda3e"
      />
      <ellipse
        id="Elipse_19"
        data-name="Elipse 19"
        cx="1.944"
        cy="2.061"
        rx="1.944"
        ry="2.061"
        transform="translate(810.683 265.893)"
        fill="#ffda3e"
      />
      <ellipse
        id="Elipse_20"
        data-name="Elipse 20"
        cx="1.173"
        cy="1.24"
        rx="1.173"
        ry="1.24"
        transform="translate(584.656 267.535)"
        fill="#ffda3e"
      />
      <path
        id="Caminho_553"
        data-name="Caminho 553"
        d="M513.724,102.693A1.559,1.559,0,1,1,513,101.21a1.559,1.559,0,0,1,.724,1.483Z"
        transform="translate(244.936 132.588)"
        fill="#ffda3e"
      />
      <path
        id="Caminho_554"
        data-name="Caminho 554"
        d="M494.416,181.22a1.173,1.173,0,1,1-1.173-1.24,1.206,1.206,0,0,1,1.173,1.24Z"
        transform="translate(232.4 185.957)"
        fill="#ffda3e"
      />
      {/* <g id="logo-seduc-branca" transform="translate(613.802 545.874)">
        <path
          id="Caminho_681"
          data-name="Caminho 681"
          d="M1003.2,175.035v.752h-2.445v-2.945h2.391v.752h-1.416v.345h1.243v.713h-1.243v.387Zm-1.059-2.433h-.63l.555-.673h.9Z"
          transform="translate(-957 -153.456)"
          fill="#fff"
        />
        <path
          id="Caminho_682"
          data-name="Caminho 682"
          d="M1041.492,181.529c0,.68-.521,1.109-1.34,1.109h-.43v.723h-.992V180.42h1.426c.818,0,1.339.43,1.339,1.109m-1,0c0-.215-.13-.345-.4-.345h-.366v.7h.361c.273,0,.4-.131.4-.345"
          transform="translate(-990.899 -161.034)"
          fill="#fff"
        />
        <path
          id="Caminho_683"
          data-name="Caminho 683"
          d="M1066.72,181.328a1.665,1.665,0,1,1,1.66,1.538,1.552,1.552,0,0,1-1.66-1.538m2.32,0a.664.664,0,1,0-.66.735.669.669,0,0,0,.66-.735"
          transform="translate(-1015.882 -160.471)"
          fill="#fff"
        />
        <path
          id="Caminho_684"
          data-name="Caminho 684"
          d="M1102.257,182.622h-.268v.739H1101V180.42h1.415c.819,0,1.341.43,1.341,1.109a.986.986,0,0,1-.556.925l.618.907h-1.058Zm.093-1.437h-.361v.694h.361c.273,0,.4-.13.4-.345s-.13-.344-.4-.344"
          transform="translate(-1046.479 -161.034)"
          fill="#fff"
        />
        <path
          id="Caminho_685"
          data-name="Caminho 685"
          d="M1001.232,222.76l-1.243,2.942h-.975l-1.244-2.942h1.068l.7,1.718.718-1.718Z"
          transform="translate(-954.341 -198.824)"
          fill="#fff"
        />
        <path
          id="Caminho_686"
          data-name="Caminho 686"
          d="M1029.4,223.678a1.665,1.665,0,1,1,1.66,1.537,1.551,1.551,0,0,1-1.66-1.537m2.319,0a.664.664,0,1,0-.659.735.668.668,0,0,0,.659-.735"
          transform="translate(-982.572 -198.271)"
          fill="#fff"
        />
        <path
          id="Caminho_687"
          data-name="Caminho 687"
          d="M1062.55,223.678a1.533,1.533,0,0,1,1.638-1.538,1.6,1.6,0,0,1,1.319.588l-.625.559a.8.8,0,0,0-.645-.344.736.736,0,0,0,0,1.47.794.794,0,0,0,.645-.345l.625.56a1.589,1.589,0,0,1-1.319.587,1.532,1.532,0,0,1-1.638-1.537"
          transform="translate(-1012.16 -198.271)"
          fill="#fff"
        />
        <path
          id="Caminho_688"
          data-name="Caminho 688"
          d="M1095.166,217.384v.752h-2.446v-2.944h2.394v.752H1093.7v.344h1.243V217H1093.7v.387Zm-1.6-2.432h-.588l.537-.673h.807l.537.673h-.589l-.353-.322Z"
          transform="translate(-1039.088 -191.255)"
          fill="#fff"
        />
        <path
          id="Caminho_689"
          data-name="Caminho 689"
          d="M1003.415,267.973a1,1,0,0,1-.794.361,1.533,1.533,0,0,1-1.2-.638,1.5,1.5,0,0,1-1.373-1.5,1.614,1.614,0,0,1,3.224,0,1.479,1.479,0,0,1-1.05,1.436.542.542,0,0,0,.416.206.639.639,0,0,0,.479-.215Zm-1.758-.845a.941.941,0,1,0-.92-.94.9.9,0,0,0,.92.94"
          transform="translate(-956.376 -236.231)"
          fill="#fff"
        />
        <path
          id="Caminho_690"
          data-name="Caminho 690"
          d="M1034.52,266.756V265.11h.681v1.622c0,.559.244.79.655.79s.656-.231.656-.79V265.11h.673v1.646a1.332,1.332,0,1,1-2.664,0"
          transform="translate(-987.141 -236.624)"
          fill="#fff"
        />
        <path
          id="Caminho_691"
          data-name="Caminho 691"
          d="M1067.837,267.5v.547h-2.277V265.11h2.222v.546h-1.546v.638h1.366v.53h-1.366v.68Z"
          transform="translate(-1014.847 -236.624)"
          fill="#fff"
        />
        <path
          id="Caminho_692"
          data-name="Caminho 692"
          d="M1101.573,267.41h-1.365l-.26.632h-.7l1.311-2.942h.672l1.315,2.942h-.714Zm-.215-.516-.466-1.126-.466,1.126Z"
          transform="translate(-1044.916 -236.615)"
          fill="#fff"
        />
        <path
          id="Caminho_693"
          data-name="Caminho 693"
          d="M1002.177,308.464h.616v1.193a2.023,2.023,0,0,1-1.188.374,1.523,1.523,0,1,1,.012-3.041,1.57,1.57,0,0,1,1.232.507l-.437.4a.994.994,0,0,0-.76-.332.942.942,0,1,0-.013,1.883,1.094,1.094,0,0,0,.53-.126Z"
          transform="translate(-956.331 -274.004)"
          fill="#fff"
        />
        <path
          id="Caminho_694"
          data-name="Caminho 694"
          d="M1034.177,309.845v.546H1031.9V307.45h2.222V308h-1.546v.639h1.365v.53h-1.365v.68Z"
          transform="translate(-984.803 -274.414)"
          fill="#fff"
        />
        <path
          id="Caminho_695"
          data-name="Caminho 695"
          d="M1060.827,307.45v2.942h-.558l-1.467-1.787v1.787h-.672V307.45h.563l1.461,1.786V307.45Z"
          transform="translate(-1008.215 -274.414)"
          fill="#fff"
        />
        <path
          id="Caminho_696"
          data-name="Caminho 696"
          d="M1087.561,308h-.942v-.554h2.563V308h-.941v2.386h-.68Z"
          transform="translate(-1033.643 -274.414)"
          fill="#fff"
        />
        <path
          id="Caminho_697"
          data-name="Caminho 697"
          d="M1116.147,309.845v.546h-2.277V307.45h2.223V308h-1.546v.639h1.365v.53h-1.365v.68Z"
          transform="translate(-1057.965 -274.414)"
          fill="#fff"
        />
        <path
          id="Caminho_698"
          data-name="Caminho 698"
          d="M1002.45,350.346v.777h1.361v.546h-1.361v1.072h-.68V349.8h2.222v.546Z"
          transform="translate(-957.911 -312.214)"
          fill="#fff"
        />
        <path
          id="Caminho_699"
          data-name="Caminho 699"
          d="M1024.324,352.1h-1.365l-.261.631h-.7l1.31-2.942h.673l1.315,2.942h-.715Zm-.215-.517-.465-1.127-.462,1.127Z"
          transform="translate(-975.967 -312.205)"
          fill="#fff"
        />
        <path
          id="Caminho_700"
          data-name="Caminho 700"
          d="M1056.361,352.177v.556h-2.571v-.443l1.613-1.945h-1.579v-.554h2.474v.442l-1.609,1.945Z"
          transform="translate(-1004.341 -312.205)"
          fill="#fff"
        />
        <path
          id="Caminho_701"
          data-name="Caminho 701"
          d="M636.033,41.691H593.59V0h42.443Zm-42.121-.322h41.8V.322h-41.8Z"
          transform="translate(-593.59)"
          fill="#fff"
        />
        <path
          id="Caminho_702"
          data-name="Caminho 702"
          d="M601.405,13.371a16.808,16.808,0,0,1-7.717-1.82l-.088-.045V0h18.826V11.005l-.107.038A33.586,33.586,0,0,1,601.405,13.371Zm-7.483-2.064c6.283,3.128,12.424,1.467,18.182-.531V.322H593.922Z"
          transform="translate(-593.599)"
          fill="#fff"
        />
        <path
          id="Caminho_703"
          data-name="Caminho 703"
          d="M596.059,399.942a1,1,0,0,1-.449-.1,1.219,1.219,0,0,1-.509.107.948.948,0,0,1-.961-1.034,1.007,1.007,0,0,1,1.074-1.061,1.837,1.837,0,0,1,.694.133v.413a1.462,1.462,0,0,0-.52-.1c-.455,0-.7.234-.7.611a.524.524,0,0,0,.537.589.972.972,0,0,0,.284-.044v-.317h-.336V398.7h.883Z"
          transform="translate(-594.081 -355.109)"
          fill="#fff"
        />
        <path
          id="Caminho_704"
          data-name="Caminho 704"
          d="M619.46,398.917a1.115,1.115,0,1,1,1.116,1.047,1.022,1.022,0,0,1-1.116-1.047m1.669,0a.558.558,0,1,0-.553.6.546.546,0,0,0,.553-.6"
          transform="translate(-616.68 -355.118)"
          fill="#fff"
        />
        <path
          id="Caminho_705"
          data-name="Caminho 705"
          d="M646.317,400.186h-.028c-.347-.607-.735-1.377-1.069-2.066h.576c.159.352.33.728.507,1.094.176-.366.347-.741.506-1.094h.576c-.333.689-.724,1.459-1.068,2.066"
          transform="translate(-639.672 -355.342)"
          fill="#fff"
        />
        <path
          id="Caminho_706"
          data-name="Caminho 706"
          d="M673.67,399.713v.446h-1.51V398.12h1.491v.447H672.7v.341h.849v.444H672.7v.361Z"
          transform="translate(-663.718 -355.342)"
          fill="#fff"
        />
        <path
          id="Caminho_707"
          data-name="Caminho 707"
          d="M696.48,400.194h-.62a5.611,5.611,0,0,0-.656-.967.761.761,0,0,0,.215.028.283.283,0,0,0,.289-.322.286.286,0,0,0-.289-.322h-.292V400.2h-.537V398.16h.886a.714.714,0,0,1,.793.71.68.68,0,0,1-.275.571,7.152,7.152,0,0,1,.485.752m-.925-.615Z"
          transform="translate(-683.738 -355.377)"
          fill="#fff"
        />
        <path
          id="Caminho_708"
          data-name="Caminho 708"
          d="M721.534,399.963a6.96,6.96,0,0,1-1.5-1.11l.008,1.082H719.5V397.87a6.706,6.706,0,0,1,1.5,1.112L721,397.9h.537Z"
          transform="translate(-705.971 -355.118)"
          fill="#fff"
        />
        <path
          id="Caminho_709"
          data-name="Caminho 709"
          d="M746.57,398.917a1.114,1.114,0,1,1,1.114,1.047,1.022,1.022,0,0,1-1.114-1.047m1.669,0a.558.558,0,1,0-.554.6.547.547,0,0,0,.554-.6"
          transform="translate(-730.132 -355.118)"
          fill="#fff"
        />
        <path
          id="Caminho_710"
          data-name="Caminho 710"
          d="M788.548,399.11a.962.962,0,0,1-1.016,1.022h-.892V398.09h.892a.961.961,0,0,1,1.016,1.017m-.557,0c0-.342-.178-.57-.449-.57h-.363v1.145h.359c.271,0,.449-.229.449-.576"
          transform="translate(-765.896 -355.315)"
          fill="#fff"
        />
        <path
          id="Caminho_711"
          data-name="Caminho 711"
          d="M811.5,398.917a1.115,1.115,0,1,1,1.115,1.047,1.022,1.022,0,0,1-1.115-1.047m1.669,0a.558.558,0,1,0-.553.6.54.54,0,0,0,.55-.6"
          transform="translate(-788.085 -355.118)"
          fill="#fff"
        />
        <path
          id="Caminho_712"
          data-name="Caminho 712"
          d="M853.08,399.713v.446h-1.51V398.12h1.49v.447h-.95v.341h.848v.444h-.848v.361Z"
          transform="translate(-823.85 -355.342)"
          fill="#fff"
        />
        <path
          id="Caminho_713"
          data-name="Caminho 713"
          d="M873.84,399.184l-.085-.023c-.4-.107-.645-.21-.645-.625s.3-.666.809-.666a1.806,1.806,0,0,1,.659.124v.405a1.865,1.865,0,0,0-.537-.1c-.276,0-.389.053-.389.141,0,.107.149.134.347.184l.078.02c.358.087.63.256.63.658s-.3.659-.793.659a1.758,1.758,0,0,1-.72-.149v-.4a1.612,1.612,0,0,0,.579.12c.283,0,.391-.057.391-.154s-.148-.146-.322-.192"
          transform="translate(-843.075 -355.118)"
          fill="#fff"
        />
        <path
          id="Caminho_714"
          data-name="Caminho 714"
          d="M895.834,398.567h-.592v1.591H894.7v-1.591h-.592v-.447h1.724Z"
          transform="translate(-861.819 -355.342)"
          fill="#fff"
        />
        <path
          id="Caminho_715"
          data-name="Caminho 715"
          d="M914.529,399.676H913.7l-.118.259H913c.341-.689.74-1.459,1.1-2.065h.027c.353.606.752,1.377,1.1,2.065h-.575Zm-.651-.444h.472c-.077-.154-.16-.305-.238-.457-.076.149-.157.3-.234.457"
          transform="translate(-878.679 -355.118)"
          fill="#fff"
        />
        <path
          id="Caminho_716"
          data-name="Caminho 716"
          d="M942.3,399.11a.962.962,0,0,1-1.018,1.022h-.891V398.09h.891a.961.961,0,0,1,1.018,1.017m-.557,0c0-.342-.178-.57-.449-.57h-.363v1.145h.363c.271,0,.449-.229.449-.576"
          transform="translate(-903.126 -355.315)"
          fill="#fff"
        />
        <path
          id="Caminho_717"
          data-name="Caminho 717"
          d="M965.25,398.917a1.117,1.117,0,1,1,1.118,1.047,1.022,1.022,0,0,1-1.118-1.047m1.669,0a.558.558,0,1,0-.554.6.547.547,0,0,0,.554-.6"
          transform="translate(-925.315 -355.118)"
          fill="#fff"
        />
        <path
          id="Caminho_718"
          data-name="Caminho 718"
          d="M595.1,126.152c14.272,7.2,27.848-10.057,42.121-2.86v3.08c-14.271-7.2-27.848,10.062-42.121,2.859Z"
          transform="translate(-594.938 -108.442)"
          fill="#fff"
        />
        <path
          id="Caminho_719"
          data-name="Caminho 719"
          d="M710.455,64.913c-.682-.239-1.024-.364-1.705-.636-.682.537-1.023.8-1.705,1.3.26-.693.39-1.041.651-1.74-.682-.3-1.024-.456-1.705-.8.843-.082,1.264-.141,2.107-.288.261-.7.391-1.056.652-1.764.26.606.39.908.651,1.5.843-.183,1.265-.288,2.108-.519-.682.593-1.023.883-1.706,1.445.261.6.391.9.652,1.488"
          transform="translate(-693.913 -54.437)"
          fill="#fff"
        />
        <path
          id="Caminho_720"
          data-name="Caminho 720"
          d="M710.455,16.5c-.682-.237-1.024-.364-1.705-.637-.682.537-1.023.8-1.705,1.3.26-.693.39-1.041.651-1.741-.682-.3-1.024-.455-1.705-.794.843-.082,1.264-.141,2.107-.288.261-.7.391-1.055.652-1.764.26.606.39.908.651,1.5.843-.183,1.265-.288,2.108-.519-.682.6-1.023.886-1.706,1.45.261.6.391.9.652,1.488"
          transform="translate(-693.913 -11.228)"
          fill="#fff"
        />
        <path
          id="Caminho_721"
          data-name="Caminho 721"
          d="M609.195,13.926c-.681-.553-1.023-.846-1.7-1.47a13.881,13.881,0,0,1-1.706.379c.26-.546.391-.821.653-1.378-.682-.67-1.024-1.023-1.707-1.772a16.036,16.036,0,0,0,2.108.906c.261-.563.391-.847.652-1.422.26.74.39,1.108.65,1.837a15.454,15.454,0,0,0,2.109.457,18.03,18.03,0,0,1-1.706.666c.26.723.391,1.083.651,1.8"
          transform="translate(-603.533 -8.185)"
          fill="#fff"
        />
        <path
          id="Caminho_722"
          data-name="Caminho 722"
          d="M609.195,62.327c-.681-.553-1.023-.846-1.7-1.47a13.681,13.681,0,0,1-1.706.378c.26-.545.391-.821.653-1.376-.682-.671-1.024-1.024-1.707-1.772a16.033,16.033,0,0,0,2.108.906c.261-.563.391-.848.652-1.423.26.741.39,1.108.65,1.838a15.45,15.45,0,0,0,2.109.456,18,18,0,0,1-1.706.667c.26.723.391,1.082.651,1.8"
          transform="translate(-603.533 -51.384)"
          fill="#fff"
        />
        <path
          id="Caminho_723"
          data-name="Caminho 723"
          d="M665.128,50.286c-.511-.285-.767-.435-1.279-.752-.512.3-.768.43-1.28.7.2-.477.293-.717.489-1.2-.513-.334-.767-.509-1.279-.878a15.675,15.675,0,0,0,1.581.132c.2-.485.293-.73.489-1.217l.488,1.237c.633,0,.949-.015,1.582-.06-.511.341-.768.505-1.279.819.194.489.292.733.488,1.219"
          transform="translate(-654.453 -42.012)"
          fill="#fff"
        />
        <path
          id="Caminho_724"
          data-name="Caminho 724"
          d="M595.1,67.489c14.272,7.2,27.848-10.058,42.121-2.86v3.078c-14.271-7.2-27.848,10.063-42.121,2.86Z"
          transform="translate(-594.938 -56.082)"
          fill="#fff"
        />
        <path
          id="Caminho_725"
          data-name="Caminho 725"
          d="M767.31,8.154v3.078c7.756-2.811,15.625-6.393,23.615-2.388V5.765c-7.991-4.006-15.86-.423-23.615,2.389"
          transform="translate(-748.644 -3.549)"
          fill="#fff"
        />
        <path
          id="Caminho_726"
          data-name="Caminho 726"
          d="M620.094,270.5a4.19,4.19,0,0,1-1.91-.412,5.136,5.136,0,0,1-2.163.459c-2.364,0-4.091-1.706-4.091-4.415a4.271,4.271,0,0,1,4.595-4.532,7.853,7.853,0,0,1,2.954.565v1.767a6.171,6.171,0,0,0-2.215-.424c-1.934,0-2.966,1-2.966,2.613a2.239,2.239,0,0,0,2.3,2.52,4.178,4.178,0,0,0,1.207-.188V267.1h-1.429v-1.9H620.1Z"
          transform="translate(-609.959 -233.491)"
          fill="#fff"
        />
        <path
          id="Caminho_727"
          data-name="Caminho 727"
          d="M693.59,266.074c0-2.686,1.9-4.474,4.747-4.474,2.825,0,4.728,1.789,4.728,4.474s-1.9,4.473-4.728,4.473c-2.848,0-4.747-1.79-4.747-4.473m7.1,0a2.376,2.376,0,1,0-2.357,2.566,2.335,2.335,0,0,0,2.357-2.566"
          transform="translate(-682.845 -233.491)"
          fill="#fff"
        />
        <rect
          id="Retângulo_38"
          data-name="Retângulo 38"
          width="2.297"
          height="8.712"
          transform="translate(21.05 28.227)"
          fill="#fff"
        />
        <path
          id="Caminho_728"
          data-name="Caminho 728"
          d="M823.263,269.324h-3.539l-.505,1.107H816.77c1.453-2.943,3.153-6.24,4.665-8.83h.118c1.5,2.591,3.211,5.887,4.664,8.83h-2.45Zm-2.766-1.9h2c-.328-.66-.68-1.308-1.008-1.955-.328.635-.668,1.295-1,1.955"
          transform="translate(-792.789 -233.491)"
          fill="#fff"
        />
        <path
          id="Caminho_729"
          data-name="Caminho 729"
          d="M909.1,267.215l-.363-.094c-1.712-.447-2.755-.9-2.755-2.673,0-1.827,1.29-2.849,3.447-2.849a7.681,7.681,0,0,1,2.8.53v1.731a7.886,7.886,0,0,0-2.271-.413c-1.172,0-1.654.225-1.654.6,0,.447.634.577,1.477.789l.329.083c1.524.376,2.687,1.094,2.687,2.813,0,1.755-1.267,2.814-3.376,2.814a7.468,7.468,0,0,1-3.06-.635v-1.731a6.813,6.813,0,0,0,2.463.518c1.207,0,1.665-.247,1.665-.66,0-.459-.634-.623-1.384-.824"
          transform="translate(-872.413 -233.491)"
          fill="#fff"
        />
        <path
          id="Caminho_730"
          data-name="Caminho 730"
          d="M864.24,262.02l.724-1.19H863.6Z"
          transform="translate(-834.587 -232.804)"
          fill="#fff"
        />
      </g> */}
    </g>
  </svg>
)

export default IllustrationSVG
