import themes from './assets/styles/themes'
import './assets/styles/wp-style.min.css'
import GlobalStyles from './assets/styles/GlobalStyles'
import { ThemeProvider } from 'styled-components'
import { useRecoilState, useRecoilValue } from 'recoil'
import activeTheme from './store/themeAtom'
import UserState from './store/UserAtom'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Home from './pages/Home'
import ContentList from './pages/ContentList/ContentList'
import SearchResults from './pages/Search/Search'
import Single from './pages/Single/Single'
import LoginPage from './pages/Login/Login'
import Contact from './pages/Contact/Contact'
import { useEffect } from 'react'
import { getLocalStorageItem } from './helpers'
import EnemForm from './pages/EnemForm/EnemForm'

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const storedUser = getLocalStorageItem('netescola')
  const user = useRecoilValue(UserState)
  return (
    <Route
      {...rest}
      render={(props) => {
        return user || storedUser ? <Component {...props} /> : <Redirect to="/login" />
      }}
    />
  )
}

function Routes() {
  const theme = useRecoilValue(activeTheme)
  const [currentUser, setCurrentUser] = useRecoilState(UserState)
  const storedUser = getLocalStorageItem('netescola')

  useEffect(() => {
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser))
    }
  }, [setCurrentUser, storedUser])

  return (
    <ThemeProvider theme={themes[theme]}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <PrivateRoute user={currentUser} exact path="/" component={Home} />
          <PrivateRoute exact path="/notas-enem" component={EnemForm} />
          <PrivateRoute user={currentUser} exact path="/busca" component={SearchResults} />
          <PrivateRoute user={currentUser} exact path="/contato" component={Contact} />
          <PrivateRoute user={currentUser} exact path="/:slug" component={Single} />
          <PrivateRoute path="/conteudos/:comp/:serie" component={ContentList} />
          <Redirect from="*" to="/" />
        </Switch>
        <GlobalStyles />
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default Routes
