import { atom, RecoilState } from 'recoil'

type Student = {
  composicao: string;
  escola: string;
  codigoEscola: number;
  falhaAcesso?: any;
  id: number;
  matricula: string;
  municipio: string;
  nome: string;
  regional: string;
  serie: string;
  turma: string;
  tipoUsuario: number;
  codigoNivelEnsino: number;
};

const UserState: RecoilState<Student | any> = atom({
  key: 'UserState',
  default: null
})

export default UserState
