import styled from 'styled-components'
import Artes from './Artes'
import Ciencias from './Ciencias'
import ApoioProf from './ApoioProf'
import EdFisica from './EdFisica'
import Geografia from './Geografia'
import Historia from './Historia'
import Matematica from './Matematica'
import ApoioAluno from './ApoioAluno'
import Linguas from './Linguas'
import Lupa from './Lupa'
import React from 'react'

type IconsProps = {
  name: string
  size: number
  color?: string
  weight?: number
}

const Icons: React.FC<IconsProps> = ({ name, size, color, weight }: IconsProps) => {
  function getIcon() {
    switch (name.toLowerCase()) {
      case 'artes':
      case 'arte':
        return <Artes />
      case 'ciências':
      case 'ciências da natureza':
        return <Ciencias />
      case 'educação física':
        return <EdFisica />
      case 'geografia':
        return <Geografia />
      case 'história':
        return <Historia />
      case 'matemática':
        return <Matematica />
      case 'material-apoio-prof':
        return <ApoioProf />
      case 'material-apoio-aluno':
        return <ApoioAluno />
      case 'língua portuguesa':
      case 'língua inglesa':
      case 'língua espanhola':
      case 'inglês':
      case 'espanhol':
        return <Linguas />
      case 'lupa':
        return <Lupa />
      default:
        return <ApoioProf />
    }
  }

  return (
    <IconWrapper size={size} color={color} weight={weight}>
      {getIcon()}
    </IconWrapper>
  )
}

type IconWrapperProps = {
  size: number
  color?: string
  weight?: number
}

const IconWrapper = styled.i<IconWrapperProps>`
  width: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: inline-block;
  transition: all ease-out 400ms;
  transform-style: preserve-3d;

  path,
  line,
  circle,
  rect,
  ellipse {
    stroke: ${({ theme, color }) => color || theme.primary};
    stroke-width: ${({ weight }) => weight || 35}px;
  }
`

export default Icons
