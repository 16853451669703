import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

export const FlexWrapper = styled.div`
  display: flex;
  padding: 50px 0;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 986px) {
    justify-content: center;
  }
`

export const InfoCardTabs = styled.div`
  color: #16b786;
  flex: 1;

  @media (max-width: 986px) {
      flex: auto;
  }
`

export const InfoCardHeader = styled.div<any>`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: ${({ active }) => (active ? ' #f9f9f9' : '#fff')};
  transition: all 500ms ease;
  cursor: pointer;
  min-height: 80px;

  &:hover {
    background-color: #f9f9f9;
  }
`

export const InfoCardContent = styled.div`
  flex: 1;
  background-color: #f9f9f9;
  color: #16b786;
  padding: 2rem;
  text-align: center;
`

export const InfoImage = styled.img`
  margin-right: 10px;
  max-width: 60px;
`

export const InfoTitle = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
`

export const InfoDescription = styled.p`
  margin: 35px 0 20px;
  line-height: 26px;
  text-align: initial;
`

export const InfoButton = styled(Link)`
  color: inherit;
  display: block;
  font-weight: bold;
  margin: 0 5px;

  :active {
    color: inherit;
  }
`

export const InfoButtonHref = styled.a`
  color: inherit;
  display: block;
  font-weight: bold;
  margin: 0 5px;

  :active {
    color: inherit;
  }
`
