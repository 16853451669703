import { useState } from 'react'
import { validateFields } from '../../../helpers/formHelpers'
import { InputWrapper, Input, WarningIcon } from './style'

function TextArea ({ name, getValue, onError, validation, ...props }: any) {
  const [error, setError] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState('')

  function handleInputValue (value: any) {
    if (error) {
      setError(false)
      onError((current: any) => ({ ...current, [name]: false }))
    }
    setInputValue(value)
  }

  function sendInputValue () {
    if (error) return

    const hasError = validateFields(validation, inputValue)
    setError(hasError)
    onError((current: any) => ({ ...current, [name]: hasError }))
    getValue((current: any) => ({ ...current, [name]: inputValue }))
  }

  return (
    <InputWrapper>
      <Input
        error={error}
        value={inputValue}
        onChange={(event) => handleInputValue(event.target.value)}
        {...props}
        onBlur={() => sendInputValue()}
      />
      {error && <WarningIcon error={error} />}
    </InputWrapper>
  )
}

export default TextArea
