function Matematica () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080">
      <path
        d="M149.45,26.65H540a0,0,0,0,1,0,0V540a0,0,0,0,1,0,0H26.65a0,0,0,0,1,0,0V149.45a122.8,122.8,0,0,1,122.8-122.8Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="17.12"
      />
      <path
        d="M540,26.65H930.55a122.8,122.8,0,0,1,122.8,122.8V540a0,0,0,0,1,0,0H540a0,0,0,0,1,0,0V26.65A0,0,0,0,1,540,26.65Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="17.12"
      />
      <path
        d="M26.65,540H540a0,0,0,0,1,0,0v513.35a0,0,0,0,1,0,0H149.45a122.8,122.8,0,0,1-122.8-122.8V540A0,0,0,0,1,26.65,540Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="17.12"
      />
      <path
        d="M540,540h513.35a0,0,0,0,1,0,0V930.55a122.8,122.8,0,0,1-122.8,122.8H540a0,0,0,0,1,0,0V540A0,0,0,0,1,540,540Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="17.12"
      />
      <path
        d="M397.15,318.72H324.32a9.16,9.16,0,0,0-9.15,9.16v73.31a9.16,9.16,0,0,1-9.16,9.15H261.67a9.16,9.16,0,0,1-9.16-9.15V327.88a9.16,9.16,0,0,0-9.15-9.16H169.5a9.16,9.16,0,0,1-9.16-9.15V276.06a9.16,9.16,0,0,1,9.16-9.16h73.86a9.15,9.15,0,0,0,9.15-9.15V184.43a9.16,9.16,0,0,1,9.16-9.15H306a9.16,9.16,0,0,1,9.16,9.15v73.32a9.15,9.15,0,0,0,9.15,9.15h72.83a9.16,9.16,0,0,1,9.16,9.16v33.51A9.16,9.16,0,0,1,397.15,318.72Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="11.41"
      />
      <path
        d="M345.49,895.49,294,844a9.14,9.14,0,0,0-12.94,0l-51.84,51.84a9.17,9.17,0,0,1-13,0L184.9,864.47a9.17,9.17,0,0,1,0-13l51.84-51.84a9.14,9.14,0,0,0,0-12.94l-52.23-52.23a9.17,9.17,0,0,1,0-13l23.7-23.69a9.15,9.15,0,0,1,12.95,0l52.22,52.22a9.15,9.15,0,0,0,12.95,0l51.84-51.83a9.15,9.15,0,0,1,12.95,0l31.36,31.35a9.17,9.17,0,0,1,0,12.95L330.64,794.4a9.15,9.15,0,0,0,0,13l51.49,51.49a9.15,9.15,0,0,1,0,12.95l-23.69,23.7A9.17,9.17,0,0,1,345.49,895.49Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="11.41"
      />
      <path
        d="M924.42,281.9v21.82A14.29,14.29,0,0,1,910.13,318H683.23a14.3,14.3,0,0,1-14.3-14.3V281.9a14.3,14.3,0,0,1,14.3-14.3h226.9A14.29,14.29,0,0,1,924.42,281.9Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="11.41"
      />
      <path
        d="M924.42,785.77v21.82a14.29,14.29,0,0,1-14.29,14.29H683.23a14.29,14.29,0,0,1-14.3-14.29V785.77a14.3,14.3,0,0,1,14.3-14.3h226.9A14.29,14.29,0,0,1,924.42,785.77Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="11.41"
      />
      <path
        d="M763.35,717.68a34.79,34.79,0,1,1,34.78,34.8A34.89,34.89,0,0,1,763.35,717.68Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="11.41"
      />
      <path
        d="M763.35,875.64a34.79,34.79,0,1,1,34.78,34.8A34.9,34.9,0,0,1,763.35,875.64Z"
        fill="none"
        stroke="#1a1a1a"
        strokeMiterlimit="10"
        strokeWidth="11.41"
      />
    </svg>
  )
}

export default Matematica
