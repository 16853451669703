import { useState } from 'react'
import { ModalCard, ModalOverlay } from './style'
import { IoMdCloseCircle } from 'react-icons/io'

type ModalProps = {
  show: boolean
  children: any
  link?: string
}

const Modal = ({ show, link, children }: ModalProps) => {
  const [removeModal, setRemoveModal] = useState(false)

  return (
    <ModalOverlay show={show} removeModal={removeModal}>
      <ModalCard>
        <a href={link || '/'}>{children}</a>
        <span onClick={() => setRemoveModal(true)}>
          <IoMdCloseCircle size={25} />
        </span>
      </ModalCard>
    </ModalOverlay>
  )
}

export default Modal
