import { useState } from 'react'
import api from '../../../services/api'
import { useHistory } from 'react-router-dom'
import searchAnimation from '../../../assets/lotties/book.json'
import Lottie from 'lottie-react'
import { InputIcon, InputText, InputWrapper, SearchButton } from './style'

function SearchInput() {
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  function searchPosts(queryString: string) {
    const url = `multiple-post-type?search=${queryString}&type[]=fundamental&type[]=ensino_medio&type[]=fundamental_dois&type[]=conteudo_enem&type[]=conteudo_eja`

    if (queryString.length < 1) return
    setLoading(true)
    api
      .get(url)
      .then((res) => {
        setLoading(false)

        history.push(`/busca/?s=${query}`, { searchResults: res.data })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  function handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      searchPosts(query)
    }
  }

  return (
    <InputWrapper>
      <InputIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.998 28.003">
          <path
            d="M13.617-.29,8.165-5.742a1.311,1.311,0,0,0-.93-.383H6.344a11.319,11.319,0,0,0,2.406-7A11.373,11.373,0,0,0-2.625-24.5,11.373,11.373,0,0,0-14-13.125,11.373,11.373,0,0,0-2.625-1.75a11.319,11.319,0,0,0,7-2.406v.891a1.311,1.311,0,0,0,.383.93L10.21,3.117a1.307,1.307,0,0,0,1.854,0L13.612,1.57A1.319,1.319,0,0,0,13.617-.29ZM-2.625-6.125a7,7,0,0,1-7-7,7,7,0,0,1,7-7,7,7,0,0,1,7,7A7,7,0,0,1-2.625-6.125Z"
            transform="translate(14 24.5)"
            fill="#e6e6e6"
          />
        </svg>
      </InputIcon>
      <InputText
        placeholder="buscar conteúdo"
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={(e) => handleKeyPress(e)}
      />
      {loading ? (
        <Lottie animationData={searchAnimation} style={{ position: 'absolute', right: 0, width: 50 }} />
      ) : (
        <SearchButton onClick={() => searchPosts(query)}>
          <svg stroke="#e6e6e6" fill="#e6e6e6" strokeWidth="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="none"
              strokeMiterlimit="10"
              strokeWidth="32"
              d="M112 111v290c0 17.44 17 28.52 31 20.16l247.9-148.37c12.12-7.25 12.12-26.33 0-33.58L143 90.84c-14-8.36-31 2.72-31 20.16z"
            />
          </svg>
        </SearchButton>
      )}
    </InputWrapper>
  )
}

export default SearchInput
