import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { htmlDecode } from '../../../helpers'
import api from '../../../services/api'
import PostsState from '../../../store/PostsAtom'
import UserState from '../../../store/UserAtom'
import ContentLoading from '../../feedback/LoadingSpinner'
import { PostContent, PostTitle } from './style'

const Content = () => {
  const posts = useRecoilValue(PostsState)
  const user = useRecoilValue(UserState)
  const [activePost, setActivePost] = useState<any>(null)
  const { comp, slug } = useParams<any>()

  useEffect(() => {
    async function getActivePost() {
      if (posts.data.length) {
        const post = posts.data.find((post: any) => post.slug === slug)
        setActivePost(post)
      } else {
        const post = await api.get(`/${comp}?slug=${slug}`)
        setActivePost(post.data[0])
      }
    }
    getActivePost()
  }, [posts, slug, comp])

  return (
    <article>
      {!activePost ? (
        <ContentLoading />
      ) : (
        <>
          <PostTitle>{htmlDecode(activePost?.title?.rendered)}</PostTitle>
          {activePost?.codigo_atividade && (
            <iframe
              title="Atividade SAGRES"
              id="atividade-sagres"
              width="100%"
              height="820"
              frameBorder="0"
              allowFullScreen
              src={`https://elearning.easygenerator.com/${activePost?.codigo_atividade}/?name=${user?.nome}&email=${user?.matricula}@netescola.com#/`}
            />
          )}
          <PostContent dangerouslySetInnerHTML={{ __html: activePost?.content?.rendered }} />
        </>
      )}
    </article>
  )
}

export default Content
