import styled from 'styled-components/macro'

export const ModalOverlay = styled.div<any>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: ${({ show, removeModal }) => (show && !removeModal ? 'grid' : 'none')};
  place-items: center;
`

export const ModalCard = styled.div`
  max-width: 500px;
  background: #fff;
  border-radius: 3px;
  box-shadow: var(--shadow);
  padding: 10px;
  color: #51a34f;

  @media (max-width: 600px) {
    width: 350px;
    font-size: 12px;
  }

  h3 {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  & > div {
    margin-top: 25px;
    display: flex;
  }
`

export const FormRadio = styled.form`
  margin: 10px;

  h4 {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .radio {
    accent-color: green;
    display: flex;
    flex-direction: row;
    justify-content: center;

    input {
      margin-right: 5px;
      margin-left: 20px;
    }
  }
`
