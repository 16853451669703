import styled from 'styled-components/macro'

export const MainContent = styled.main`
min-height: calc(100vh - 316px);
overflow: hidden;
`

export const FlexContainer = styled.div`
display: flex;
`

export const ContentWrapper = styled.div<any>`
flex-direction: column;
display: flex;
justify-content: space-between;
padding: 0 0 0 45px;
flex: 1;
min-height: 450px;
transition: 300ms right ease;

@media (max-width: 980px) {
  padding: 0 10px;
  position: relative;
  z-index: 999;
  right: ${({ open }) => (open ? 255 : 0)}px;
  background: white;
  min-width: calc(100% - 13px);
  border-left: ${({ theme }) => `1px solid ${theme.primary}`};
}

@media (max-width: 768px) {
  padding: 0 10px;
}
`
