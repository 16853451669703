function isEmpty(value) {
  return value === undefined || value.length === 0
}

function isInvalidEmail(email) {
  const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  return !regexEmail.test(email)
}

function toBeTrue(value) {
  return !value
}

function maxMinEnemLength(value) {
  const gradeValue = !!value ? parseFloat(value) : 0
  return gradeValue < 100 || gradeValue > 1000
}

const validators = {
  email: isInvalidEmail,
  required: isEmpty,
  notaEnem: maxMinEnemLength,
  toBeTrue,
}

export function validateFields(rule, value) {
  return validators[rule] ? validators[rule](value) : false
}
