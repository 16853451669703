import React from 'react'
import { IconContext } from 'react-icons'
import IconButton from '../../forms/IconButton'
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc'
import { PaginationButton, PaginationWrapper } from './style'

type PaginationTypes = {
  totalPages: number
  setPage: Function
  activePage: number
}

const Pagination: React.FC<PaginationTypes> = ({ activePage, totalPages, setPage }) => {
  const total = Array(5).fill(null)
  const offset = activePage >= 5 ? activePage - 5 : 0

  return (
    <PaginationWrapper>
      <IconContext.Provider value={{ size: '1.3em', color: '#707070' }}>
        {activePage > 1 && (
          <IconButton
            testId="icon-left"
            onClick={() => setPage((curr: number) => curr - 1)}
            icon={<VscChevronLeft />}
          />
        )}
        {total.map((_, i) => (
          <PaginationButton
            role="button"
            key={i}
            active={i + 1 + offset === activePage}
            onClick={() => setPage(i + offset)}
          >
            {++i + offset}
          </PaginationButton>
        ))}
        {totalPages !== activePage && (
          <IconButton
            testId="icon-right"
            onClick={() => setPage((curr: number) => curr + 1)}
            icon={<VscChevronRight />}
          />
        )}
      </IconContext.Provider>
    </PaginationWrapper>
  )
}

export default Pagination
