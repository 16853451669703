import styled from 'styled-components/macro'
import Lottie from 'lottie-react'
import emailSent from '../../assets/lotties/email-sent.json'

const AnimationWrapper = styled.div`
  max-width: 80px;
  max-height: 80px;
  margin: auto;
`

const SendEmailLoading = () => {
  return (
    <AnimationWrapper>
      <Lottie animationData={emailSent} style={{ maxWidth: '100%' }} />
    </AnimationWrapper>
  )
}

export default SendEmailLoading
