import styled from 'styled-components/macro'
import themes from '../../assets/styles/themes'

export const SearchResultsContainer = styled.div`
padding-top: 3.75rem;
max-width: 800px;
margin: auto;
`

export const SearchItem = styled.div`
display: flex;
margin-bottom: 30px;
box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.2);
border-radius: 3px;
padding: 15px;
`

export const SubjectWrapper = styled.div`
max-width: 92px;
text-align: center;
`

export const SubjectName = styled.p`
text-align: center;
font-weight: normal;
color: #303030;
font-size: 0.9rem;
`

export const ContentWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
padding-left: 30px;
color: #404040;
`

export const ItemTitle = styled.h3`
text-align: left;
font-size: 16px;
font-family: "BwMitga", -apple-system, BlinkMacSystemFont, “Segoe UI”,
  “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”,
  “Helvetica Neue”, sans-serif;
font-weight: 900;
text-transform: capitalize;
color: #51a34f;
`

export const Excerpt = styled.p`
color: #707070;
font-size: 0.9rem;
margin: 10px 0;
line-height: 20px;
`

export const CategoriesInfo = styled.div`
display: flex;
justify-content: stretch;

& > * {
  flex: 1;
}
`

type CategoryProp = {
  colorName: string;
};

export const Category = styled.span<CategoryProp>`
margin-left: 0.5rem;
color: ${({ colorName }) => themes[colorName].primary};
`

export const ButtonReadMore = styled.a`
background-color: transparent;
border: none;
cursor: pointer;
opacity: 0.8;

svg {
  margin-left: 5px;
  position: relative;
  top: 3px;
}

&:hover {
  opacity: 1;
}
`
