import styled from 'styled-components/macro'

export const PageWrapper = styled.div`
min-height: 300px;
`

export const PageContent = styled.div`
color: #707070;
max-width: 850px;
margin: 3rem auto 0;

p {
  text-indent: 30px;
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 10px;
}

strong {
  line-height: initial;
}

.has-large-font-size {
  line-height: 2.5rem;
}

p,
ol,
li {
  line-height: 32px;
}

.justificado {
  text-align: justify;
}
`
