export function htmlDecode(input: string) {
  const str = new DOMParser().parseFromString(input, 'text/html')
  return str.documentElement.textContent
}

export function getQueryParams(url: string) {
  if (!url) return {}
  const urlArr = url.slice(1).split('&')
  const params: any = {}
  urlArr.forEach((element: string) => {
    const item = element.split('=')
    params[item[0]] = decodeURI(item[1]) || true
  })
  return params
}

export function getLocalStorageItem(item: string) {
  try {
    return localStorage.getItem(item)
  } catch (e) {}
}

export function setLocalStorageItem(key: string, value: any) {
  try {
    return localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {}
}

export function removeLocalStorageItem(key: string) {
  try {
    return localStorage.removeItem(key)
  } catch (e) {}
}
