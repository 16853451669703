import styled from 'styled-components/macro'

export const FormWrapper = styled.div`
  max-width: 768px;
  margin: 50px auto 0;
  text-align: center;
`

export const ButtonWrapper = styled.div`
  position: relative;
  min-height: 80px;
  display: grid;
  place-items: center;
`

export const SuccessMessage = styled.p`
  color: ${({ theme }) => theme.primary};
`
