import styled from 'styled-components/macro'
import { BsExclamationCircle } from 'react-icons/bs'

type InputProps = {
  mb: number;
  mt: number;
  theme: string;
  error: boolean;
};

export const Input = styled.textarea<InputProps>`
    width: 100%;
    padding: 5px;
    border-radius: 7px;
    border: none;
    background-color: #efefef;
    outline: none;
    font-size: 18px;
    color: ${({ theme }) => theme.primary};
    margin-top: ${({ mt }) => mt || 0}px;
    margin-bottom: ${({ mb }) => mb || 15}px;
  
    ::placeholder {
      color: ${({ theme, error }) => (error ? '#e18282' : theme.primary)};
    }
  `

export const InputWrapper = styled.div`
    position: relative;
  `

export const WarningIcon = styled<any>(BsExclamationCircle)`
    color: #e18282;
    position: absolute;
    top: 16px;
    right: 10px;
  `
